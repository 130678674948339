import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function RearPanelSelect({ isDisabled, isRequired = false }) {
  const { watch } = useFormContext();

  const switchboardHeight = watch('switchboardHeight');
  const horizontalModules = watch('horizontalModulesPerRow');
  const mountType = watch('mountType');

  const params = {
    verticalModules: switchboardHeight,
    horizontalModules,
    mountType,
    categorySlug: systemProductCategorySlug.RearPanel
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Rear Panel"
      inputName="rearPanel"
      params={params}
    />
  );
}
