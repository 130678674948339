import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import FormGroupNote from '../../../../components/form/FormGroupNote';
import CircuitsMultiValueInput from './CircuitsMultiValueInput';

export default function CircuitsField({
  label,
  isDisabled,
  isDimmable = false
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor={label}>
        {t(isDimmable ? 'Dimmable Circuits' : 'Circuits')}
      </Label>
      <Controller
        name={label}
        control={control}
        render={({ field }) => (
          <CircuitsMultiValueInput
            onChange={field.onChange}
            name={label}
            id={label}
            placeholder={t('No circuits assigned')}
            defaultValues={field.value}
            isDisabled={isDisabled}
            isDimmable={isDimmable}
          />
        )}
      />
      {errors && errors[label] && (
        <FormGroupNote type="warning">{errors[label]?.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
