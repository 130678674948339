import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import ClientModal from '../components/modals/ClientModal';
import ConsumerModal from '../components/modals/ConsumerModal';
import ProductModal from '../components/modals/ProductModal';
import ProjectModal from '../components/modals/ProjectModal';
import UserManagementModal from '../components/modals/UserManagementModal';
import UserProfileModal from '../components/modals/UserProfileModal';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import AppContext from '../context/Context';

const DashboardLayout = () => {
  const location = useLocation();
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical navbarStyle={navbarStyle} />}
      <div
        className={
          'mb-4 content ' +
          (location.pathname.endsWith('/switchboard') &&
            'vh-100 d-flex flex-column')
        }
      >
        <NavbarTop />
        <Outlet />
      </div>
      <SidePanelModal path={location.pathname} autoShow={false} />
      <ProductModal />
      <ClientModal />
      <ProjectModal />
      <ConsumerModal />
      <UserManagementModal />
      <UserProfileModal />
    </div>
  );
};

export default DashboardLayout;
