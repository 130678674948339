import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import { capitalize } from '../../../helpers/utils/utils';

export const getProductTableColumns = ({
  applySort,
  onClick,
  showDeleteConfirmationToast,
  t
}) => {
  return [
    {
      dataField: 'sku',
      text: 'Sku',
      classes: 'border-0',
      sort: true,
      onSort: () => applySort('sku'),
      headerClasses: 'border-0'
    },
    {
      dataField: 'label',
      text: t('Label'),
      classes: 'border-0',
      sort: true,
      onSort: () => applySort('label'),
      headerClasses: 'border-0'
    },
    {
      dataField: 'group',
      text: t('Group'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('group'),
      formatter: (id, data) => capitalize(data.group)
    },
    {
      dataField: 'destination',
      text: t('Destination'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('destination'),
      formatter: (id, data) => capitalize(data.destination)
    },
    {
      dataField: 'manufacturerName',
      text: t('Manufacturer'),
      classes: 'border-0',
      sort: true,
      onSort: () => applySort('manufacturer'),
      headerClasses: 'border-0'
    },
    {
      dataField: 'manufacturerSku',
      text: t('Manufacturer SKU'),
      classes: 'border-0',
      sort: true,
      onSort: () => applySort('manufacturer_sku'),
      headerClasses: 'border-0'
    },
    {
      dataField: 'price',
      text: `${t('Price')} (EUR)`,
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('price'),
      formatter: (price, _) => Number.parseFloat(price).toFixed(2)
    },
    {
      dataField: 'sellPrice',
      text: `${t('Sell Price')} (EUR)`,
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('sellPrice'),
      formatter: (sellPrice, _) => Number.parseFloat(sellPrice).toFixed(2)
    },
    {
      formatter: (_, row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              size="sm"
              className="text-600 btn-reveal mr-3"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
            </DropdownToggle>
            <DropdownMenu right className="border py-2">
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(row.id);
                }}
              >
                {t('Edit')}
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  showDeleteConfirmationToast(row.sku, row.id);
                }}
              >
                {t('Delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      text: '',
      classes: 'border-0',
      headerClasses: 'border-0',
      dataField: ''
    }
  ];
};
