import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../components/common/requiredStarLabel';
import {
  MountType,
  MountTypeText
} from '../../../../helpers/enums/mountTypeEnum';

const options = [
  {
    value: MountType.WallMount,
    label: MountTypeText[MountType.WallMount]
  },
  {
    value: MountType.FlushMount,
    label: MountTypeText[MountType.FlushMount]
  }
];

export default function SwitchboardMountTypeSelect({
  isRequired = false,
  isDisabled
}) {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const getDefaultValue = (fieldValue) => {
    return options.find((option) => option.value === fieldValue) || null;
  };

  return (
    <FormGroup>
      <Label htmlFor="mountType">
        {t('Mount Type')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="mountType"
        control={control}
        render={({ field }) => (
          <Select
            id="mountType"
            name="mountType"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={getDefaultValue(field.value)}
            onChange={(option) => field.onChange(option ? option.value : null)}
            placeholder="Not specified"
            options={options}
            isDisabled={isDisabled}
          />
        )}
      />
    </FormGroup>
  );
}
