export const MountType = {
  WallMount: 0,
  FlushMount: 1,
  DinRail: 2
};

export const MountTypeText = {
  [MountType.WallMount]: 'Wall mount',
  [MountType.FlushMount]: 'Flush mount',
  [MountType.DinRail]: 'DIN rail'
};
