import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import AppContext from '../../context/Context';
import { clientApi } from '../../helpers/api/clientApi';
import ClientModalForm from '../form/ClientModalForm';
import SideModal from './SideModal';

const emptyFormData = {
  name: '',
  email: '',
  phone: '',
  address: ''
};

const ClientModal = () => {
  const { t } = useTranslation();
  const { isOpenClientModal, toggleClientModal, editClientId } =
    useContext(AppContext);

  const [data, setData] = useState(emptyFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const fetchClientData = async () => {
    if (editClientId == null) {
      setData(emptyFormData);
      return;
    }

    setIsLoading(true);

    try {
      const response = await clientApi.getClientById(editClientId);
      const newData = {
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        address: response.data.address,
        companyRegistrationCode: response.data.companyRegistrationCode,
        companyFiscalCode: response.data.companyFiscalCode,
        IDNumber: response.data.IDNumber
      };
      setData(newData);
    } catch (error) {
      toast.error(t('Unable to retrieve client information.'));
      setError(t('Unable to retrieve client information.'));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchClientData();
  }, [editClientId]);

  return (
    <SideModal
      isOpen={isOpenClientModal}
      toggle={toggleClientModal}
      title="Client Form"
      subtitle={
        editClientId
          ? `${t('Editing client')} '${data.name}'`
          : t('Create a new client')
      }
      icon="edit"
    >
      {error === undefined && (
        <ClientModalForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          defaultValues={data}
        />
      )}

      {error !== undefined && <p>{error}</p>}
    </SideModal>
  );
};

export default ClientModal;
