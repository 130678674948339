import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const GuardedRoute = ({ auth }) => {
  if (auth === null || auth === undefined) return null;

  return auth ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default GuardedRoute;
