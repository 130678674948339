export const footer = {
  en: {
    translations: {
      Company: 'Company'
    }
  },
  ro: {
    translations: {
      Company: 'Companie'
    }
  },
  de: {
    translations: {
      Company: 'Firma'
    }
  }
};
