import {
  faChartPie,
  faCog,
  faCopy,
  faList,
  faPlug,
  faUserCog,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { faUsersGear } from '@fortawesome/free-solid-svg-icons';

import { PermissionType } from '../enums/permissionTypeEnum';

export const homeRoute = {
  name: 'Dashboard',
  to: '/',
  icon: faChartPie,
  permissions: [PermissionType.DASHBOARD.VIEW]
};

export const routes = [
  homeRoute,
  {
    name: 'Projects',
    to: '/projects',
    icon: faCopy,
    permissions: [
      PermissionType.PROJECTS.VIEW,
      PermissionType.PROJECTS.MODIFY,
      PermissionType.PROJECT_DETAILS.VIEW,
      PermissionType.PROJECT_DETAILS.MODIFY,
      PermissionType.PROJECT_DETAILS_OFFER.VIEW,
      PermissionType.PROJECT_DETAILS_OFFER.MODIFY
    ]
  },
  {
    name: 'Clients',
    to: '/clients',
    icon: faUserFriends,
    permissions: [PermissionType.CLIENT.VIEW, PermissionType.CLIENT.MODIFY]
  },
  {
    name: 'Products',
    to: '/products',
    icon: faList,
    permissions: [PermissionType.PRODUCT.MODIFY, PermissionType.PRODUCT.VIEW]
  },
  {
    name: 'Consumers',
    to: '/consumers',
    icon: faPlug,
    permissions: [PermissionType.CONSUMER.VIEW, PermissionType.CONSUMER.MODIFY]
  },
  {
    name: 'Configurations',
    to: '/configurations',
    icon: faCog,
    permissions: [
      PermissionType.CONFIGURATIONS.MODIFY,
      PermissionType.CONFIGURATIONS.VIEW
    ]
  }
];

export const adminRoutes = [
  {
    name: 'Manage Access',
    to: '/manage-access',
    icon: faUserCog
  },
  {
    name: 'Manage Users',
    to: '/manage-users',
    icon: faUsersGear
  }
];

export default routes;
