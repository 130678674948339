import './helpers/initializeFontAwesome';
import './localization';
import './styles/styles.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import i18n from './localization';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('main'));

root.render(
  <I18nextProvider i18n={i18n} defaultNS="translations">
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </I18nextProvider>
);
