export const clients = {
  en: {
    translations: {
      'Add Client': 'Add Client',
      Search: 'Search',
      Name: 'Name',
      Email: 'Email',
      Phone: 'Phone',
      'Created At': 'Created At',
      'Created By': 'Created By',
      'Results per page': 'Results per page',
      Delete: 'Delete',
      Edit: 'Edit'
    }
  },
  ro: {
    translations: {
      'Add Client': 'Adaugă un Client',
      Search: 'Caută',
      Name: 'Nume',
      Email: 'Email',
      Phone: 'Telefon',
      'Created At': 'Creat la',
      'Created By': 'Creat de',
      'Results per page': 'Rezultate pe pagină',
      Delete: 'Șterge',
      Edit: 'Editează'
    }
  },
  de: {
    translations: {
      'Add Client': 'Kunde hinzufügen',
      Search: 'Suche',
      Name: 'Name',
      Email: 'Email',
      Phone: 'Telefon',
      'Created At': 'Erstellt am',
      'Created By': 'Erstellt von',
      'Results per page': 'Ergebnisse pro Seite',
      Delete: 'Löschen',
      Edit: 'Bearbeiten'
    }
  }
};
