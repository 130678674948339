import { Route } from 'react-router-dom';

import AuthLayout from '../../layouts/AuthLayout';
import LoginScreen from '../../screens/auth/LoginScreen';
import RegisterScreen from '../../screens/auth/RegisterScreen';
import ResetPasswordScreen from '../../screens/auth/ResetPasswordScreen';

export const authRoutes = () => {
  return (
    <>
      <Route path="/auth/*" element={<AuthLayout />}>
        <Route path="login" element={<LoginScreen />} />
        <Route path="register" element={<RegisterScreen />} />
        <Route path="reset-password" element={<ResetPasswordScreen />} />
        <Route
          path="reset-password-confirm/:token?"
          element={<ResetPasswordScreen />}
        />
      </Route>
    </>
  );
};
