import { useQuery } from '@tanstack/react-query';
import { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../../../components/form/FormGroupNote';
import { productApi } from '../../../../../helpers/api/productApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import { getDefaultSelectValue } from '../../../../../helpers/utils/getDefaultSelectValue';

function GenericSwitchboardSettingsProductSelect({
  isDisabled,
  isRequired = false,
  inputName = '',
  params,
  displayName
}) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const switchboardManufacturerId = watch('switchboardManufacturerId');
  const mountType = watch('mountType');

  const [options, setOptions] = useState([]);

  const { isLoading, isError, error } = useQuery({
    queryKey: [inputName, params, mountType, switchboardManufacturerId],
    queryFn: () =>
      productApi
        .getProducts({
          ...params,
          manufacturer: switchboardManufacturerId
        })
        .then((res) => {
          const products = res.data;
          setOptions(
            products.items.map((product) => ({
              value: product.id,
              label: product.label
            }))
          );

          return products;
        }),
    retry: 0,
    refetchOnWindowFocus: false
  });

  if (isError) {
    showErrorsFromRequest(error, t);
  }

  const getFirstOrDefaultSelectValue = (fieldValue) => {
    return getDefaultSelectValue(fieldValue, options);
  };

  return (
    <FormGroup>
      <Label htmlFor={`switchboardProduct.${inputName}`}>
        {t(displayName)} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        control={control}
        name={`switchboardProduct.${inputName}`}
        render={({ field }) => (
          <Select
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(option) => field.onChange(option ? option.value : null)}
            placeholder={isLoading ? t('Loading...') : t('Not specified')}
            value={getFirstOrDefaultSelectValue(field.value)}
            isSearchable
            isClearable
            isDisabled={isDisabled || isError || isLoading}
          />
        )}
      />
      {errors &&
        errors.switchboardProduct &&
        errors.switchboardProduct[inputName] && (
          <FormGroupNote type="warning">
            {t(errors.switchboardProduct[inputName]?.message)}
          </FormGroupNote>
        )}
    </FormGroup>
  );
}

export default memo(GenericSwitchboardSettingsProductSelect);
