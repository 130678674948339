import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'reactstrap';

import AppContext from '../../../context/Context';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { userApi } from '../../../helpers/api/user/userApi';
import { UserRole } from '../../../helpers/enums/userRoleEnum';
import { userManagementFormSchema } from '../../../helpers/formValidations/userManagementFormSchema';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import { deleteConfirmationToast } from '../../deleteConfirmationToast';
import ClientSelect from '../ClientSelect';
import InputFormField from '../InputFormField';
import UserRoleSelect from '../UserRoleSelect';
import UserClientsSelect from './UserClientsSelect';
import UserProductsSelect from './UserProjectsSelect';

export default function UserManagementForm({
  user,
  isLoading,
  setIsLoading,
  userId
}) {
  const { t } = useTranslation();
  const { editUserId, toggleUserModal, setFetchData } = useContext(AppContext);

  const form = useForm({
    defaultValues: user,
    mode: 'onChange',
    resolver: zodResolver(userManagementFormSchema)
  });

  useFormScrollIntoView({ form });

  const onSubmit = async (data) => {
    setIsLoading(true);

    let newData = {
      email: data.userEmail,
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.userRole,
      company: data.company,
      phoneNumber: data.phoneNumber
    };

    if (data.userRole === UserRole.CLIENT) {
      newData.clientIds = data.clientId ? [data.clientId] : [];
    } else if (data.userRole === UserRole.COLLABORATOR) {
      newData.clientIds = data.clientIds ?? [];
      newData.projectIds = data.projectIds ?? [];
    } else {
      newData.clientIds = [];
      newData.projectIds = [];
    }

    try {
      if (editUserId) {
        await userApi.updateUser(editUserId, newData);
      } else {
        await userApi.addUser(newData);
      }
      setFetchData(true);
      toggleUserModal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    form.reset(user);
  }, [user]);

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `Please confirm you want to delete ${user.userEmail}?`,
      onClick: () => handleUserDelete(),
      buttonText: 'Yes, delete!'
    });
  };

  const handleUserDelete = async () => {
    try {
      await userApi.deleteUser(userId);
      setFetchData(true);
      toggleUserModal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="form-modal-padding">
          <InputFormField
            isDisabled={isLoading}
            isRequired
            placeholder="John"
            displayLabel="First Name"
            label="firstName"
          />
          <InputFormField
            isDisabled={isLoading}
            isRequired
            placeholder="Doe"
            displayLabel="Last Name"
            label="lastName"
          />
          <InputFormField
            isDisabled={isLoading}
            isRequired
            placeholder="john.doe@example.com"
            displayLabel="Email"
            label="userEmail"
          />
          <UserRoleSelect isDisabled={isLoading} isRequired />
          <InputFormField
            isDisabled={isLoading}
            displayLabel="Company"
            label="company"
          />
          <InputFormField
            isDisabled={isLoading}
            displayLabel="Phone Number"
            label="phoneNumber"
          />
          {form.watch('userRole') === UserRole.CLIENT && (
            <ClientSelect isLoading={isLoading} />
          )}
          {form.watch('userRole') === UserRole.COLLABORATOR && (
            <>
              <UserClientsSelect isDisabled={isLoading} />
              <UserProductsSelect isDisabled={isLoading} />
            </>
          )}
          <div className="d-flex justify-content-between">
            {editUserId && (
              <Button
                disabled={isLoading}
                type="button"
                color="falcon-danger"
                onClick={showDeleteConfirmationToast}
              >
                Delete
              </Button>
            )}
            <Button disabled={isLoading} type="submit" color="falcon-primary">
              {isLoading ? '...' : 'Save'}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
}
