import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DynamicEntryForm from '../../../../components/form/DynamicEntryForm';
import SideModal from '../../../../components/modals/SideModal';
import { offersApi } from '../../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';

const DynamicEntryModal = ({
  profileId,
  editDynamicEntryId,
  isOpen,
  toggleModal
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    label: '',
    productId: null,
    priceExpression: '',
    taxExpression: '',
    discountExpression: '',
    quantityExpression: ''
  });

  const fetchDynamicEntry = useCallback(async () => {
    try {
      const response = await offersApi.getDynamicEntryById(
        profileId,
        editDynamicEntryId
      );
      setFormData({
        label: response.data.label ?? '',
        productId: response.data.productId ?? null,
        priceExpression: response.data.priceExpression ?? '',
        taxExpression: response.data.taxExpression ?? '',
        discountExpression: response.data.discountExpression ?? '',
        quantityExpression: response.data.quantityExpression ?? ''
      });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [profileId, editDynamicEntryId]);

  useEffect(() => {
    if (editDynamicEntryId) {
      fetchDynamicEntry();
    }
  }, [fetchDynamicEntry, editDynamicEntryId]);

  return (
    <SideModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Dynamic Entry"
      subtitle={
        editDynamicEntryId
          ? `${t('Edit dynamic entry')} #${editDynamicEntryId}`
          : t('Create a new dynamic entry')
      }
      icon="edit"
    >
      <DynamicEntryForm
        profileId={profileId}
        editDynamicEntryId={editDynamicEntryId}
        defaultValues={formData}
        toggleModal={toggleModal}
      />
    </SideModal>
  );
};

export default DynamicEntryModal;
