import axios from '../../axios/axios.service';

export const userApi = {
  userLogin: async (email, password) => {
    return await axios.post('/users/login', { email, password });
  },
  userRegister: async (user) => {
    return await axios.post('/users/register', user);
  },
  userRefreshToken: async (refreshToken) => {
    return await axios.post('/users/login-by-refresh-token', { refreshToken });
  },
  userIdentity: async () => {
    return await axios.get('/identity/me');
  },
  getUsers: async (params) => {
    return await axios.get(`/users`, { params });
  },
  getUserById: async (userId) => {
    return await axios.get(`/users/${userId}`);
  },
  updateUser: async (userId, user) => {
    return await axios.put(`/users/${userId}`, user);
  },
  addUser: async (user) => {
    return await axios.post(`/users`, user);
  },
  deleteUser: async (userId) => {
    return await axios.delete(`/users/${userId}`);
  },
  userProfileEdit: async (user) => await axios.put('/users/profile', user)
};
