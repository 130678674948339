import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

export const deleteConfirmationToast = ({ title, subtitle, onClick, buttonText }) => {
  toast(
    <>
      <span>{title}</span>
      {subtitle && <small className="text-danger d-block mt-2">{subtitle}</small>}
      <div className="d-flex mt-2">
        <Button color="danger" className="btn-sm ml-auto mr-2" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </>,
    {
      position: 'top-right',
      type: toast.TYPE.DEFAULT,
      autoClose: 20000,
    },
  );
};
