import z from 'zod';

export const userProfileEditFormSchema = z.object({
  firstName: z
    .string({
      invalid_type_error: 'First Name is required',
      required_error: 'First Name is required'
    })
    .min(1, { message: 'First Name is required' }),
  lastName: z
    .string({
      invalid_type_error: 'Last Name is required',
      required_error: 'Last Name is required'
    })
    .min(1, { message: 'Last Name is required' }),
  company: z.string().nullish().optional(),
  phoneNumber: z.string().nullish().optional()
});
