export const ConsumerType = {
  GENERIC: 0,
  LIGHT_ON_OFF: 1,
  LED_ON_OFF: 3,
  ROLLUP: 5,
  HEATING: 6,
  COOLING: 7
};

export const ConsumerTypeText = {
  [ConsumerType.GENERIC]: 'Generic',
  [ConsumerType.LIGHT_ON_OFF]: 'Light On/Off',
  [ConsumerType.LED_ON_OFF]: 'LED On/Off',
  [ConsumerType.ROLLUP]: 'Rollup',
  [ConsumerType.HEATING]: 'Heating',
  [ConsumerType.COOLING]: 'Cooling'
};
