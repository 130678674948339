import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

import ButtonIcon from '../../../components/common/ButtonIcon';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import AppContext from '../../../context/Context';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../../hoc/checkUserPermissions';

export default function ProjectInformationCard({ project, projectId }) {
  const { t } = useTranslation();
  const { toggleProjectModal } = useContext(AppContext);

  return (
    <Card>
      <FalconCardHeader title="Information">
        <CheckUserPermissions permissions={[PermissionType.PROJECTS.MODIFY]}>
          <ButtonIcon
            icon="edit"
            size="sm"
            color="falcon-default"
            onClick={() => toggleProjectModal(projectId)}
          >
            {t('Edit')}
          </ButtonIcon>
        </CheckUserPermissions>
      </FalconCardHeader>
      <CardBody>
        <Row>
          <Col md={4} className="mt-3">
            <h5 className="mb-3 fs-0">{t('Summary')}</h5>
            <p className="mb-0 fs--1">
              <strong>{t('Project Id')}: </strong>
              {project.id}
            </p>
            <p className="mb-0 fs--1">
              <strong>{t('Created At:')} </strong>{' '}
              {moment(project.createdAt).format('DD MMM YYYY HH:mm:ss')}
            </p>
          </Col>

          <Col md={4} className="mt-3">
            <h5 className="mb-3 fs-0">{t('Project')}</h5>
            <h6 className="mb-2">{project.name}</h6>
            <p className="mb-1 fs--1">{project.address}</p>
          </Col>

          <Col md={4} className="mt-3">
            <h5 className="mb-3 fs-0">{t('Client')}</h5>
            <h6 className="mb-2">{project.client.name}</h6>
            <p className="mb-1 fs--1">{project.client.address}</p>
            <p className="mb-0 fs--1">
              <strong>{t('Email')}: </strong>
              <a href={`mailto:${project.client.email}`}>
                {project.client.email}
              </a>
            </p>
            <p className="mb-0 fs--1">
              <strong>{t('Phone')}: </strong>
              <a href={`phone:${project.client.phone}`}>
                {project.client.phone}
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <h5 className="mb-3 fs-0">{t('Notes')}</h5>
            <p className="fs--1">{project.notes}</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
