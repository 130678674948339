import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import { dataContractApi } from '../../../helpers/api/dataContractApi';
import { ConsumerTypeText } from '../../../helpers/enums/consumerTypeEnum';
import RequiredStarLabel from '../../common/requiredStarLabel';
import EntitySelect from '../../entitySelect/EntitySelect';
import FormGroupNote from '../FormGroupNote';

export default function ConsumerTypeSelect({ isDisabled, isRequired = false }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="type">
        {t('Consumer Type')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <EntitySelect
            name="type"
            displayName={t('Consumer Type')}
            fieldValue={field.value}
            onChange={field.onChange}
            placeholder="Not specified"
            fetchDataFunction={() => {
              return dataContractApi.getDataContractsByContractType(
                'consumer-types'
              );
            }}
            isFetchDataEnum
            enumToText={ConsumerTypeText}
            isDisabled={isDisabled}
            isClearable={false}
          />
        )}
      />
      {errors.type && (
        <FormGroupNote type="warning">{errors.type.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
