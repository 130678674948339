import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader } from 'reactstrap';

import ScrollBarCustom from '../../../../components/common/ScrollBarCustom';

const CardModal = ({
  children,
  title,
  subtitle,
  icon,
  isOpen,
  toggleModal
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName="overflow-hidden modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader
        tag="div"
        toggle={toggleModal}
        className="modal-header-settings"
      >
        <div className="py-1 flex-grow-1">
          <h5 className="text-white">
            {icon && <FontAwesomeIcon icon={icon} className="mr-2 fs-0" />}
            {t(title)}
          </h5>
          {subtitle && (
            <p className="mb-0 fs--1 text-white opacity-75">{t(subtitle)}</p>
          )}
        </div>
      </ModalHeader>

      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          renderer: ({ elementRef, ...restParams }) => (
            <span
              {...restParams}
              ref={elementRef}
              className={classNames('p-card position-absolute border-left')}
            />
          )
        }}
      >
        {children}
      </ScrollBarCustom>
    </Modal>
  );
};

export default CardModal;
