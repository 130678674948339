import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Input, Label } from 'reactstrap';

export default function SearchInput({
  onSearch,
  placeholder,
  addSearchParams = false,
  showLabel = true
}) {
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const handleSearchParamsSet = (value) => {
    if (!addSearchParams) {
      return;
    }

    if (value === '') {
      searchParams.delete('search');
      setSearchParams(searchParams);
      return;
    }

    setSearchParams((params) => {
      params.set('search', value);
      return params;
    });
  };

  const handleInputChange = (value) => {
    setSearchValue(value);
    handleSearchParamsSet(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 300),
    []
  );

  const handleSearchClear = () => {
    handleInputChange('');
  };

  return (
    <div>
      {showLabel && <Label for="searchInput">{t('Search')}</Label>}
      <div className="position-relative">
        <Input
          id="searchInput"
          name="searchInput"
          value={searchValue}
          onChange={({ target }) => handleInputChange(target.value)}
          placeholder={
            placeholder &&
            placeholder
              .split(', ')
              .map((word) => t(word))
              .join(', ')
          }
          className="fs--1"
          size={placeholder?.length}
          type="text"
          autoComplete="off"
          style={{ minWidth: '200px' }}
        />
        {searchValue && (
          <button
            className="btn btn-link position-absolute"
            style={{ right: 0, top: 0 }}
            onClick={handleSearchClear}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </div>
    </div>
  );
}
