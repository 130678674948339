export const prepareProductsListParams = ({
  search,
  page,
  perPage,
  sort,
  filterByGroup,
  filterByDestination
}) => {
  let params = {
    page,
    perPage,
    searchTerm: search,
    exactDestinationMatch: true
  };

  if (sort.field !== '') {
    params.sortBy = sort.field;
    params.sortDirection = sort.direction;
  }

  if (filterByGroup !== '') {
    params.group = filterByGroup;
  }

  if (filterByDestination !== '') {
    params.destination = filterByDestination;
  }

  return params;
};
