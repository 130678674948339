import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';

import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';

export const handleExportProject = async ({
  documentType,
  setIsLoading,
  projectId,
  project,
  t
}) => {
  setIsLoading(true);
  try {
    const response = await projectApi.exportProject(projectId, documentType);

    let filename;
    const downloadDate = new Date().toLocaleDateString('en-GB');

    let documentPrefix = '';
    const documentName = `${downloadDate} ${project.name}`;
    let documentExtension = '.txt';

    switch (documentType) {
      case 'tableAttach':
        documentPrefix = t('Switchboard attach');
        documentExtension = '.pdf';
        break;
      case 'tableDocumentation':
        documentPrefix = t('Switchboard documentation');
        documentExtension = '.xlsx';
        break;
      case 'cableJournal':
        documentPrefix = t('Cable journal');
        documentExtension = '.xlsx';
        break;
      case 'purchasesJournal':
        documentPrefix = t('Purchases list');
        documentExtension = '.xlsx';
        break;
      case 'tagsClamps':
        documentPrefix = t('Tags clamps');
        documentExtension = '.xlsx';
        break;
      case 'tagsConductors':
        documentPrefix = t('Tags conductors');
        documentExtension = '.xlsx';
        break;
      case 'tagsModularEquipment':
        documentPrefix = t('Tags modular equipment');
        documentExtension = '.xlsx';
        break;
      case 'offer':
        documentPrefix = t('Offer');
        documentExtension = '.xlsx';
        break;
      default:
        toast.error(t('Unknown document type!'));
        documentPrefix = '';
        documentExtension = '.txt';
        break;
    }

    filename =
      `${documentPrefix}_${documentName}${documentExtension}`.replaceAll(
        ' ',
        '_'
      );

    fileDownload(response.data, filename);
  } catch (error) {
    showErrorsFromRequest(error, t);
  }
  setIsLoading(false);
};
