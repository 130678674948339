export const switchboardHelpers = {
  subzones: function (zoneId = null) {
    if (zoneId !== null) {
      return (
        (this &&
          this.zones &&
          this.zones.find((z) => z.id === zoneId) &&
          this.zones.find((z) => z.id === zoneId).subzones) ||
        []
      );
    }

    return (
      (this &&
        this.zones &&
        this.zones.reduce((acc, z) => [...acc, ...z.subzones], [])) ||
      []
    );
  },
  consumers: function (subzoneId) {
    if (
      subzoneId !== null &&
      subzoneId !== undefined &&
      this &&
      this.subzones() &&
      this.subzones().find((s) => s.id === subzoneId)
    ) {
      return this.subzones().find((s) => s.id === subzoneId).consumers || [];
    }

    return (
      (this &&
        this.subzones() &&
        this.subzones().reduce((acc, s) => [...acc, ...s.consumers], [])) ||
      []
    );
  },
  zone: function (id) {
    return (this && this.zones.find((z) => z.id === id)) || null;
  },
  subzone: function (subzoneId) {
    return this.subzones().find((s) => s.id === subzoneId) || null;
  },
  consumer: function (consumerId) {
    return this.consumers().find((c) => c.id === consumerId) || null;
  }
};
