import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import ButtonIcon from '../../../../components/common/ButtonIcon';
import AppContext from '../../../../context/Context';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import ConsumerColumn from './ConsumerColumn';
import ZoneCard from './zoneCard/ZoneCard';

export default function SubzoneColumn({
  zone,
  toggleSubzoneModal,
  toggleConsumerModal,
  switchboard,
  fetchSwitchboard
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const checkPermission = ({ permissions, callback }) => {
    if (
      checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermissions: user?.permissions,
        permissions: permissions
      })
    ) {
      return callback();
    }
  };
  return (
    <Col md={8}>
      {!zone.subzones.length && !zone.isDeleted && (
        <CheckUserPermissions
          permissions={[PermissionType.SWITCHBOARD.SUBZONE.MODIFY]}
        >
          <ButtonIcon
            className="w-100 mt-1 mb-3"
            color="light"
            size="sm"
            icon="plus"
            onClick={() => toggleSubzoneModal({ zoneId: zone.id })}
          >
            {t('New Subzone')}
          </ButtonIcon>
        </CheckUserPermissions>
      )}

      {zone.subzones.map((subzone, subzoneIndex) => (
        <Row key={`subzone - row - ${subzone.id}`}>
          <Col md={6}>
            <ZoneCard
              className={`mb-2 ${subzone.isDeleted ? 'opacity-50' : ''}`}
              key={subzone.id}
              zone={subzone}
              onClick={() =>
                checkPermission({
                  permissions: [
                    PermissionType.SWITCHBOARD.SUBZONE.VIEW,
                    PermissionType.SWITCHBOARD.SUBZONE.MODIFY
                  ],
                  callback: () => toggleSubzoneModal(subzone)
                })
              }
            />
            {zone.subzones.length - 1 === subzoneIndex && !zone.isDeleted && (
              <CheckUserPermissions
                permissions={[PermissionType.SWITCHBOARD.SUBZONE.MODIFY]}
              >
                <ButtonIcon
                  className="w-100 mt-1 mb-3"
                  color="light"
                  size="sm"
                  icon="plus"
                  onClick={() => toggleSubzoneModal({ zoneId: zone.id })}
                >
                  {t('New Subzone')}
                </ButtonIcon>
              </CheckUserPermissions>
            )}
          </Col>
          <ConsumerColumn
            toggleConsumerModal={toggleConsumerModal}
            switchboard={switchboard}
            fetchSwitchboard={fetchSwitchboard}
            subzone={subzone}
          />
        </Row>
      ))}
    </Col>
  );
}
