import z from 'zod';

import { MountType } from '../enums/mountTypeEnum';

export const switchboardSettingsFormSchema = z
  .object({
    label: z
      .string({
        required_error: 'Label is required.',
        invalid_type_error: 'Label is required.'
      })
      .trim()
      .min(1, { message: 'Label is required.' }),
    connectionType: z.number({
      invalid_type_error: 'Connection type is required.'
    }),
    manufacturerId: z.number().nullish(),
    relayId: z.number().nullish(),
    contactorId: z.number().nullish(),
    switchboardManufacturerId: z.number().nullish(),
    protection: z.object({
      id: z.number().nullish(),
      label: z.string().nullish()
    }),
    forceCableCode: z
      .string({
        invalid_type_error: 'Force cable code is required.',
        required_error: 'Force cable code is required.'
      })
      .trim()
      .min(1, { message: 'Force cable code is required.' }),
    busCableCode: z
      .string({
        invalid_type_error: 'Bus cable code is required.',
        required_error: 'Bus cable code is required.'
      })
      .trim()
      .min(1, { message: 'Bus cable code is required.' }),
    networkCableCode: z
      .string({
        invalid_type_error: 'Network cable code is required.',
        required_error: 'Network cable code is required.'
      })
      .trim()
      .min(1, { message: 'Network cable code is required.' }),
    cablesShouldHaveTileLabels: z.boolean(),
    mountType: z.number({
      required_error: 'Mount type is required.',
      invalid_type_error: 'Mount type is required.'
    }),
    switchboardWidth: z.coerce.number().optional(),
    switchboardHeight: z.coerce.number().optional(),
    horizontalModulesPerRow: z.coerce.number().optional(),
    verticalModulesPerGenericRow: z.coerce.number().optional(),
    numberOfGenericRows: z.coerce.number().optional(),
    verticalModulesPerClampRow: z.coerce.number().optional(),
    numberOfClampRows: z.coerce.number().optional(),
    verticalModulesPerReservedRow: z.coerce.number().optional(),
    numberOfReservedRows: z.coerce.number().optional(),
    hasCounterTub: z.boolean(),
    isAllIncluded: z.boolean(),
    switchboardProduct: z.object({
      rearPanel: z.number().optional().nullish(),
      counter: z.number().optional().nullish(),
      frame: z.number().optional().nullish(),
      distributionBoard: z.number({
        invalid_type_error: 'Distribution board is required.',
        required_error: 'Distribution board is required.'
      }),
      support: z.number().optional().nullish(),
      rail: z.number().optional().nullish(),
      slottedPlate: z.number().optional().nullish(),
      blindPlate: z.number().optional().nullish(),
      reservedBlindPlate: z.number().optional().nullish(),
      counterPlate: z.number().optional().nullish()
    })
  })
  .superRefine((data, ctx) => {
    const { mountType, switchboardProduct, hasCounterTub, isAllIncluded } =
      data;

    if (!isAllIncluded) {
      if (mountType === MountType.WallMount) {
        if (!switchboardProduct.rearPanel) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Rear panel is required for wall mount.',
            path: ['switchboardProduct.rearPanel']
          });
        }
      }

      if (mountType === MountType.FlushMount) {
        if (!switchboardProduct.frame) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Frame is required for flush mount.',
            path: ['switchboardProduct.frame']
          });
        }
      }
    }

    if (hasCounterTub && !switchboardProduct.counter) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Meter Box is required.',
        path: ['switchboardProduct.counter']
      });
    }

    if (hasCounterTub && !switchboardProduct.counterPlate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Meter Box Plate is required.',
        path: ['switchboardProduct.counterPlate']
      });
    }
  });
