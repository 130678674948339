export const configurations = {
  en: {
    translations: {
      Configurations: 'Configurations',
      'Dynamic Entries': 'Dynamic Entries',
      Tax: 'Tax',
      Discount: 'Discount',
      Quantity: 'Quantity',
      'Add new entry': 'Add new entry',
      'Add a new profile': 'Add a new profile',
      'Select a profile...': 'Select a profile...',
      'There are no dynamic entries, go ahead and add a new one.':
        'There are no dynamic entries, go ahead and add a new one.',
      'Please select a profile first!': 'Please select a profile first!'
    }
  },
  ro: {
    translations: {
      Configurations: 'Configurări',
      'Dynamic Entries': 'Intrări Dinamice',
      Tax: 'Taxă',
      Discount: 'Discount',
      Quantity: 'Cantitate',
      'Add new entry': 'Adaugă o nouă intrare',
      'Add a new profile': 'Adaugă un nou profil',
      'Select a profile...': 'Selectează un profil...',
      'There are no dynamic entries, go ahead and add a new one.':
        'Nu există intrări dinamice, adaugă una nouă.',
      'Please select a profile first!': 'Te rog selectează un profil!'
    }
  },
  de: {
    translations: {
      Configurations: 'Konfigurationen',
      'Dynamic Entries': 'Dynamische Einträge',
      Tax: 'Steuer',
      Discount: 'Rabatt',
      Quantity: 'Menge',
      'Add new entry': 'Neuen Eintrag hinzufügen',
      'Add a new profile': 'Fügen Sie ein neues Profil hinzu',
      'Select a profile...': 'Wählen Sie ein Profil aus...',
      'There are no dynamic entries, go ahead and add a new one.':
        'Es gibt keine dynamischen Einträge, fügen Sie einen neuen hinzu.',
      'Please select a profile first!':
        'Bitte wählen Sie zuerst ein Profil aus!'
    }
  }
};
