export const systemProductCategorySlug = {
  General: 'general',
  Busbar: 'busbar',
  Clamp: 'clamp',
  ModularDevices: 'modular-devices',
  DistributionBoard: 'distribution-board',
  RCBO: 'rcbo',
  AFDD: 'afdd',
  RCCB: 'rccb',
  MCB: 'mcb',
  SPD: 'spd',
  ChangeoverSwitches: 'changeover-switches',
  Relay: 'relay',
  Actuators: 'actuators',
  Jumper: 'jumper',
  Conductor: 'conductor',
  IsolatedSleeves: 'isolated-sleeves',
  Label: 'label',
  Frame: 'frame',
  Support: 'support',
  Rail: 'rail',
  SlottedPlate: 'slotted-plate',
  BlindPlate: 'blind-plate',
  Accessory: 'accessory',
  RearPanel: 'rear-panel',
  MeterBox: 'meter-box',
  MeterBoxPlate: 'meter-box-plate'
};
