import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

import Flex from '../common/Flex';

const NavbarVerticalMenuItem = ({ route }) => {
  const { t } = useTranslation();
  return (
    <Flex align="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text">{t(route.name)}</span>
      {!!route.badge && (
        <Badge
          color={route.badge.color || 'soft-success'}
          pill
          className="ml-2"
        >
          {route.badge.text}
        </Badge>
      )}
    </Flex>
  );
};

export default NavbarVerticalMenuItem;
