import { useTranslation } from 'react-i18next';
import { Table, Input } from 'reactstrap';

export default function PowerSourcesTable({ originalEntries, entries, setEntries, loading }) {
  const { t } = useTranslation();

  const handlePowerSourceChange = (index, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index].powerSource = value;
    updatedEntries[index].toUpdate = updatedEntries[index].powerSource !== originalEntries[index].powerSource;

    setEntries(updatedEntries);
  };

  const handlePowerChange = (index, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index].power = value;
    updatedEntries[index].toUpdate = updatedEntries[index].power !== originalEntries[index].power;

    setEntries(updatedEntries);
  };

  return (
    <div className="table-responsive fs--1">
      {loading ? (
        <div className="text-center py-3">{t('Loading')}...</div>
      ) : (
        <Table striped className="border-bottom border-200">
          <thead className="bg-200 text-900">
            <tr>
              <th className="border-0">{t('Consumer Label')}</th>
              <th className="border-0">{t('Circuit Name')}</th>
              <th className="border-0">{t('Power Source')}</th>
              <th className="border-0">{t('Power')}</th>
            </tr>
          </thead>
          <tbody>
            {entries && entries.length > 0 ? (
              entries.map((entry, index) => (
                <tr key={index}>
                  <td className="border-200">{entry.consumerLabel}</td>
                  <td className="border-200">{entry.circuitName}</td>
                  <td className="border-200">
                    <Input
                      type="text"
                      value={entry.powerSource || ''}
                      onChange={(e) =>
                        handlePowerSourceChange(index, e.target.value)
                      }
                    />
                  </td>
                  <td className="border-200">
                    <Input
                      type="number"
                      value={entry.power || ''}
                      onChange={(e) =>
                        handlePowerChange(index, e.target.value === '' ? null : parseInt(e.target.value))
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="align-middle text-center border-200">
                  <i>{t('No circuits in switchboard')}...</i>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
}
