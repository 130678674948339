import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../../components/form/FormGroupNote';
import { consumerApi } from '../../../../helpers/api/consumerApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { getDefaultSelectValue } from '../../../../helpers/utils/getDefaultSelectValue';

export default function ConsumerSelect({ isDisabled, isRequired }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext();

  const [isLoading, setIsLoading] = useState(false);
  const [consumers, setConsumers] = useState([]);

  const fetchConsumers = async () => {
    let currentPage = 1;
    let fetchedConsumers = [];
    setIsLoading(true);

    while (true) {
      try {
        const response = await consumerApi.getConsumers({
          perPage: 1000,
          page: currentPage
        });

        const { items, page, lastPage } = response.data;

        fetchedConsumers = [...fetchedConsumers, ...items];
        currentPage++;

        if (lastPage === 0 || page === lastPage) {
          break;
        }
      } catch (error) {
        showErrorsFromRequest(error, t);
        break;
      }
    }

    fetchedConsumers = fetchedConsumers.map((consumer) => {
      return {
        value: consumer.id,
        label: consumer.label,
        aliases: consumer.aliases
      };
    });

    setConsumers(fetchedConsumers);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchConsumers();
  }, []);

  const fetchConsumer = async (consumerId) => {
    try {
      const response = await consumerApi.getConsumerById(consumerId);
      setValue('consumerId', response.data.id, { shouldValidate: true });
      setValue('type', response.data.type, { shouldValidate: true });
      setValue('power', response.data.power, { shouldValidate: true });
      setValue('label', response.data.label, { shouldValidate: true });
      setValue('protection', response.data.protection, {
        shouldValidate: true
      });
      setValue('cableType', response.data.cableType, { shouldValidate: true });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const handleSelectChange = (onChange, option) => {
    if (option?.value) {
      fetchConsumer(option.value);
    }

    onChange(option?.value || null);
  };

  const customFilterOption = (option, inputValue) => {
    // Check if the label, value, or any other field matches the inputValue
    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.aliases.some((alias) =>
        alias.alias.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  };

  return (
    <FormGroup>
      <Label htmlFor="consumerId">
        {t('Consumer')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="consumerId"
        control={control}
        render={({ field }) =>
          isLoading ? (
            <div>{t('Loading consumers')} ...</div>
          ) : (
            <Select
              options={consumers}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(option) => handleSelectChange(field.onChange, option)}
              defaultValue={getDefaultSelectValue(field.value, consumers)}
              isClearable
              isDisabled={isDisabled}
              filterOption={customFilterOption}
            />
          )
        }
      />
      {errors.consumerId && (
        <FormGroupNote type="warning">
          {errors.consumerId.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
