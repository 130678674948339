import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Card, CardBody } from 'reactstrap';

import { deleteConfirmationToast } from '../../../components/deleteConfirmationToast';
import RemoteTableWidget from '../../../components/RemoteTableWidget';
import SearchInput from '../../../components/SearchInput';
import AppContext from '../../../context/Context';
import { clientApi } from '../../../helpers/api/clientApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { getClientsColumns } from '../constants/clientsColumns';

const initialData = {
  count: 0,
  lastPage: 0,
  page: 1,
  perPage: 20,
  total: 0,
  items: null
};

const ClientsTable = ({ onClick }) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20
  });
  const [sort, setSort] = useState({ field: '', direction: '' });
  const [data, setData] = useState(initialData);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const { isOpenClientModal } = useContext(AppContext);

  const showDeleteConfirmationToast = (clientName, clientId) => {
    deleteConfirmationToast({
      title: `Please confirm you want to delete ${clientName} client?`,
      onClick: () => deleteClient(clientId),
      buttonText: 'Yes, delete!'
    });
  };

  const applySort = (field) => {
    if (sort.field === field) {
      setSort({ field, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ field, direction: 'asc' });
    }
  };

  const fetchClients = useCallback(
    async (search) => {
      let params = { ...pagination, searchTerm: search };

      if (sort.field !== '') {
        params.sortBy = sort.field;
        params.sortDirection = sort.direction;
      }

      try {
        const response = await clientApi.getClients(params);
        setData(response.data);
      } catch (error) {
        showErrorsFromRequest(error, t);
      }
    },
    [pagination.page, pagination.perPage, sort]
  );

  useEffect(() => {
    fetchClients(debouncedSearchValue);
  }, [
    fetchClients,
    pagination.page,
    pagination.perPage,
    sort,
    debouncedSearchValue
  ]);

  useEffect(() => {
    if (!isOpenClientModal) fetchClients(debouncedSearchValue);
  }, [isOpenClientModal]);

  const deleteClient = async (id) => {
    try {
      await clientApi.deleteClient(id);
      toast.success(t('Client has been successfully deleted!'));
      await fetchClients();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const handleClientSearch = async (value) => {
    setPagination({
      ...pagination,
      page: 1
    });
    setDebouncedSearchValue(value);
  };

  return (
    <Card>
      <div className="d-flex align-items-end search-with-filter px-4 py-3 flex-wrap gap-2">
        <SearchInput
          onSearch={(value) => handleClientSearch(value)}
          placeholder="Name, Email, Phone"
        />
      </div>
      <CardBody className="p-0">
        <RemoteTableWidget
          columns={getClientsColumns({
            onClick,
            applySort,
            t,
            showDeleteConfirmationToast
          })}
          keyField="id"
          page={pagination.page}
          perPage={pagination.perPage}
          items={data.items}
          lastPage={data.lastPage}
          resultsPerPage={[20, 30, 50]}
          onPageChange={(page) => setPagination({ ...pagination, page })}
          onPerPageChange={(perPage) =>
            setPagination({
              ...pagination,
              page: 1,
              perPage
            })
          }
          rowEvents={{
            onClick: (_, row, rowIndex) => onClick(row.id, rowIndex)
          }}
        />
      </CardBody>
    </Card>
  );
};

export default ClientsTable;
