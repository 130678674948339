import { Badge, Card, CardBody } from 'reactstrap';

import { ConnectionPhaseText } from '../../../../../helpers/enums/connectionPhaseEnum';

const ConsumerCard = ({ it, snapshot, onClick }) => {
  return (
    <Card
      onClick={() => onClick(it)}
      className={`mb-2 ${snapshot.isDragging ? 'border border-dark bg-soft-info' : ''} ${it.isDeleted ? 'opacity-50' : ''}`}
      style={{ minHeight: '1vh' }}
    >
      <CardBody className="p-0">
        <h6 className="pl-3 pt-3 pr-3 mb-2">{it.label}</h6>
        <div className="pl-3 pr-3 pb-2 d-flex justify-content-between">
          <div className="flex-fill">
            <span className="fs--1">{`#${it.code}`}</span>
          </div>
          {it.power !== 0 && (
            <div className="pl-2 my-auto fs--1">
              <i>{it.power}w</i>
            </div>
          )}
          <Badge
            color="soft-dark"
            className="my-auto fs--1 font-weight-light ml-3"
            style={{
              backgroundColor: it.hasHighTension && '#FF4D00',
              color: it.hasHighTension && 'white'
            }}
            pill
          >
            {it.connectionPhase
              ? ConnectionPhaseText[it.connectionPhase].toUpperCase()
              : '3N'}
          </Badge>
        </div>
      </CardBody>
    </Card>
  );
};

export default ConsumerCard;
