import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

import { HttpResponseStatus } from '../../constants/httpResponseStatus';

export const showErrorsFromRequest = (error, t) => {
  if (
    isAxiosError(error) &&
    error.response?.status === HttpResponseStatus.NotFound
  ) {
    toast.error(t('Resource not found'));
    return;
  }

  if (
    isAxiosError(error) &&
    error.response?.status === HttpResponseStatus.Unauthorized
  ) {
    if (window.location.pathname !== '/auth/login') {
      window.location.href = '/auth/login';
    } else {
      toast.error(t('Invalid credentials'));
    }
    return;
  }

  if (typeof error === 'string') {
    toast.error(t(error));
    return;
  }

  const errors = error?.response?.data?.errors;
  if (Array.isArray(errors) && errors.length > 0) {
    const errorMessage = errors.map((error) => error.errorMessage).join('\n');
    toast.error(t(errorMessage) || errorMessage);
  } else toast.error(t('System error: Please try again later!'));
};
