import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';

import FormGroupNote from '../../../../components/form/FormGroupNote';

export default function CablesHaveTileLabelCheckbox({ isDisabled = false }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup className="d-flex align-items-center">
      <Label htmlFor="cablesShouldHaveTileLabels" className="m-0 mr-2">
        {t('Cables should have tile labels')}
      </Label>
      <Controller
        name="cablesShouldHaveTileLabels"
        control={control}
        render={({ field }) => (
          <Input
            id="cablesShouldHaveTileLabels"
            type="checkbox"
            {...field}
            checked={!!field.value}
            className="m-0 position-static"
            disabled={isDisabled}
          />
        )}
      />
      {errors.cablesShouldHaveTileLabels && (
        <FormGroupNote type="warning">
          {errors.cablesShouldHaveTileLabels.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
