import {
  faArrowDownWideShort,
  faArrowUpWideShort
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'reactstrap';

import { SortDirection } from '../../helpers/enums/sortEnum';
import { getDefaultSelectValue } from '../../helpers/utils/getDefaultSelectValue';

export default function SortDropdown({
  options,
  isDisabled = false,
  isLoading = false
}) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = searchParams.get('sortBy') || options[0].value;
  const sortDirection = searchParams.get('sortDirection') || SortDirection.Desc;

  const handleSortByChange = (value) => {
    setSearchParams((params) => {
      params.set('sortBy', value);
      return params;
    });
  };

  const handleSortDirectionChange = () => {
    setSearchParams((params) => {
      params.set(
        'sortDirection',
        Number(sortDirection) === SortDirection.Desc
          ? SortDirection.Asc
          : SortDirection.Desc
      );
      return params;
    });
  };

  return (
    <div className="gap-2 d-flex">
      <Select
        options={options}
        getOptionLabel={(option) => t(option.label)}
        getOptionValue={(option) => option.value}
        isDisabled={isDisabled}
        onChange={(option) => handleSortByChange(option.value)}
        value={getDefaultSelectValue(Number(sortBy), options)}
        isLoading={isLoading}
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%'
          })
        }}
      />
      <Button
        size="icon"
        onClick={handleSortDirectionChange}
        title={
          Number(sortDirection) === SortDirection.Asc
            ? t('Ascending')
            : t('Descending')
        }
      >
        <FontAwesomeIcon
          icon={
            Number(sortDirection) === SortDirection.Asc
              ? faArrowUpWideShort
              : faArrowDownWideShort
          }
        />
      </Button>
    </div>
  );
}
