export const toastMessages = {
  en: {
    translations: {
      'Client successfully created!': 'Client successfully created!',
      'Consumer successfully created!': 'Consumer successfully created!',
      'Dynamic entry successfully saved!': 'Dynamic entry successfully saved!',
      'Product successfully saved!': 'Product successfully saved!',
      'Project successfully created!': 'Project successfully created!',
      'Unable to retrieve client information.':
        'Unable to retrieve client information.',
      'Unable to retrieve consumer data.': 'Unable to retrieve consumer data.',
      'Unable to retrieve product information.':
        'Unable to retrieve product information.',
      'Unable to load project information':
        'Unable to load project information',
      'Unable to load project offer': 'Unable to load project offer',
      'Profile has been successfully deleted!':
        'Profile has been successfully deleted!',
      'Enter the profile name:': 'Enter the profile name:',
      'Profile has been successfully created!':
        'Profile has been successfully created!',
      'Profile has been successfully updated!':
        'Profile has been successfully updated!',
      'Profile has been set as default!': 'Profile has been set as default!',
      'Project has been successfully deleted!':
        'Project has been successfully deleted!',
      'Please confirm you want to remove': 'Please confirm you want to remove',
      project: 'project',
      "This action can't be reverted!": "This action can't be reverted!",
      'Yes, delete!': 'Yes, delete!',
      'Resource not found': 'Resource not found',
      'Invalid credentials': 'Invalid credentials',
      'System error: Please try again later!':
        'System error: Please try again later!',
      'Check your email for the reset link':
        'Check your email for the reset link',
      'Password reset successfully': 'Password reset successfully',
      'Client has been successfully deleted!':
        'Client has been successfully deleted!',
      'Entry has been successfully deleted!':
        'Entry has been successfully deleted!',
      'Consumer has been successfully deleted!':
        'Consumer has been successfully deleted!',
      'Please confirm you want to delete': 'Please confirm you want to delete',
      consumer: 'consumer',
      'Products successfully imported!': 'Products successfully imported!',
      'Product deleted successfully!': 'Product deleted successfully!',
      'Please confirm you want to delete product with':
        'Please confirm you want to delete product with',
      'Unable to retrieve projects': 'Unable to retrieve projects',
      'Project status updated': 'Project status updated',
      'File deleted successfully': 'File deleted successfully',
      'Unable to load project history': 'Unable to load project history',
      'Comment posted successfully': 'Comment posted successfully',
      'Unable to post comment': 'Unable to post comment',
      'Unable to retrieve connection types.':
        'Unable to retrieve connection types.',
      'Unable to retrieve switchboard mount types.':
        'Unable to retrieve switchboard mount types.',
      'Consumer successfully saved.': 'Consumer successfully saved.',
      'Consumer successfully deleted.': 'Consumer successfully deleted.',
      subzone: 'subzone',
      'Subzone successfully saved.': 'Subzone successfully saved.',
      'Zone successfully saved.': 'Zone successfully saved.',
      'Zone successfully deleted.': 'Zone successfully deleted.',
      'Switchboard settings successfully saved.':
        'Switchboard settings successfully saved.',
      'Consumer successfully moved.': 'Consumer successfully moved.',
      'Role permissions successfully updated!':
        'Role permissions successfully updated!',
      'Not found entity error': (name) => `No ${name.toLowerCase()} found`,
      'Could not load': (name) => `Could not load ${name.toLowerCase()}`,
      'Category has products.': 'Category has products.',
      'Cable color successfully saved.': 'Cable color successfully saved.',
      'A cable color with that name already exists!':
        'A cable color with that name already exists!',
      'SKU must be unique.': 'SKU must be unique.'
    }
  },
  ro: {
    translations: {
      'Client successfully created!': 'Client creat cu succes!',
      'Consumer successfully created!': 'Consumator creat cu succes!',
      'Dynamic entry successfully saved!':
        'Intrare dinamică salvată cu succes!',
      'Product successfully saved!': 'Produs salvat cu succes!',
      'Project successfully created!': 'Proiect creat cu succes!',
      'Unable to retrieve client information.':
        'Nu se poate obține informațiile despre client.',
      'Unable to retrieve consumer data.':
        'Nu se poate obține datele consumatorului.',
      'Unable to retrieve product information.':
        'Nu se poate obține informațiile despre produs.',
      'Unable to load project information':
        'Nu se poate încărca informațiile despre proiect',
      'Unable to load project offer': 'Nu se poate încărca oferta proiectului',
      'Profile has been successfully deleted!':
        'Profilul a fost șters cu succes!',
      'Enter the profile name:': 'Introduceți numele profilului:',
      'Profile has been successfully created!':
        'Profilul a fost creat cu succes!',
      'Profile has been successfully updated!':
        'Profilul a fost actualizat cu succes!',
      'Profile has been set as default!': 'Profilul a fost setat ca implicit!',
      'Project has been successfully deleted!':
        'Proiectul a fost șters cu succes!',
      'Please confirm you want to remove':
        'Vă rugăm să confirmați că doriți să ștergeți',
      project: 'proiect',
      "This action can't be reverted!": 'Această acțiune nu poate fi anulată!',
      'Yes, delete!': 'Da, șterge!',
      'Resource not found': 'Resursa nu a fost găsită',
      'Invalid credentials': 'Credențiale invalide',
      'System error: Please try again later!':
        'Eroare de sistem: Vă rugăm să încercați mai târziu!',
      'Check your email for the reset link':
        'Verificați-vă emailul pentru linkul de resetare',
      'Password reset successfully':
        'Resetarea parolei a fost realizată cu succes',
      'Client has been successfully deleted!':
        'Clientul a fost șters cu succes!',
      'Entry has been successfully deleted!':
        'Intrarea a fost ștearsă cu succes!',
      'Consumer has been successfully deleted!':
        'Consumatorul a fost șters cu succes!',
      'Please confirm you want to delete':
        'Vă rugăm să confirmați că doriți să ștergeți',
      consumer: 'consumator',
      'Products successfully imported!':
        'Produsele au fost importate cu succes!',
      'Product deleted successfully!': 'Produsul a fost șters cu succes!',
      'Please confirm you want to delete product with':
        'Vă rugăm să confirmați că doriți să ștergeți produsul cu',
      'Unable to retrieve projects': 'Nu se pot obține proiectele',
      'Project status updated': 'Starea proiectului a fost actualizată',
      'File deleted successfully': 'Fișierul a fost șters cu succes',
      'Unable to load project history':
        'Nu se poate încărca istoricul proiectului',
      'Comment posted successfully': 'Comentariul a fost postat cu succes',
      'Unable to post comment': 'Nu se poate posta comentariul',
      'Unable to retrieve connection types.':
        'Nu se pot obține tipurile de conexiune.',
      'Unable to retrieve switchboard mount types.':
        'Nu se pot obține tipurile de montare a tabloului electric.',
      'Consumer successfully saved.': 'Consumatorul a fost salvat cu succes.',
      'Consumer successfully deleted.': 'Consumatorul a fost șters cu succes.',
      subzone: 'subzonă',
      'Subzone successfully saved.': 'Subzona a fost salvată cu succes.',
      'Zone successfully saved.': 'Zona a fost salvată cu succes.',
      'Zone successfully deleted.': 'Zona a fost ștearsă cu succes.',
      'Switchboard settings successfully saved.':
        'Setările tabloului electric au fost salvate cu succes.',
      'Consumer successfully moved.': 'Consumatorul a fost mutat cu succes.',
      'Role permissions successfully updated!':
        'Permisiunile rolului au fost actualizate cu succes!',
      'Not found entity error': (name) =>
        `${name.toLowerCase()} nu a fost gasit`,
      'Could not load': (name) => `Nu s-a putut incarca ${name.toLowerCase()}`,
      'Category has products.': 'Categoria are produse.',
      'Cable color successfully saved.':
        'Culoarea cablului a fost salvată cu succes.',
      'A cable color with that name already exists!':
        'Culoarea cablului există deja!',
      'SKU must be unique.': 'SKU trebuie sa fie unic.'
    }
  },
  de: {
    translations: {
      'Client successfully created!': 'Kunde erfolgreich erstellt!',
      'Consumer successfully created!': 'Verbraucher erfolgreich erstellt!',
      'Dynamic entry successfully saved!':
        'Dynamischer Eintrag erfolgreich gespeichert!',
      'Product successfully saved!': 'Produkt erfolgreich gespeichert!',
      'Project successfully created!': 'Projekt erfolgreich erstellt!',
      'Unable to retrieve client information.':
        'Kundeninformationen konnten nicht abgerufen werden.',
      'Unable to retrieve consumer data.':
        'Verbraucherdaten konnten nicht abgerufen werden.',
      'Unable to retrieve product information.':
        'Produktinformationen konnten nicht abgerufen werden.',
      'Unable to load project information':
        'Projektinformationen konnten nicht geladen werden',
      'Unable to load project offer':
        'Projektangebot konnte nicht geladen werden',
      'Profile has been successfully deleted!':
        'Profil wurde erfolgreich gelöscht!',
      'Enter the profile name:': 'Geben Sie den Profilnamen ein:',
      'Profile has been successfully created!':
        'Profil wurde erfolgreich erstellt!',
      'Profile has been successfully updated!':
        'Profil wurde erfolgreich aktualisiert!',
      'Profile has been set as default!':
        'Profil wurde als Standard festgelegt!',
      'Project has been successfully deleted!':
        'Projekt wurde erfolgreich gelöscht!',
      'Please confirm you want to remove':
        'Bitte bestätigen Sie, dass Sie entfernen möchten',
      project: 'Projekt',
      "This action can't be reverted!":
        'Diese Aktion kann nicht rückgängig gemacht werden!',
      'Yes, delete!': 'Ja, löschen!',
      'Resource not found': 'Ressource nicht gefunden',
      'Invalid credentials': 'Ungültige Anmeldeinformationen',
      'System error: Please try again later!':
        'Systemfehler: Bitte versuchen Sie es später erneut!',
      'Check your email for the reset link':
        'Überprüfen Sie Ihre E-Mail auf den Zurücksetzungslink',
      'Password reset successfully': 'Passwort erfolgreich zurückgesetzt',
      'Client has been successfully deleted!':
        'Kunde wurde erfolgreich gelöscht!',
      'Entry has been successfully deleted!':
        'Eintrag wurde erfolgreich gelöscht!',
      'Consumer has been successfully deleted!':
        'Verbraucher wurde erfolgreich gelöscht!',
      'Please confirm you want to delete':
        'Bitte bestätigen Sie, dass Sie löschen möchten',
      consumer: 'Verbraucher',
      'Products successfully imported!': 'Produkte erfolgreich importiert!',
      'Product deleted successfully!': 'Produkt erfolgreich gelöscht!',
      'Please confirm you want to delete product with':
        'Bitte bestätigen Sie, dass Sie das Produkt löschen möchten',
      'Unable to retrieve projects': 'Projekte konnten nicht abgerufen werden',
      'Project status updated': 'Projektstatus aktualisiert',
      'File deleted successfully': 'Datei erfolgreich gelöscht',
      'Unable to load project history':
        'Projektgeschichte konnte nicht geladen werden',
      'Comment posted successfully': 'Kommentar erfolgreich gepostet',
      'Unable to post comment': 'Kommentar konnte nicht gepostet werden',
      'Unable to retrieve connection types.':
        'Verbindungstypen konnten nicht abgerufen werden.',
      'Unable to retrieve switchboard mount types.':
        'Schaltschrankmontagearten konnten nicht abgerufen werden.',
      'Consumer successfully saved.': 'Verbraucher erfolgreich gespeichert.',
      'Consumer successfully deleted.': 'Verbraucher erfolgreich gelöscht.',
      subzone: 'Unterzone',
      'Subzone successfully saved.': 'Unterzone erfolgreich gespeichert.',
      'Zone successfully saved.': 'Zone erfolgreich gespeichert.',
      'Zone successfully deleted.': 'Zone erfolgreich gelöscht.',
      'Switchboard settings successfully saved.':
        'Schaltschrankeinstellungen erfolgreich gespeichert.',
      'Consumer successfully moved.': 'Verbraucher erfolgreich verschoben.',
      'Role permissions successfully updated!':
        'Rollenberechtigungen erfolgreich aktualisiert!',
      'Not found entity error': (name) =>
        `Keine ${name.toLowerCase()} gefunden`,
      'Could not load': (name) =>
        `${name.toLowerCase()} konnten nicht geladen werden`,
      'Category has products.': 'Kategorie hat Produkte.',
      'Cable color successfully saved.': 'Kabelfarbe erfolgreich gespeichert.',
      'A cable color with that name already exists!':
        'Eine Kabelfarbe mit diesem Namen existiert bereits!',
      'SKU must be unique.': 'Die SKU muss eindeutig sein.'
    }
  }
};
