import { Badge, Card, CardBody } from 'reactstrap';

import { X7TypeText } from '../../../helpers/enums/x7TypeEnum';

export default function X7ItemCard({ it, snapshot, onClick }) {
  return (
    <Card
      onClick={() => onClick(it)}
      className={`mb-2 ${snapshot.isDragging ? 'border border-dark bg-soft-info' : ''} ${it?.isDisabled ? 'opacity-50' : ''}`}
      style={{ minHeight: '1vh' }}
    >
      <CardBody className="p-0">
        <h6 className="pl-3 pt-3 pr-3 mb-2">{it.label}</h6>
        <div className="px-3 pb-2 d-flex justify-content-between">
          <div className="flex-fill">
            <span className="fs--1">{`#${it.idx}`}</span>
          </div>
          <Badge
            color="soft-dark"
            className="my-auto fs--1 font-weight-light ml-3"
            pill
          >
            {X7TypeText[it.type]}
          </Badge>
        </div>
      </CardBody>
    </Card>
  );
}
