export const SortDirection = {
  Asc: 0,
  Desc: 1
};

export const CategorySortBy = {
  Name: 0,
  CreatedAt: 1
};

export const ClientSortBy = {
  Id: 0,
  Name: 1,
  Email: 2,
  Phone: 3,
  CreatedAt: 4
};

export const ProductSortBy = {
  Sku: 0,
  Label: 1,
  Type: 2,
  Manufacturer: 3,
  ManufacturerSku: 4,
  Price: 5
};

export const ProjectSortBy = {
  UpdatedAt: 0,
  Name: 1,
  FinanceDiscount: 2,
  FinanceHourlyRate: 3,
  Status: 4
};
