import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Badge, Table } from 'reactstrap';

import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { ProjectStatusText } from '../../../../helpers/enums/statusEnum';
import { getStatusColor } from '../../../../helpers/utils/utils';
import CheckUserPermission from '../../../../hoc/checkUserPermissions';

export default function HistoryTable({ entries }) {
  const { t } = useTranslation();
  return (
    <div className="table-responsive fs--1">
      <Table striped className="border-bottom border-200">
        <thead className="bg-200 text-900">
          <tr>
            <th className="border-0">{t('Date')}</th>
            <CheckUserPermission
              permissions={[
                PermissionType.PROJECT_DETAILS_HISTORY.CAN_SEE_PUBLIC_COLUMN
              ]}
            >
              <th className="border-0">Public</th>
            </CheckUserPermission>
            {/*TODO: get history type from backend*/}
            {/*<th className="border-0">Type</th>*/}
            <th className="border-0">Status</th>
            <th className="border-0">{t('Comments')}</th>
          </tr>
        </thead>
        <tbody>
          {entries && entries.length > 0 ? (
            entries.map((entry) => (
              <tr key={entry.id}>
                <td className="border-200">
                  {moment(entry.createdAt).format('Do MMM YY, HH:mm')}
                </td>
                <CheckUserPermission
                  permissions={[
                    PermissionType.PROJECT_DETAILS_HISTORY
                      .CAN_SEE_CUSTOMER_VISIBLE_CHECKBOX
                  ]}
                >
                  <td className="border-200">
                    <FontAwesomeIcon
                      icon={entry.isPublic ? 'eye' : 'eye-slash'}
                    />
                  </td>
                </CheckUserPermission>
                {/*TODO: get history type from backend*/}
                {/*<td className="align-middle text-center border-200">System</td>*/}
                <td className="border-200">
                  <Badge
                    color={`${getStatusColor(entry.status)}`}
                    className="mr-2 text-capitalize"
                    pill
                  >
                    {entry.status != null
                      ? t(ProjectStatusText[entry.status])
                      : ''}
                  </Badge>
                </td>
                <td className="border-200">{entry.comments}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="align-middle text-center border-200">
                <i>{t('No comments posted yet')} ... </i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
