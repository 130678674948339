export function productDataNullConvert(data) {
  return {
    sku: data.sku ?? '',
    categoryId: data.categoryId ?? '',
    manufacturerId: data.manufacturerId ?? '',
    manufacturerSku: data.manufacturerSku ?? '',
    price: data.price,
    label: data.label ?? '',
    group: data.group ?? '',
    destination: data.destination ?? '',
    width: data.width,
    protectionType: data.protectionType ?? '',
    connectionType: data.connectionType ?? '',
    amperage: data.amperage,
    differentialAmperage: data.differentialAmperage,
    differentialClass: data.differentialClass ?? '',
    differentialTripCurve: data.differentialTripCurve ?? '',
    horizontalModules: data.horizontalModules,
    verticalModules: data.verticalModules,
    hasGrounding: data.hasGrounding ?? '',
    mountType: data.mountType ?? '',
    cableThickness: data.cableThickness,
    cableColorId: data.cableColorId,
    sellPrice: data.sellPrice,
    description: data.description ?? '',
    measurementUnit: data.measurementUnit ?? ''
  };
}
