import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from '../../components/common/PageHeader';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import X7HeaderButtons from './components/X7HeaderButtons';
import X7ItemsList from './components/X7ItemsList';
import X7Modal from './components/X7Modal';

export default function X7Screen() {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [project, setProject] = useState({});
  const [x7Switchboard, setX7Switchboard] = useState([]);

  const fetchProject = useCallback(async () => {
    try {
      const response = await projectApi.getProjectById(projectId);
      setProject(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return (
    <>
      <PageHeader
        title={'X7: ' + (project.name || '...')}
        controls={() => (
          <div className="d-flex flex-wrap justify-content-end gap-4">
            <X7HeaderButtons projectId={projectId} />
          </div>
        )}
      />
      <X7ItemsList
        x7Switchboard={x7Switchboard}
        setX7Switchboard={setX7Switchboard}
      />
      <X7Modal x7Switchboard={x7Switchboard} />
    </>
  );
}
