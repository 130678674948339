import { useEffect } from 'react';

export const useFormScrollIntoView = ({ form }) => {
  useEffect(() => {
    const fields = Object.keys(form.formState.errors);
    if (fields.length === 0) return;
    const input = document.querySelector(`*[id="${fields[0]}"]`);
    if (!input) return;
    input.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });
  }, [form.formState.errors]);

  return null;
};
