import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function FrameSelect({ isDisabled, isRequired = false }) {
  const { watch } = useFormContext();

  const switchboardHeight = watch('switchboardHeight');
  const horizontalModules = watch('horizontalModulesPerRow');
  const mountType = watch('mountType');

  const params = {
    verticalModules: switchboardHeight,
    horizontalModules,
    mountType,
    categorySlug: systemProductCategorySlug.Frame
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Frame"
      inputName="frame"
      params={params}
    />
  );
}
