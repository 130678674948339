import { useTranslation } from 'react-i18next';

import FloatValueInputSelect from '../../../../components/form/FloatValueInputSelect';
import {
  horizontalModulesPerRow,
  numberOfClampRows,
  numberOfGenericRows,
  numberOfReservedClampRows,
  numberOfReservedRows,
  switchboardHeights,
  switchboardWidths,
  verticalModulesPerClampRow,
  verticalModulesPerGenericRow
} from '../../../../constants/switchboardSettings';
import HasMeterBoxCheckbox from './HasMeterBoxCheckbox';
import IsAllIncludedCheckbox from './isAllIncludedCheckbox';
import SwitchboardManufacturerFilter from './SwitchboardManufacturerFilter';

export default function SwitchboardParametersFields({ isLoading }) {
  const { t } = useTranslation();
  return (
    <div>
      <hr />
      <h6 className="mt-4 text-primary font-weight-bold d-flex gap-2">
        {t('Switchboard parameters')}
      </h6>
      <SwitchboardManufacturerFilter isDisabled={isLoading} />
      <FloatValueInputSelect
        label="switchboardWidth"
        displayLabel="Switchboard width"
        isDisabled={isLoading}
        inputGroupText="mm"
        options={switchboardWidths}
      />
      <FloatValueInputSelect
        label="switchboardHeight"
        displayLabel="Switchboard height"
        isDisabled={isLoading}
        inputGroupText="MU"
        options={switchboardHeights}
      />
      <FloatValueInputSelect
        label="horizontalModulesPerRow"
        displayLabel="Horizontal modules per row"
        isDisabled={isLoading}
        inputGroupText="modules"
        options={horizontalModulesPerRow}
      />
      <FloatValueInputSelect
        label="verticalModulesPerGenericRow"
        displayLabel="Vertical modules per generic row"
        isDisabled={isLoading}
        inputGroupText="MV"
        options={verticalModulesPerGenericRow}
      />
      <FloatValueInputSelect
        label="numberOfGenericRows"
        displayLabel="Number of generic rows"
        isDisabled={isLoading}
        options={numberOfGenericRows}
      />
      <FloatValueInputSelect
        label="verticalModulesPerClampRow"
        displayLabel="Vertical modules per clamp row"
        isDisabled={isLoading}
        inputGroupText="MV"
        options={verticalModulesPerClampRow}
      />
      <FloatValueInputSelect
        label="numberOfClampRows"
        displayLabel="Number of clamp rows"
        isDisabled={isLoading}
        options={numberOfClampRows}
      />
      <FloatValueInputSelect
        label="verticalModulesPerReservedRow"
        displayLabel="Vertical modules per reserved row"
        inputGroupText="MV"
        isDisabled={isLoading}
        options={numberOfReservedRows}
      />
      <FloatValueInputSelect
        label="numberOfReservedRows"
        displayLabel="Number of reserved row"
        isDisabled={isLoading}
        options={numberOfReservedClampRows}
      />
      <HasMeterBoxCheckbox isDisabled={isLoading} />
      <IsAllIncludedCheckbox isDisabled={isLoading} />
    </div>
  );
}
