import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { cableColorsApi } from '../../../../../helpers/api/cableColorsApi';
import RequiredStarLabel from '../../../../common/requiredStarLabel';
import EntitySelect from '../../../../entitySelect/EntitySelect';
import FormGroupNote from '../../../FormGroupNote';
import CableColorActionButtons from './CableColorActionButtons';

export default function CableColorSelect({ isLoading, isRequired = false }) {
  const { fetchData, setFetchData } = useContext(AppContext);
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const inputName = 'cableColorId';

  return (
    <FormGroup>
      <Label htmlFor={inputName}>
        {t('Cable Color')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={inputName}
        control={control}
        render={({ field }) => (
          <div className="d-flex gap-2">
            <EntitySelect
              name={inputName}
              displayName={t('Cable Color')}
              fieldValue={field.value}
              onChange={field.onChange}
              fetchDataFunction={cableColorsApi.getCableColors}
              placeholder="Not specified"
              isDisabled={isLoading}
              isFetchData={fetchData}
              setFetchData={setFetchData}
            />
            <CableColorActionButtons />
          </div>
        )}
      />
      {errors[inputName] && (
        <FormGroupNote type={'warning'}>
          {errors[inputName]?.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
