import { zodResolver } from '@hookform/resolvers/zod';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row, Spinner } from 'reactstrap';

import InputFormField from '../../../components/form/InputFormField';
import AppContext from '../../../context/Context';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { userApi } from '../../../helpers/api/user/userApi';
import { loginFormSchema } from '../../../helpers/formValidations/loginFormSchema';
import { getUserIdentity } from '../../../helpers/utils/getUserIdentity';
import { setUserToCookies } from '../../../helpers/utils/removeUserFromCookies';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import PasswordInput from './PasswordInput';

const LoginForm = () => {
  const { t } = useTranslation();
  // const [remember, setRemember] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { setIsAuthenticated, setUser } = useContext(AppContext);

  const form = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    mode: 'onChange',
    resolver: zodResolver(loginFormSchema)
  });

  useFormScrollIntoView({ form });

  const handleSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await userApi.userLogin(data.email, data.password);

      const { token, refreshToken, tokenType } = response.data;
      setUserToCookies({ token, refreshToken, tokenType });

      toast.success(`${t('Logged in as')} ${data.email}`);
      getUserIdentity({ setUser, setIsAuthenticated, t });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <InputFormField
          displayLabel="Email"
          label="email"
          isRequired
          isDisabled={isLoading}
          placeholder="Email adress"
          inputType="email"
        />
        <PasswordInput label="password" displayLabel="Password" />
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            {/* <FormGroup check inline>
              <Input
                id="customCheckRemember"
                checked={remember}
                onChange={({ target }) => setRemember(target.checked)}
                type="checkbox"
              />
              <Label check htmlFor="customCheckRemember">
                {t('Remember me')}
              </Label>
            </FormGroup> */}
          </Col>
          <Col xs="auto">
            <Link to="/auth/reset-password">{t('Forgot password?')}</Link>
          </Col>
        </Row>

        <Button
          color="primary"
          block
          className="mt-3"
          disabled={isLoading || !form.formState.isValid}
        >
          {isLoading ? <Spinner size="sm" children="" /> : t('Login')}
        </Button>

        <Button
          color="success"
          block
          onClick={() => navigate('/auth/register')}
        >
          {t('Register')}
        </Button>
      </Form>
    </FormProvider>
  );
};

export default LoginForm;
