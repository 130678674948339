import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function SlottedPlate({ isDisabled, isRequired = false }) {
  const { watch } = useFormContext();

  const verticalModules = watch('verticalModulesPerGenericRow');
  const horizontalModules = watch('horizontalModulesPerRow');

  const params = {
    verticalModules,
    horizontalModules,
    categorySlug: systemProductCategorySlug.SlottedPlate
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Slotted Plate"
      inputName="slottedPlate"
      params={params}
    />
  );
}
