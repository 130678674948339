import axios from '../../axios/axios.service';

export const switchboardApi = {
  getSwitchboardsByProjectId: async (projectId, params) => {
    return await axios.get(`/projects/${projectId}/switchboard`, { params });
  },
  updateSwitchboardsByProjectId: async (projectId, data) => {
    return await axios.post(`/projects/${projectId}/switchboard`, data);
  }
};
