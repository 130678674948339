import PropTypes from 'prop-types';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from 'reactstrap';

import FalconCardHeader from './common/FalconCardHeader';
import Loader from './common/Loader';

const RemoteTableWidget = ({
  title = null,
  controls = null,
  columns,
  items,
  page,
  keyField,
  lastPage,
  perPage,
  perPageOptions,
  cellEdit,
  onPerPageChange,
  onPageChange,
  onSortChange,
  cardClassName,
  cardBodyClassName,
  isTwoColumnsTable,
  secondColumnItems,
  ...rest
}) => {
  const { t } = useTranslation();
  // Check whether there exists items
  const hasItems = items && items.length > 0;
  const isLoading = items === undefined || items === null;

  const handleTableChange = (event, data) => {
    if (event === 'sort' && onSortChange)
      onSortChange(data.sortField, data.sortOrder);
  };

  return (
    <Card className={`mt-2 ${cardClassName ?? ''}`}>
      {(title || controls) && (
        <FalconCardHeader title={title} light={false}>
          {controls}
        </FalconCardHeader>
      )}

      <CardBody className={`p-0 ${cardBodyClassName ?? ''}`}>
        {isLoading ? (
          <Container>
            <Loader size="sm" />
          </Container>
        ) : (
          <div className="d-flex">
            <BootstrapTable
              remote={true}
              keyField={keyField}
              data={items ?? []}
              columns={columns}
              cellEdit={cellEdit}
              bordered={false}
              classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger border-top border-200"
              headerClasses="bg-200 text-900 border-y border-200"
              onTableChange={handleTableChange}
              wrapperClasses="table-responsive"
              {...rest}
            />
            {isTwoColumnsTable && (
              <BootstrapTable
                remote={true}
                keyField={keyField}
                data={secondColumnItems ?? []}
                columns={columns}
                cellEdit={cellEdit}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                onTableChange={handleTableChange}
                wrapperClasses="table-responsive border-left"
                {...rest}
              />
            )}
          </div>
        )}
        {hasItems && (
          <Row className="px-3 pb-3 pt-4 g-1">
            <Col className="pl-3 fs--1 mt-3">
              {t('Results per page')} &nbsp;
              <ButtonGroup className="btn-group-sm">
                {perPageOptions.map((perPageOption) => (
                  <Button
                    key={`rpp_${perPageOption}`}
                    color={perPage === perPageOption ? 'primary' : 'light'}
                    onClick={() =>
                      onPerPageChange && onPerPageChange(perPageOption)
                    }
                  >
                    {perPageOption}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
            <Col className="fs--1 pl-lg-10 text-nowrap pt-3">
              <span className="text-nowrap">
                {t('Page')} {page} {lastPage ? `${t('of')} ${lastPage}` : ''}
              </span>
            </Col>
            <Col xs="auto" className="pr-3 pt-3">
              <Button
                color={page === 1 ? 'light' : 'primary'}
                size="sm"
                onClick={() => onPageChange && onPageChange(page - 1)}
                disabled={page === 1}
                className="px-4"
              >
                {t('Previous')}
              </Button>
              <Button
                color={page >= lastPage ? 'light' : 'primary'}
                size="sm"
                onClick={() => onPageChange && onPageChange(page + 1)}
                disabled={page >= lastPage}
                className="px-4 ml-2"
              >
                {t('Next')}
              </Button>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

RemoteTableWidget.propTypes = {
  title: PropTypes.string,
  controls: PropTypes.node,
  columns: PropTypes.array,
  items: PropTypes.array,
  page: PropTypes.number,
  keyField: PropTypes.string,
  lastPage: PropTypes.number,
  perPage: PropTypes.number,
  perPageOptions: PropTypes.array,
  sort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string
  }),
  onPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func
};

RemoteTableWidget.defaultProps = {
  perPageOptions: [20, 30, 50],
  perPage: 20,
  page: 1,
  lastPage: 0,
  title: '',
  sort: { field: null, direction: null }
};

export default RemoteTableWidget;
