export const ConnectionPhase = {
  G: 0,
  L1: 1,
  L2: 2,
  L3: 3,
  N: 5
};

export const ConnectionPhaseText = {
  [ConnectionPhase.G]: 'G',
  [ConnectionPhase.L1]: 'L1',
  [ConnectionPhase.L2]: 'L2',
  [ConnectionPhase.L3]: 'L3',
  [ConnectionPhase.N]: '3N'
};
