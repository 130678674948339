import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../context/Context';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import UserProfileForm from '../form/UserProfileForm/UserProfileForm';
import SideModal from './SideModal';

export default function UserProfileModal() {
  const { t } = useTranslation();
  const { isProfileModalOpen, toggleProfileModal, user } =
    useContext(AppContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    phoneNumber: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const getUserData = useCallback(async () => {
    setIsLoading(true);

    try {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        phoneNumber: user.phoneNumber
      });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [t, user, setFormData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <SideModal
      isOpen={isProfileModalOpen}
      toggle={toggleProfileModal}
      title="Profile edit"
      subtitle={t('Edit profile')}
      icon="edit"
    >
      {error === undefined && (
        <UserProfileForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          defaultValues={formData}
          setFormData={setFormData}
          setError={setError}
        />
      )}

      {error !== undefined && <p>{error}</p>}
    </SideModal>
  );
}
