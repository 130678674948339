import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadCanvasTemplate, loadCaptchaEnginge } from 'react-simple-captcha';

import InputFormField from '../../../components/form/InputFormField';

export default function CaptchaField({ isDisabled }) {
  const { t } = useTranslation();

  useEffect(() => {
    loadCaptchaEnginge(6, 'black', 'white');
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column align-items-center">
        <InputFormField
          label="captcha"
          displayLabel="Please enter the code"
          isDisabled={isDisabled}
        />
        <div className="captcha-canvas-wrapper">
          <LoadCanvasTemplate reloadText={t('Refresh code')} />
        </div>
      </div>
    </div>
  );
}
