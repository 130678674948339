import { useCallback, useContext, useLayoutEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { categoryApi } from '../../../../../helpers/api/categoryApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import RequiredStarLabel from '../../../../common/requiredStarLabel';
import EntitySelect, {
  InputStates
} from '../../../../entitySelect/EntitySelect';
import FormGroupNote from '../../../FormGroupNote';
import ProductCategoryActionButtons from './ProductCategoryActionButtons';

export default function ProductCategorySelect({
  isRequired = false,
  isDisabled
}) {
  const [inputState, setInputState] = useState(InputStates.Loading);
  const { selectedCategory, setSelectedCategory } = useContext(AppContext);

  const [fetchCategory, setFetchCategory] = useState(false);

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
    setValue
  } = useFormContext();

  const inputName = 'categoryId';
  const categoryId = watch(inputName);
  const groupId = watch('group');

  const getCategory = useCallback(async () => {
    try {
      const response = await categoryApi.getCategory(categoryId);
      setSelectedCategory(response.data);
    } catch (e) {
      showErrorsFromRequest(e, t);
    }
  }, [categoryId, setSelectedCategory, t]);

  useLayoutEffect(() => {
    if (categoryId) {
      getCategory();
    } else {
      setSelectedCategory(null);
    }
  }, [categoryId, getCategory, setSelectedCategory]);

  useLayoutEffect(() => {
    setFetchCategory(true);
  }, [groupId]);

  const isLoadedAndNotSystemCategory =
    inputState === InputStates.Loaded && !selectedCategory?.isSystem;

  const isErrorAndGroupIdNotNullOrEmpty =
    inputState === InputStates.Error && (groupId != null || groupId !== '');

  const getCategories = async () => {
    if (groupId == null || groupId === '') {
      return { data: [] };
    }

    const response = await categoryApi.getCategories({ group: groupId });

    if (response.data.length > 0) {
      const categoryIndex = response.data?.findIndex(
        (category) => category.id === categoryId
      );

      if (categoryIndex === -1) {
        setValue(inputName, null);
        setSelectedCategory(null);
      } else {
        setSelectedCategory(response.data[categoryIndex]);
        setValue(inputName, response.data[categoryIndex].id);
      }
    }

    return response;
  };

  return (
    <FormGroup>
      <Label htmlFor={inputName}>
        {t('Category')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={inputName}
        control={control}
        render={({ field }) => (
          <div className="d-flex gap-2 align-items-center">
            <EntitySelect
              displayName={t('Category')}
              name="categoryId"
              fieldValue={field.value}
              onChange={field.onChange}
              placeholder="Not assigned"
              fetchDataFunction={getCategories}
              isDisabled={isDisabled}
              isFetchData={fetchCategory}
              setFetchData={setFetchCategory}
              setInputState={setInputState}
            />
            {(isLoadedAndNotSystemCategory ||
              isErrorAndGroupIdNotNullOrEmpty) && (
              <ProductCategoryActionButtons
                setFetchCategory={setFetchCategory}
                isDisabled={isDisabled}
              />
            )}
          </div>
        )}
      />
      {errors.categoryId && (
        <FormGroupNote type={'warning'}>
          {errors.categoryId.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
