import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

import RegisterForm from './components/RegisterForm';

export default function RegisterScreen() {
  const { t } = useTranslation();

  return (
    <Col md={9} lg={7} xl={6}>
      <Card>
        <CardBody className="fs--1 font-weight-normal p-5">
          <Row className="text-left justify-content-between mb-3">
            <Col xs="auto">
              <h5>{t('Register')}</h5>
            </Col>
          </Row>
          <RegisterForm />
        </CardBody>
      </Card>
    </Col>
  );
}
