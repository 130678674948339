import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { offersApi } from '../../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';

export default function OfferProfileSelect({ setOfferProfile }) {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);

  const fetchProfiles = useCallback(async () => {
    try {
      const response = await offersApi.getOffersProfiles();
      setProfiles(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, []);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const options = profiles.map((profile) => ({
    label: profile.label,
    value: profile.id
  }));

  const handleOnChange = (option) => {
    setOfferProfile(option ? option.value : null);
  };

  return (
    <Select
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      placeholder={t('Select offer profile')}
      isSearchable
      onChange={handleOnChange}
    />
  );
}
