import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import { ProductDestination } from '../../../helpers/enums/productDestinationEnum';
import FormGroupNote from '../FormGroupNote';
import ProductsSelect from '../ProductsSelect';

export default function DynamicEntryProduct({ isRequired, isDisabled }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label>{t('Product')}</Label>
      <Controller
        name="productId"
        control={control}
        render={({ field }) => (
          <ProductsSelect
            fieldValue={field.value}
            onChange={field.onChange}
            placeholder={t('Select a product')}
            isDisabled={isDisabled}
            destination={ProductDestination.ALL}
          />
        )}
      />
      {errors.productId && (
        <FormGroupNote type="warning">{errors.productId.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
