import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../common/requiredStarLabel';
import FormGroupNote from '../FormGroupNote';

export default function GroundingSelect({ isRequired = false, isDisabled }) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const options = [
    { value: 0, label: t('Not applicable') },
    { value: 1, label: t('Yes') },
    { value: 2, label: t('No') }
  ];

  const getDefaultValue = (fieldValue) => {
    if (fieldValue) {
      return options.find((option) => option.value === fieldValue);
    }
    return options[0];
  };

  const handleOnChange = (option, onChange) => {
    onChange(option ? option.value : null);
  };

  return (
    <FormGroup>
      <Label htmlFor="hasGrounding">
        {t('Has Grounding?')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="hasGrounding"
        control={control}
        render={({ field }) =>
          !isDisabled && (
            <Select
              id="hasGrounding"
              disabled={isDisabled}
              value={getDefaultValue(field.value)}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              options={options}
              onChange={(option) => handleOnChange(option, field.onChange)}
            />
          )
        }
      />
      {errors.hasGrounding && (
        <FormGroupNote type={'warning'}>
          {errors.hasGrounding.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
