export const getLanguageByLanguageCode = (languageCode) => {
  switch (languageCode) {
    case 'en':
      return 'english';
    case 'ro':
      return 'romanian';
    case 'de':
      return 'german';
    default:
      return 'unknown language';
  }
};
