export const UserRole = {
  CLIENT: 0,
  COLLABORATOR: 1,
  ADMIN: 2
};

export const UserRoleText = {
  [UserRole.CLIENT]: 'Client',
  [UserRole.COLLABORATOR]: 'Collaborator',
  [UserRole.ADMIN]: 'Admin'
};
