import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';

const ResetPasswordForm = ({ resetToken, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const shouldClear = await onSubmit(email, password, passwordConfirmation);
    if (shouldClear) {
      setEmail('');
      setPassword('');
      setPasswordConfirmation('');
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (resetToken) {
      setIsDisabled(!email || !password || !passwordConfirmation);
    } else {
      setIsDisabled(!email);
    }
  }, [email, password, passwordConfirmation]);

  const isResetTokenValid = () => {
    if (resetToken) {
      return (
        <>
          <FormGroup>
            <Input
              placeholder={t('New password')}
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              type="password"
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder={t('Confirm new password')}
              value={passwordConfirmation}
              onChange={({ target }) => setPasswordConfirmation(target.value)}
              type="password"
            />
          </FormGroup>
        </>
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          placeholder={t('Email address')}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      {isResetTokenValid()}
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          {isLoading ? <Spinner size="sm" children="" /> : t('Reset Password')}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default ResetPasswordForm;
