import { Route } from 'react-router-dom';

import ErrorLayout from '../../layouts/ErrorLayout';
import Error404Screen from '../../screens/error/Error404Screen';
import Error500Screen from '../../screens/error/Error500Screen';

export const errorRoutes = () => {
  return (
    <>
      <Route path="*" element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404Screen />} />
        <Route path="errors/500" element={<Error500Screen />} />
      </Route>
    </>
  );
};
