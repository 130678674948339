import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

import corner4 from '../../assets/img/illustrations/corner-4.png';
import createMarkup from '../../helpers/createMarkup';
import Background from './Background';

const PageHeader = ({
  title,
  titleTag: TitleTag,
  description,
  image,
  children,
  controls,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Card {...rest} className="mb-3 mt-sm-0 mt-5">
      <Background
        image={image}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <CardBody>
        <Row className="gap-3">
          <Col>
            <TitleTag className="mb-0 mt-1">{t(title)}</TitleTag>
          </Col>
          {controls != null ? (
            <Col className="text-right">{controls()}</Col>
          ) : (
            ''
          )}
        </Row>
        {description && (
          <Row>
            <Col>
              <p
                className={classNames('mt-2', { 'mb-0': !children })}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
              {children}
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  controls: PropTypes.any
};

PageHeader.defaultProps = { col: { lg: 8 }, image: corner4, titleTag: 'h4' };

export default PageHeader;
