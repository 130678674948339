import axios from '../axios/axios.service';

export const offersApi = {
  getOffersProfiles: async () => {
    return await axios.get(`/offers/profiles`);
  },
  getOffersProfile: async (profileId) => {
    return await axios.get(`/offers/profiles/${profileId}`);
  },
  deleteOfferProfile: async (profileId) => {
    return await axios.delete(`/offers/profiles/${profileId}`);
  },
  addOfferProfile: async (data) => {
    return await axios.post(`/offers/profiles`, data);
  },
  updateOfferProfile: async (id, data) => {
    return await axios.post(`/offers/profiles/${id}`, data);
  },
  setDefaultProfile: async (profileId) => {
    return await axios.post(`/offers/profiles/${profileId}/default`);
  },
  offerChangeProfile: async (offerId, profileId) => {
    return await axios.post(`/offers/${offerId}/change-profile`, {
      profile_id: profileId
    });
  },
  buildProjectOffer: async (projectId, profileId) => {
    return await axios.post(`/projects/${projectId}/build-offer`, {
      profileId
    });
  },
  getDynamicEntriesByProfileId: async (profileId) => {
    return await axios.get(`/offers/profiles/${profileId}/dynamic-entries`);
  },
  addDynamicEntryByProfileId: async (profileId, data) => {
    return await axios.post(
      `/offers/profiles/${profileId}/dynamic-entries`,
      data
    );
  },
  getDynamicEntryById: async (profileId, entryId) => {
    return await axios.get(
      `/offers/profiles/${profileId}/dynamic-entries/${entryId}`
    );
  },
  updateDynamicEntryById: async (profileId, entryId, data) => {
    return await axios.post(
      `/offers/profiles/${profileId}/dynamic-entries/${entryId}`,
      data
    );
  },
  deleteDynamicEntry: async (profileId, entryId) => {
    return await axios.delete(
      `/offers/profiles/${profileId}/dynamic-entries/${entryId}`
    );
  },
  getDynamicEntriesTokens: async () => {
    return await axios.get(`/offers/profiles/dynamic-entries/tokens`);
  }
};
