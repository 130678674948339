import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Card, CardBody } from 'reactstrap';

import FalconCardHeader from '../../../../components/common/FalconCardHeader';
import { productHistoryCommentsApi } from '../../../../helpers/api/historyCommentsApi';
import HistoryCommentsForm from './HistoryCommentsForm';
import HistoryTable from './HistoryTable';

const History = ({ projectId }) => {
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    if (projectId) fetchHistory();
  }, [projectId]);

  const fetchHistory = async () => {
    try {
      const response =
        await productHistoryCommentsApi.getHistoryCommentsByProjectId(
          projectId
        );
      setEntries(response.data);
    } catch (error) {
      if (error?.response?.status !== 404)
        toast.error(t('Unable to load project history'));
    }
  };

  return (
    <Card>
      <FalconCardHeader title="History" />
      <HistoryTable entries={entries} />
      <FalconCardHeader title="Post a new comment" light={false} />
      <CardBody>
        <HistoryCommentsForm
          projectId={projectId}
          fetchHistory={fetchHistory}
        />
      </CardBody>
    </Card>
  );
};

export default History;
