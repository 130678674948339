import axios from '../axios/axios.service';

export const manufacturerApi = {
  getManufacturerById: async (manufacturerId) => {
    return await axios.get(`/manufacturers/${manufacturerId}`);
  },
  getManufacturers: async () => {
    return axios.get(`/manufacturers`);
  }
};
