import React from 'react';
import { useTranslation } from 'react-i18next';

import ZoneModalForm from '../form/ZoneModalForm';
import CardModal from './CardModal';

const ZoneModal = ({
  isOpen,
  toggle,
  zone,
  switchboard,
  refreshSwitchboard
}) => {
  const { t } = useTranslation();
  const isEdit =
    typeof zone === 'object' &&
    zone !== null &&
    Number.isInteger(zone.id) &&
    zone.id >= 0;

  const defaultValues = {
    index: zone.index ?? switchboard.zones.length + 1,
    label: zone.label ?? '',
    protection: zone.protection ?? null
  };

  return (
    <CardModal
      title={isEdit ? `${t('Zone')} ${zone.code}` : t('Zone')}
      subtitle={
        isEdit ? `${t('Editing')} '${zone.label}'` : t('Create a new zone')
      }
      isOpen={isOpen}
      toggleModal={toggle}
    >
      <ZoneModalForm
        defaultValues={defaultValues}
        isOpen={isOpen}
        zone={zone}
        toggle={toggle}
        switchboard={switchboard}
        refreshSwitchboard={refreshSwitchboard}
        isEdit={isEdit}
      />
    </CardModal>
  );
};

export default ZoneModal;
