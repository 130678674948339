import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { categoryApi } from '../../../../../helpers/api/categoryApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import { categoryCreateFormSchema } from '../../../../../helpers/formValidations/categoryFormSchema';
import { useFormScrollIntoView } from '../../../../../hooks/useFormScrollIntoView';
import InputFormField from '../../../InputFormField';
import ProductGroupSelect from '../ProductGroupSelect';

export default function ProductCategoryFormModal({
  isModalOpen,
  toggleModal,
  categoryId,
  groupId,
  setFetchCategory
}) {
  const { selectedCategory, setSelectedCategory } = useContext(AppContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const parentFormContext = useFormContext();

  const form = useForm({
    defaultValues: {
      name: '',
      slug: '',
      group: groupId ?? ''
    },
    mode: 'onChange',
    resolver: zodResolver(categoryCreateFormSchema)
  });

  useFormScrollIntoView({ form });

  const resetForm = (name, slug) =>
    form.reset({
      name: name ?? '',
      slug: slug ?? '',
      group: groupId ?? ''
    });

  const handleModalClose = () => {
    toggleModal();
    resetForm();
  };

  const handleSubmitButtonClick = () => {
    if (!form.watch('slug')) {
      form.setValue(
        'slug',
        form.watch('name').replace(/\s+/g, '-').toLowerCase()
      );
    }
    form.handleSubmit(handleCategoryUpsert)();
  };

  const handleCategoryUpsert = async (data) => {
    setIsLoading(true);

    try {
      let response;
      if (selectedCategory) {
        response = await categoryApi.updateCategory(categoryId, data);
      } else {
        response = await categoryApi.addCategory(data);
      }

      parentFormContext.setValue('group', response?.data?.group ?? null, {
        shouldValidate: true,
        shouldDirty: true
      });
      parentFormContext.setValue('categoryId', response?.data?.id ?? null, {
        shouldValidate: true,
        shouldDirty: true
      });

      handleModalClose();
      setFetchCategory(true);
      setSelectedCategory(response.data);
      toast.success(t('Category successfully saved!'));
    } catch (e) {
      showErrorsFromRequest(e, t);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (categoryId && selectedCategory && isModalOpen) {
      resetForm(selectedCategory.name, selectedCategory.slug);
    } else {
      resetForm();
    }
  }, [categoryId, isModalOpen, selectedCategory]);

  return (
    <Modal
      id="categoryModal"
      isOpen={isLoading || isModalOpen}
      toggle={isLoading ? null : toggleModal}
    >
      <ModalHeader>
        <span>
          {selectedCategory ? t('Edit category') : t('Add new category')}
        </span>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <Form>
            <InputFormField
              displayLabel="Name"
              label="name"
              isRequired
              isDisabled={isLoading}
            />
            <InputFormField
              displayLabel="Slug"
              label="slug"
              isRequired
              isDisabled={isLoading}
            />
            <ProductGroupSelect isRequired isDisabled={isLoading} />
          </Form>
        </FormProvider>
        <p className="fs--1 font-italic">
          {t('If you want to make the slug same as name, leave it empty.')}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleModalClose} disabled={isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          type="button"
          color="success"
          onClick={handleSubmitButtonClick}
          disabled={isLoading}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
