import z from 'zod';

export const dynamicEntryFormSchema = z.object({
  label: z
    .string({
      invalid_type_error: 'Label is required',
      required_error: 'Label is required'
    })
    .min(1, { message: 'Label is required' }),
  productId: z.number().nullish(),
  priceExpression: z
    .string({
      invalid_type_error: 'Price Expression is required',
      required_error: 'Price Expression is required'
    })
    .trim()
    .min(1, { message: 'Price Expression is required' })
    .max(1000, {
      message: 'Price Expression must be less than 1000 characters'
    }),
  taxExpression: z
    .string({
      invalid_type_error: 'Tax Expression is required',
      required_error: 'Tax Expression is required'
    })
    .trim()
    .min(1, { message: 'Tax Expression is required' })
    .max(1000, {
      message: 'Tax Expression must be less than 1000 characters'
    }),
  discountExpression: z
    .string({
      invalid_type_error: 'Discount Expression is required',
      required_error: 'Discount Expression is required'
    })
    .trim()
    .min(1, { message: 'Discount Expression is required' })
    .max(1000, {
      message: 'Discount Expression must be less than 1000 characters'
    }),
  quantityExpression: z
    .string({
      invalid_type_error: 'Quantity Expression is required',
      required_error: 'Quantity Expression is required'
    })
    .trim()
    .min(1, { message: 'Quantity Expression is required' })
    .max(1000, {
      message: 'Quantity Expression must be less than 1000 characters'
    })
});
