import axios from '../axios/axios.service';

export const x7Api = {
  getX7Switchboard: async (projectId) =>
    axios.get(`/projects/${projectId}/switchboard/x7`),
  createX7Switchboard: async (projectId, data) =>
    axios.post(`/projects/${projectId}/switchboard/x7`, data),
  updateX7Switchboard: async (projectId, x7Id, data) =>
    axios.post(`/projects/${projectId}/switchboard/x7/${x7Id}`, data),
  getX7SwitchboardById: async (projectId, x7Id) =>
    axios.get(`/projects/${projectId}/switchboard/x7/${x7Id}`),
  deleteX7Switchboard: async (projectId, x7Id) =>
    axios.delete(`/projects/${projectId}/switchboard/x7/${x7Id}`),
  X7SwitchboardSwap: async (projectId, x7Id, data) =>
    axios.post(`/projects/${projectId}/switchboard/x7/${x7Id}/swap`, data)
};
