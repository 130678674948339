import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

import corner4 from '../../../assets/img/illustrations/corner-2.png';
import Background from '../../../components/common/Background';
import ProjectStatusSelect from './ProjectStatusSelect';

const ProjectQuickStatusCard = ({ projectId, status }) => {
  const { t } = useTranslation();
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <CardBody>
        <Row>
          <Col>
            <h5 className="mb-0">{t('Status Action')}</h5>
          </Col>
        </Row>
        <div>
          <p className="fs--1 mt-1">
            {t(
              'Quickly update the project status (a notification will be sent to admins and collaborators)'
            )}
          </p>
          <div className="d-flex">
            <ProjectStatusSelect projectId={projectId} status={status} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProjectQuickStatusCard;
