export const x7 = {
  en: {
    translations: {
      'Add item': 'Add item',
      Type: 'Type',
      Edit: 'Edit',
      'Create X7': 'Create X7',
      'X7 Form': 'X7 Form',
      'X7 saved successfully.': 'X7 saved successfully.',
      'X7 successfully deleted.': 'X7 successfully deleted.',
      Saving: 'Saving'
    }
  },
  ro: {
    translations: {
      'Add item': 'Adaugă un element',
      Type: 'Tip',
      Edit: 'Editare',
      'Create X7': 'Creează X7',
      'X7 Form': 'Formular X7',
      'X7 saved successfully.': 'X7 salvat cu succes.',
      'X7 successfully deleted.': 'X7 șters cu succes.',
      Saving: 'Salvare'
    }
  },
  de: {
    translations: {
      'Add item': 'Element hinzufügen',
      Type: 'Art',
      Edit: 'Bearbeiten',
      'Create X7': 'X7 erstellen',
      'X7 Form': 'X7 Formular',
      'X7 saved successfully.': 'X7 erfolgreich gespeichert.',
      'X7 successfully deleted.': 'X7 erfolgreich gelöscht.',
      Saving: 'Speichern'
    }
  }
};
