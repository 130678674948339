import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { cableColorsApi } from '../../../../../helpers/api/cableColorsApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import CableColorFormModal from './CableColorFormModal';

export default function CableColorActionButtons() {
  const { setFetchData } = useContext(AppContext);
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const inputName = 'cableColorId';
  const cableColorId = watch(inputName);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setCableColor = (cableColor) => {
    setValue(inputName, cableColor);
  };

  const handleCableColorDelete = async () => {
    try {
      await cableColorsApi.deleteCableColor(cableColorId);
      setFetchData(true);
      setIsModalOpen(false);
      setCableColor(null);
    } catch (e) {
      showErrorsFromRequest(e, t);
    }
  };

  return (
    <>
      <div className="d-flex gap-1">
        <Button
          size="icon"
          className={`px-2 ${cableColorId ? 'bg-success border-success' : 'bg-primary border-primary'}`}
          type="button"
          onClick={toggleModal}
        >
          <FontAwesomeIcon icon={cableColorId ? faPen : faPlus} />
        </Button>
        {cableColorId && (
          <Button
            size="icon"
            className="px-2"
            type="button"
            color="danger"
            onClick={handleCableColorDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </div>
      <CableColorFormModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        cableColorId={cableColorId}
        setCableColor={setCableColor}
      />
    </>
  );
}
