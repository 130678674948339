import z from 'zod';

export const relayFormSchema = z.object({
  label: z
    .string({
      invalid_type_error: 'Label is required',
      required_error: 'Label is required'
    })
    .min(1, 'Label is required'),
  index: z.number({
    invalid_type_error: 'Index is required',
    required_error: 'Index is required'
  }),
  relayType: z.coerce.number({
    invalid_type_error: 'Type is required',
    required_error: 'Type is required'
  }),
  protection: z
    .object({
      id: z.coerce.number().optional()
    })
    .optional(),
  circuitsCount: z.coerce
    .number({
      invalid_type_error: 'Minimum value is 1',
      required_error: 'Minimum value is 1'
    })
    .min(1, 'Minimum value is 1')
});
