export const ProtectionType = {
  GENERIC: 0,
  AUTOMATED_FUSE: 1,
  DIFFERENTIAL: 2,
  BREAKER: 3,
};

export const ProtectionTypeText = {
  [ProtectionType.GENERIC]: 'generic',
  [ProtectionType.AUTOMATED_FUSE]: 'automated fuse',
  [ProtectionType.DIFFERENTIAL]: 'differential',
  [ProtectionType.BREAKER]: 'breaker',
};
