import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import ClientsTable from './components/ClientsTable';

const ClientsScreen = () => {
  const { t } = useTranslation();
  const { toggleClientModal } = useContext(AppContext);

  return (
    <>
      <PageHeader
        title="Clients"
        controls={() => (
          <ButtonIcon
            icon="plus"
            color="falcon-primary"
            size="sm"
            onClick={() => toggleClientModal()}
          >
            {t('Add Client')}
          </ButtonIcon>
        )}
      />

      <ClientsTable onClick={(clientId) => toggleClientModal(clientId)} />
    </>
  );
};

export default ClientsScreen;
