import PropTypes from 'prop-types';
import React from 'react';

const FormGroupNote = ({ type, children }) => {
  let typeClasses = type === 'error' ? 'text-danger' : type === 'warning' ? 'text-warning' : 'text-muted';

  return <small className={`d-block px-2 py-1 ${typeClasses}`}>{children}</small>;
};

FormGroupNote.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string
};

FormGroupNote.defaultProps = {
  type: 'info'
};

export default FormGroupNote;
