import loadable from '@loadable/component';
import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CloseButton, Fade } from '../components/common/Toast';
import AppContext from '../context/Context';
import { authRoutes } from '../helpers/routes/authRoutes';
import { dashBoardRoutes } from '../helpers/routes/dashBoardRoutes';
import { errorRoutes } from '../helpers/routes/errorRoutes';
import GuardedRoute from '../hoc/guardedRoute';

import 'react-toastify/dist/ReactToastify.css';

const AuthLayout = loadable(() => import('../layouts/AuthLayout'));

const Layout = () => {
  useEffect(() => {
    AuthLayout.preload();
  }, []);

  const { isAuthenticated, user } = useContext(AppContext);

  return (
    <>
      <Routes>
        <Route element={<GuardedRoute auth={isAuthenticated} />}>
          {dashBoardRoutes({
            isAdmin: user?.isAdmin || false,
            userPermissions: user?.permissions || []
          })}
        </Route>
        {authRoutes()}
        {errorRoutes()}
      </Routes>
      <ToastContainer
        position="top-right"
        transition={Fade}
        closeButton={<CloseButton />}
        className="text-white"
      />
    </>
  );
};

export default Layout;
