import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import { offersApi } from '../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { deleteConfirmationToast } from '../deleteConfirmationToast';
import OfferProfileSelect from '../form/OfferProfileSelect';

export default function OfferProfile({
  isLoading,
  setIsLoading,
  setDynamicEntries,
  selectedProfileId,
  setSelectedProfileId,
  showDropdown = true
}) {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);

  const fetchProfiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await offersApi.getOffersProfiles();
      setProfiles(response.data);
      response.data.forEach((entry) => {
        if (entry.isDefault || selectedProfileId == null) {
          setSelectedProfileId(entry.id);
        }
      });
    } catch (error) {
      if (error?.response?.status !== 404)
        toast.error(t('Unable to load project offer'));
    }
    setIsLoading(false);
  }, [selectedProfileId, setIsLoading, setSelectedProfileId, t]);

  const deleteProfile = async () => {
    try {
      await offersApi.deleteOfferProfile(selectedProfileId);
      fetchProfiles();
      setDynamicEntries([]);
      setSelectedProfileId(null);
      toast.success(t('Profile has been successfully deleted!'));
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const addProfile = async () => {
    const label = prompt(t('Enter the profile name:'));
    if (label === null) return;
    try {
      await offersApi.addOfferProfile({ label });
      fetchProfiles();
      toast.success(t('Profile has been successfully created!'));
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const editProfile = async () => {
    const profile = profiles.find((p) => p.id === selectedProfileId);
    const label = prompt(t('Enter the profile name:'), profile.label);
    if (label === null) return;
    try {
      await offersApi.updateOfferProfile(profile.id, { label });
      fetchProfiles();
      toast.success(t('Profile has been successfully updated!'));
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    const profile = profiles.find(
      (profile) => profile.id === selectedProfileId
    );
    deleteConfirmationToast({
      title: `${t('Please confirm you want to remove')} ${profile.label}?`,
      onClick: () => deleteProfile(),
      buttonText: t('Yes, delete!')
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProfiles();
  }, [fetchProfiles, setIsLoading]);

  return (
    !isLoading && (
      <>
        {!isLoading && (
          <OfferProfileSelect
            label="offerProfile"
            className="d-inline-block w-auto"
            selectedProfileId={selectedProfileId}
            setSelectedProfileId={setSelectedProfileId}
            profiles={profiles}
            placeholder={t('Select a profile...')}
          />
        )}
        {showDropdown && (
          <UncontrolledDropdown className="d-inline-block ml-3">
            <DropdownToggle
              color="link"
              size="sm"
              className="text-600 btn-reveal"
            >
              <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
            </DropdownToggle>
            <DropdownMenu right className="border py-2">
              {selectedProfileId && (
                <>
                  {/*TODO check what profile default does*/}
                  {/*<DropdownItem disabled={defaultProfileId === selectedProfileId} onClick={() => setProfileAsDefault()}>*/}
                  {/*  {defaultProfileId === selectedProfileId ? 'Default profile' : 'Set as default'}*/}
                  {/*</DropdownItem>*/}
                  <DropdownItem
                    onClick={() => editProfile()}
                    className="text-secondary"
                  >
                    {t('Edit')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={showDeleteConfirmationToast}
                    className="text-danger"
                  >
                    {t('Delete')}
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              )}
              <DropdownItem className="text-primary" onClick={addProfile}>
                {t('Add a new profile')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </>
    )
  );
}
