import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../../components/form/FormGroupNote';

export default function HasMeterBoxCheckbox({
  isDisabled,
  isRequired = false
}) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup className="d-flex align-items-center">
      <Label htmlFor="hasCounterTub" className="m-0 mr-2">
        {t('Meter Box')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="hasCounterTub"
        control={control}
        render={({ field }) => (
          <Input
            id="hasCounterTub"
            type="checkbox"
            {...field}
            checked={!!field.value}
            className="m-0 position-static"
            disabled={isDisabled}
          />
        )}
      />
      {errors.hasCounterTub && (
        <FormGroupNote type="warning">
          {errors.hasCounterTub.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
