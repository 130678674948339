import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

import CheckUserPermissions from '../../../hoc/checkUserPermissions';

export default function ShowDeletedCheckbox({ showDeleted, setShowDeleted }) {
  const { t } = useTranslation();
  return (
    <CheckUserPermissions>
      <div className="d-flex align-items-center gap-1">
        <Input
          id="showDeleted"
          type="checkbox"
          value={showDeleted}
          className="m-0 p-0 position-static"
          onChange={() => setShowDeleted(!showDeleted)}
        />
        <Label htmlFor="showDeleted" className="m-0 p-0 user-select-none">
          {t('Show deleted')}
        </Label>
      </div>
    </CheckUserPermissions>
  );
}
