import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { categoryApi } from '../../../../../helpers/api/categoryApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import ProductCategoryFormModal from './ProductCategoryFormModal';

export default function ProductCategoryActionButtons({
  isDisabled = false,
  setFetchCategory
}) {
  const { selectedCategory, setSelectedCategory } = useContext(AppContext);
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const inputName = 'categoryId';
  const categoryId = watch(inputName);
  const groupId = watch('group');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCategoryDelete = async () => {
    try {
      await categoryApi.deleteCategory(categoryId);
      setFetchCategory(true);
      setValue(inputName, null);
      setIsModalOpen(false);
      setSelectedCategory(null);
    } catch (error) {
      showErrorsFromRequest(error?.response?.data ?? '', t);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      setValue(inputName, selectedCategory.id, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [selectedCategory, setValue]);

  return (
    <>
      <div className="d-flex gap-1">
        <Button
          size="icon"
          className={`px-2 ${categoryId ? 'bg-success border-success' : 'bg-primary border-primary'}`}
          type="button"
          onClick={toggleModal}
          disabled={isDisabled}
        >
          <FontAwesomeIcon icon={categoryId ? faPen : faPlus} />
        </Button>
        {categoryId && (
          <Button
            size="icon"
            className="px-2"
            type="button"
            color="danger"
            onClick={handleCategoryDelete}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </div>
      <ProductCategoryFormModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        categoryId={categoryId}
        groupId={groupId}
        setFetchCategory={setFetchCategory}
      />
    </>
  );
}
