export const navbar = {
  en: {
    translations: {
      Dashboard: 'Dashboard',
      Projects: 'Projects',
      Clients: 'Clients',
      Products: 'Products',
      Consumers: 'Consumers',
      Configurations: 'Configurations',
      'Manage Access': 'Manage Access',
      'Manage Users': 'Manage Users'
    }
  },
  ro: {
    translations: {
      Dashboard: 'Tablou de bord',
      Projects: 'Proiecte',
      Clients: 'Clienți',
      Products: 'Produse',
      Consumers: 'Consumatori',
      Configurations: 'Configurații',
      'Manage Access': 'Gestionați accesul',
      'Manage Users': 'Gestionați utilizatorii'
    }
  },
  de: {
    translations: {
      Dashboard: 'Instrumententafel',
      Projects: 'Projekte',
      Clients: 'Kunden',
      Products: 'Produkte',
      Consumers: 'Verbraucher',
      Configurations: 'Konfigurationen',
      'Manage Access': 'Zugriff verwalten',
      'Manage Users': 'Benutzer verwalten'
    }
  }
};
