import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';

import RequiredStarLabel from '../../common/requiredStarLabel';
import FormGroupNote from '../FormGroupNote';

export default function ExpressionInput({
  isDisabled,
  isRequired,
  label,
  displayLabel,
  placeholder = '',
  setClickedExpressionLabel,
  setCaretPosition,
  setClickedInputRef
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const inputRef = useRef(null);

  const handleInputClick = () => {
    setClickedExpressionLabel(label);
  };

  const getCaretPosition = () => {
    setCaretPosition(inputRef.current.selectionStart);
    setClickedInputRef(inputRef.current);
    setClickedExpressionLabel(label);
  };

  useEffect(() => {
    let input = inputRef.current;

    if (input) {
      input.addEventListener('click', getCaretPosition);
      input.addEventListener('keyup', getCaretPosition);
      input.addEventListener('focus', getCaretPosition);
    }

    return () => {
      if (input) {
        input.removeEventListener('click', getCaretPosition);
        input.removeEventListener('keyup', getCaretPosition);
        input.removeEventListener('focus', getCaretPosition);
      }
    };
  }, []);

  return (
    <FormGroup onClick={handleInputClick}>
      <Label>
        {t(displayLabel)} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={label}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            disabled={isDisabled}
            placeholder={placeholder}
            type="textarea"
            innerRef={inputRef}
          />
        )}
      />
      {errors[label] && (
        <FormGroupNote type="warning">{errors[label].message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
