import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import ConsumersTable from './components/ConsumersTable';

const ConsumersScreen = () => {
  const { t } = useTranslation();
  const { toggleConsumerModal } = useContext(AppContext);

  return (
    <>
      <PageHeader
        title="Consumers"
        controls={() => (
          <>
            <ButtonIcon
              icon="plus"
              color="falcon-primary"
              size="sm"
              onClick={() => toggleConsumerModal()}
            >
              {t('Add Consumer')}
            </ButtonIcon>
          </>
        )}
      />
      <ConsumersTable
        onClick={(consumerId) => toggleConsumerModal(consumerId)}
      />
    </>
  );
};

export default ConsumersScreen;
