import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function SupportSelect({ isDisabled, isRequired = false }) {
  const { watch } = useFormContext();

  const switchboardHeight = watch('switchboardHeight');

  const params = {
    verticalModules: switchboardHeight,
    categorySlug: systemProductCategorySlug.Support
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Support"
      inputName="support"
      params={params}
    />
  );
}
