import axios from '../axios/axios.service';

export const productHistoryCommentsApi = {
  getHistoryCommentsByProjectId: async (projectId) => {
    return await axios(`/projects/${projectId}/history-comments`);
  },
  addHistoryCommentsByProjectId: async (projectId, data) => {
    return await axios.post(`/projects/${projectId}/history-comments`, data);
  },
};
