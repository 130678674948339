import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';

import AppContext from '../../../context/Context';
import { getLanguageByLanguageCode } from '../../../helpers/getLanguageByLanguageCode';
import { ProjectContext } from '../ProjectScreen';

export default function DifferentLanguageWarning() {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const { documentationInfo, setDocumentationInfo } =
    useContext(ProjectContext);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setIsTooltipOpen((p) => !p);
  };

  useEffect(() => {
    if (i18n.language !== documentationInfo?.languageCode) {
      setDocumentationInfo({
        ...documentationInfo,
        isGenerated: false
      });
    }
  }, [
    documentationInfo?.isGenerated,
    documentationInfo?.languageCode,
    setDocumentationInfo,
    i18n.language
  ]);

  if (!user?.isAdmin) {
    return null;
  }

  if (i18n.language === documentationInfo?.languageCode) {
    return null;
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        id="different-language-warning"
        className="text-danger animate-pulse"
        aria-hidden={false}
        focusable={true}
      />
      <Tooltip
        isOpen={isTooltipOpen}
        toggle={toggleTooltip}
        target="different-language-warning"
        placement="bottom"
      >
        <span>
          {t(`Documentation is generated in`)(
            t(getLanguageByLanguageCode(documentationInfo?.languageCode))
          )}
        </span>
      </Tooltip>
    </>
  );
}
