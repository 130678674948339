import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from 'reactstrap';

import RemoteTableWidget from '../../components/RemoteTableWidget';
import { UserRoleText } from '../../helpers/enums/userRoleEnum';

export default function UsersList({
  users,
  page,
  perPage,
  setPage,
  setPerPage,
  onClick
}) {
  const { t } = useTranslation();
  const { items, lastPage } = users;

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'firstName',
      text: t('First Name'),
      classes: 'border-200',
      headerClasses: 'border-0'
    },
    {
      dataField: 'lastName',
      text: t('Last Name'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'email',
      text: t('Email'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'role',
      text: t('Role'),
      classes: 'border-0',
      headerClasses: 'border-0',
      formatter: (role) => UserRoleText[role]
    },
    {
      dataField: 'company',
      text: t('Company'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'phoneNumber',
      text: t('Phone Number'),
      classes: 'border-0',
      headerClasses: 'border-0'
    }
  ];

  return (
    <Card>
      <CardHeader></CardHeader>
      <CardBody className="p-0">
        <RemoteTableWidget
          columns={columns}
          keyField="email"
          page={page}
          perPage={perPage}
          perPageOptions={[20, 30, 50]}
          items={items}
          lastPage={lastPage}
          onPageChange={(e) => setPage(e)}
          onPerPageChange={(e) => {
            setPage(1);
            setPerPage(e);
          }}
          rowEvents={{
            onClick: (_, row, rowIndex) => onClick(row.id, rowIndex)
          }}
        />
      </CardBody>
    </Card>
  );
}
