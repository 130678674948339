import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';

import FormGroupNote from './FormGroupNote';
import IndexSelect from './IndexSelect';

export default function IndexFieldSelect({ items, isEdit, isDisabled }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="index">Index</Label>
      <Controller
        name="index"
        control={control}
        render={({ field }) => (
          <IndexSelect
            defaultValue={field.value || items.length + 1}
            maxIndex={isEdit ? items.length : items.length + 1}
            onChange={(value) => field.onChange(value)}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.index && (
        <FormGroupNote type={'warning'}>{errors.index.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
