import {
  ProductDestination,
  ProductDestinationText
} from '../helpers/enums/productDestinationEnum';

export const productSelectDestinationOptions = [
  {
    value: ProductDestination.ALL,
    label: ProductDestinationText[ProductDestination.ALL]
  },
  {
    value: ProductDestination.ZONE,
    label: ProductDestinationText[ProductDestination.ZONE]
  },
  {
    value: ProductDestination.SUBZONE,
    label: ProductDestinationText[ProductDestination.SUBZONE]
  },
  {
    value: ProductDestination.CONSUMER,
    label: ProductDestinationText[ProductDestination.CONSUMER]
  },
  {
    value: ProductDestination.ZONEANDSUBZONE,
    label: ProductDestinationText[ProductDestination.ZONEANDSUBZONE]
  },
  {
    value: ProductDestination.ZONEANDCONSUMER,
    label: ProductDestinationText[ProductDestination.ZONEANDCONSUMER]
  },
  {
    value: ProductDestination.SUBZONEANDCONSUMER,
    label: ProductDestinationText[ProductDestination.SUBZONEANDCONSUMER]
  },
  {
    value: ProductDestination.SWITCHBOARD,
    label: ProductDestinationText[ProductDestination.SWITCHBOARD]
  },
  {
    value: ProductDestination.RELAY,
    label: ProductDestinationText[ProductDestination.RELAY]
  },
  {
    value: ProductDestination.NONE,
    label: ProductDestinationText[ProductDestination.NONE]
  }
];
