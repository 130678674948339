import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { differentialAmperageCategories } from '../../../../constants/differentialInputsCategories';
import { protectionProducts } from '../../../../constants/protectionProducts';
import AppContext from '../../../../context/Context';
import { ProductCategoryEnum } from '../../../../helpers/enums/productCategoryEnum';
import InputFormFieldFloatValue from '../../InputFormFieldFloatValue';
import ConnectionTypeSelect from './ConnectionTypeSelect';
import MountTypeForCategorySlugs from './MountTypeForCategorySlugs';
import ProtectionTypeSelect from './ProtectionTypeSelect';

export default function ProtectionRelatedFields({ isLoading }) {
  const { selectedCategory } = useContext(AppContext);

  const { t } = useTranslation();

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  useEffect(() => {
    if (selectedCategory) {
      let isProtectionCategory = false;

      if (
        protectionProducts.includes(selectedCategory.slug) ||
        differentialAmperageCategories.includes(selectedCategory.slug)
      ) {
        isProtectionCategory = true;
      }

      setIsCollapseOpen(isProtectionCategory);
    } else {
      setIsCollapseOpen(false);
    }
  }, [selectedCategory]);

  return (
    <>
      <h6
        className="mt-5 mb-3 text-primary font-weight-bold d-flex gap-2 cursor-pointer"
        onClick={toggleCollapse}
      >
        {t('Protections Related Fields')}{' '}
        <FontAwesomeIcon icon={isCollapseOpen ? faArrowUp : faArrowDown} />
      </h6>
      <Collapse isOpen={isCollapseOpen}>
        <ProtectionTypeSelect isDisabled={isLoading} />
        <ConnectionTypeSelect isDisabled={isLoading} />
        <InputFormFieldFloatValue
          isDisabled={isLoading}
          label="amperage"
          displayLabel="Amperage"
          inputGroupText="amps"
          isDecimal={true}
        />
        <MountTypeForCategorySlugs
          isLoading={isLoading}
          categoriesSlugs={[
            ProductCategoryEnum.ModularDevices,
            ProductCategoryEnum.DistributionBoard,
            ProductCategoryEnum.Clamp
          ]}
        />
        <InputFormFieldFloatValue
          isDisabled={isLoading}
          label="width"
          displayLabel="Width"
          inputGroupText="slots"
        />
      </Collapse>
    </>
  );
}
