import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function DistributionBoardSelect({
  isDisabled,
  isRequired = false
}) {
  const { watch } = useFormContext();

  const horizontalModules = watch('horizontalModulesPerRow');
  const switchboardHeight = watch('switchboardHeight');
  const mountType = watch('mountType');

  const params = {
    horizontalModules,
    mountType,
    verticalModules: switchboardHeight,
    categorySlug: systemProductCategorySlug.DistributionBoard
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Distribution Board"
      inputName="distributionBoard"
      params={params}
    />
  );
}
