export const form = {
  en: {
    translations: {
      'Search Client': 'Search Client',
      'Create new client': 'Create new client',
      'Select a client': 'Select a client',
      'Project Name': 'Project Name',
      Address: 'Address',
      Notes: 'Notes',
      Financial: 'Financial',
      'Hourly Rate': 'Hourly Rate',
      Discount: 'Discount',
      Save: 'Save',
      'Phone Number': 'Phone Number',
      Company: 'Company',
      'Company Unique Registration Code': 'Company Unique Registration Code',
      'Company Fiscal Code': 'Company Fiscal Code',
      'ID Number': 'ID Number',
      Accept: 'Accept',
      'terms and conditions': 'terms and conditions',
      'Project Form': 'Project Form',
      'Create a new project': 'Create a new project',
      'Editing project': 'Editing project',
      'Client Form': 'Client Form',
      'Create a new client': 'Create a new client',
      'Editing client': 'Editing client',
      Category: 'Category',
      'Add new category': 'Add new category',
      'Edit category': 'Edit category',
      'If you want to make the slug same as name, leave it empty.':
        'If you want to make the slug same as name, leave it empty.',
      'Not assigned': 'Not assigned',
      'Not specified': 'Not specified',
      'Differential Class': 'Differential Class',
      'Differential Trip Curve': 'Differential Trip Curve',
      'Differential Amperage': 'Differential Amperage',
      all: 'all',
      zone: 'zone',
      subzone: 'subzone',
      switchboard: 'switchboard',
      consumer: 'consumer',
      Width: 'Width',
      'Product Form': 'Product Form',
      'Create a new product': 'Create a new product',
      'Edit product': 'Edit product',
      slots: 'slots',
      miliamps: 'miliamps',
      'Protections Related Fields': 'Protections Related Fields',
      'Protection Type': 'Protection Type',
      'Connection Type': 'Connection Type',
      Amperage: 'Amperage',
      amps: 'amps',
      'Switchboard Related Fields': 'Switchboard Related Fields',
      'Horizontal Modules': 'Horizontal Modules',
      'Vertical Modules': 'Vertical Modules',
      'Mount Type': 'Mount Type',
      modules: 'modules',
      'Cable Related Fields': 'Cable Related Fields',
      'Cable Thickness': 'Cable Thickness',
      'Other Fields': 'Other Fields',
      'Has Grounding?': 'Has Grounding?',
      'Not applicable': 'Not applicable',
      Yes: 'Yes',
      No: 'No',
      'Select an existing consumer from the list below or fill in a custom one using the fields.':
        'Select an existing consumer from the list below or fill in a custom one using the fields.',
      Cancel: 'Cancel',
      'Enter the name then Enter Key to add the alias':
        'Enter the name then Enter Key to add the alias',
      'Other information can be stored here':
        'Other information can be stored here',
      'Consumer Form': 'Consumer Form',
      'Edit consumer': 'Edit consumer',
      'Create a new consumer': 'Create a new consumer',
      'Consumer Type': 'Consumer Type',
      'Cable Type': 'Cable Type',
      'Dynamic Entry': 'Dynamic Entry',
      'Edit dynamic entry': 'Edit dynamic entry',
      'Create a new dynamic entry': 'Create a new dynamic entry',
      Product: 'Product',
      'Price Expression': 'Price Expression',
      'Tax Expression': 'Tax Expression',
      'Discount Expression %': 'Discount Expression %',
      'Quantity Expression': 'Quantity Expression',
      Create: 'Create',
      'Available tokens': 'Available tokens',
      'Select a product': 'Select a product',
      'Import products from a .csv file': 'Import products from a .csv file',
      'Drop products .csv file here': 'Drop products .csv file here',
      'Input Current Type': 'Input Current Type',
      'Loading...': 'Loading...',
      'Edit cable color': 'Edit cable color',
      'Add new cable color': 'Add new cable color'
    }
  },
  ro: {
    translations: {
      'Search Client': 'Caută client',
      'Create new client': 'Creează un client nou',
      'Select a client': 'Selectează un client',
      'Project Name': 'Numele proiectului',
      Address: 'Adresa',
      Notes: 'Note',
      Financial: 'Finanțe',
      'Hourly Rate': 'Tarif orar',
      Discount: 'Reducere',
      Save: 'Salvează',
      'Phone Number': 'Număr de telefon',
      Company: 'Companie',
      'Company Unique Registration Code':
        'Cod unic de înregistrare al companiei',
      'Company Fiscal Code': 'Cod fiscal al companiei',
      'ID Number': 'Număr de identificare',
      Accept: 'Acceptați',
      'terms and conditions': 'termenii și condițiile',
      'Project Form': 'Formular proiect',
      'Create a new project': 'Creează un proiect nou',
      'Editing project': 'Editarea proiectului',
      'Client Form': 'Formular client',
      'Create a new client': 'Creează un client nou',
      'Editing client': 'Editarea clientului',
      Category: 'Categorie',
      'Add new category': 'Adaugă o categorie nouă',
      'Edit category': 'Editează categoria',
      'If you want to make the slug same as name, leave it empty.':
        'Daca vrei ca slug-ul sa fie acelasi cu numele, lasa-l gol.',
      'Not assigned': 'Nespecificat',
      'Not specified': 'Nespecificat',
      'Differential Class': 'Clasa diferențială',
      'Differential Trip Curve': 'Curba de declanșare diferențială',
      'Differential Amperage': 'Amperaj diferențial',
      all: 'toate',
      zone: 'zonă',
      subzone: 'subzonă',
      switchboard: 'tablou',
      consumer: 'consumator',
      Width: 'Lățime',
      'Product Form': 'Formular Produs',
      'Create a new product': 'Creează un produs nou',
      'Edit product': 'Editează produsul',
      slots: 'sloturi',
      miliamps: 'miliamperi',
      'Protections Related Fields': 'Câmpuri Legate de Protecții',
      'Protection Type': 'Tipul de protecție',
      'Connection Type': 'Tipul de conexiune',
      Amperage: 'Amperaj',
      amps: 'amperi',
      'Switchboard Related Fields': 'Câmpuri Legate de Tablou',
      'Horizontal Modules': 'Module Orizontale',
      'Vertical Modules': 'Module Verticale',
      'Mount Type': 'Tip de montare',
      modules: 'module',
      'Cable Related Fields': 'Câmpuri Legate de Cablu',
      'Cable Thickness': 'Grosimea cablului',
      'Other Fields': 'Alte câmpuri',
      'Has Grounding?': 'Are legare la pământ?',
      'Not applicable': 'Nu este aplicabil',
      Yes: 'Da',
      No: 'Nu',
      'Select an existing consumer from the list below or fill in a custom one using the fields.':
        'Selectați un consumator existent din lista de mai jos sau completați unul personalizat folosind câmpurile.',
      Cancel: 'Anulează',
      'Enter the name then Enter Key to add the alias':
        'Introduceți pseudonimul + Enter',
      'Other information can be stored here':
        'Alte informații pot fi stocate aici',
      'Consumer Form': 'Formular Consumator',
      'Edit consumer': 'Editează consumatorul',
      'Create a new consumer': 'Crează un consumator nou',
      'Consumer Type': 'Tip Consumator',
      'Cable Type': 'Tip Cablu',
      'Dynamic Entry': 'Intrare dinamică',
      'Edit dynamic entry': 'Editează intrarea dinamică',
      'Create a new dynamic entry': 'Creează o intrare dinamică nouă',
      Product: 'Produs',
      'Price Expression': 'Expresie de preț',
      'Tax Expression': 'Expresie de taxă',
      'Discount Expression %': 'Expresie de reducere %',
      'Quantity Expression': 'Expresie de cantitate',
      Create: 'Creează',
      'Available tokens': 'Simboluri disponibile',
      'Select a product': 'Selectează un produs',
      'Import products from a .csv file':
        'Importă produse dintr-un fișier .csv',
      'Drop products .csv file here': 'Trage fișierul .csv aici',
      'Input Current Type': 'Tipul curentului de intrare',
      'Loading...': 'Se încarcă...',
      'Add new cable color': 'Adaugă o culoare de cablu nouă',
      'Edit cable color': 'Editează culoarea cablului'
    }
  },
  de: {
    translations: {
      'Search Client': 'Kunde suchen',
      'Create new client': 'Neuen Kunden erstellen',
      'Select a client': 'Wählen Sie einen Kunden aus',
      'Project Name': 'Projektname',
      Address: 'Adresse',
      Notes: 'Anmerkungen',
      Financial: 'Finanziell',
      'Hourly Rate': 'Stundensatz',
      Discount: 'Rabatt',
      Save: 'Speichern',
      'Phone Number': 'Telefonnummer',
      Company: 'Unternehmen',
      'Company Unique Registration Code':
        'Einzigartiger Registrierungscode des Unternehmens',
      'Company Fiscal Code': 'Unternehmenssteuernummer',
      'ID Number': 'Identifikationsnummer',
      Accept: 'Akzeptieren',
      'terms and conditions': 'Geschäftsbedingungen',
      'Project Form': 'Projektformular',
      'Create a new project': 'Neues Projekt erstellen',
      'Editing project': 'Projekt bearbeiten',
      'Client Form': 'Kundenformular',
      'Create a new client': 'Neuen Kunden erstellen',
      'Editing client': 'Kunden bearbeiten',
      Category: 'Kategorie',
      'Add new category': 'Neue Kategorie hinzufügen',
      'Edit category': 'Kategorie bearbeiten',
      'If you want to make the slug same as name, leave it empty.':
        'Wenn Sie die Slug identisch mit dem Namen, lassen Sie es leer.',
      'Not assigned': 'Nicht zugewiesen',
      'Not specified': 'Nicht spezifiziert',
      'Differential Class': 'Differenzialklasse',
      'Differential Trip Curve': 'Differenzialschaltkurve',
      'Differential Amperage': 'Differenzialstromstärke',
      all: 'alle',
      zone: 'zone',
      subzone: 'subzone',
      switchboard: 'schalttafel',
      consumer: 'verbraucher',
      Width: 'Breite',
      'Product Form': 'Produktformular',
      'Create a new product': 'Neues Produkt erstellen',
      'Edit product': 'Produkt bearbeiten',
      slots: 'steckplätze',
      miliamps: 'miliamps',
      'Protections Related Fields': 'Schutzrelevante Felder',
      'Protection Type': 'Schutzart',
      'Connection Type': 'Verbindungstyp',
      Amperage: 'Stromstärke',
      amps: 'amps',
      'Switchboard Related Fields': 'Schalttafelrelevante Felder',
      'Horizontal Modules': 'Horizontale Module',
      'Vertical Modules': 'Vertikale Module',
      'Mount Type': 'Montageart',
      modules: 'module',
      'Cable Related Fields': 'Kabelrelevante Felder',
      'Cable Thickness': 'Kabelstärke',
      'Other Fields': 'Andere Felder',
      'Has Grounding?': 'Hat Erdung?',
      'Not applicable': 'Nicht anwendbar',
      Yes: 'Ja',
      No: 'Nein',
      'Select an existing consumer from the list below or fill in a custom one using the fields.':
        'Wählen Sie einen vorhandenen Verbraucher aus der Liste unten aus oder füllen Sie ein benutzerdefiniertes Feld aus.',
      Cancel: 'Stornieren',
      'Enter the name then Enter Key to add the alias':
        'Geben Sie den Namen ein und drücken Sie die Eingabetaste, um das Alias hinzuzufügen',
      'Other information can be stored here':
        'Andere Informationen können hier gespeichert werden',
      'Consumer Form': 'Verbraucherformular',
      'Edit consumer': 'Verbraucher bearbeiten',
      'Create a new consumer': 'Neuen Verbraucher erstellen',
      'Consumer Type': 'Verbrauchertyp',
      'Cable Type': 'Kabelart',
      'Dynamic Entry': 'Dynamischer Eintrag',
      'Edit dynamic entry': 'Dynamischen Eintrag bearbeiten',
      'Create a new dynamic entry': 'Neuen dynamischen Eintrag erstellen',
      Product: 'Produkt',
      'Price Expression': 'Preisausdruck',
      'Tax Expression': 'Steuererklärung',
      'Discount Expression %': 'Rabattaussage %',
      'Quantity Expression': 'Mengenerklärung',
      Create: 'Erstellen',
      'Available tokens': 'Verfügbare Symbole',
      'Select a product': 'Wählen Sieein Produkt aus',
      'Import products from a .csv file':
        'Produkte aus einer .csv-Datei importieren',
      'Drop products .csv file here': 'Legen Sie die .csv-Datei hier ab',
      'Input Current Type': 'Eingangstromtyp',
      'Loading...': 'Laden...',
      'Add new cable color': 'Neue Kabelfarbe hinzufügen',
      'Edit cable color': 'Kabelfarbe bearbeiten'
    }
  }
};
