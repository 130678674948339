import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

import AppContext from '../../context/Context';
import { removeUserFromCookies } from '../../helpers/utils/removeUserFromCookies';
import Avatar from '../common/Avatar';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const { user, setIsAuthenticated, setUser, toggleProfileModal } =
    useContext(AppContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar name={`${user && user.firstName} ${user && user.lastName}`} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} onClick={toggleProfileModal}>
            {t('Profile')}
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to="/auth/login"
            onClick={() => {
              removeUserFromCookies();
              setIsAuthenticated(false);
              setUser(null);
            }}
          >
            {t('Logout')}
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
