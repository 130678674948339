import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Label,
  Media,
  Modal,
  ModalHeader
} from 'reactstrap';

import card from '../../assets/img/generic/card.png';
import transparent from '../../assets/img/generic/default.png';
import darkModeImg from '../../assets/img/generic/falcon-mode-dark.jpg';
import defaultModeImg from '../../assets/img/generic/falcon-mode-default.jpg';
import invertedImg from '../../assets/img/generic/inverted.png';
import vibrant from '../../assets/img/generic/vibrant.png';
import arrowsH from '../../assets/img/icons/arrows-h.svg';
import leftArrowFromLeft from '../../assets/img/icons/left-arrow-from-left.svg';
import paragraph from '../../assets/img/icons/paragraph.svg';
import AppContext from '../../context/Context';
import { createCookie, getCookieValue } from '../../helpers/utils/utils';
import Flex from '../common/Flex';
import ScrollBarCustom from '../common/ScrollBarCustom';
import VerticalNavRadioBtn from './VerticalNavStyleRadioBtn';

const SidePanelModal = ({
  autoShow,
  showOnce,
  autoShowDelay,
  cookieExpireTime
}) => {
  const { t } = useTranslation();
  const {
    isCombo,
    isOpenSidePanel,
    toggleModal,
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    isTopNav,
    setIsTopNav,
    setIsOpenSidePanel,
    setIsCombo,
    isVertical,
    setIsVertical
  } = useContext(AppContext);
  // const isKanban = getPageName('kanban');

  useEffect(() => {
    let modalStatus = getCookieValue('modalClose');

    if (modalStatus === null && autoShow) {
      setTimeout(() => {
        setIsOpenSidePanel((prev) => !prev);
        showOnce && createCookie('modalClose', false, cookieExpireTime);
      }, autoShowDelay);
    }
  }, [autoShow, showOnce, setIsOpenSidePanel, autoShowDelay, cookieExpireTime]);

  return (
    <Modal
      isOpen={isOpenSidePanel}
      toggle={toggleModal}
      modalClassName="overflow-hidden modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader
        tag="div"
        toggle={toggleModal}
        className="modal-header-settings"
      >
        <div className="py-1 flex-grow-1">
          <h5 className="text-white">
            <FontAwesomeIcon icon="palette" className="mr-2 fs-0" />
            {t('Settings')}
          </h5>
          <p className="mb-0 fs--1 text-white opacity-75">
            {t('Set your own customized style')}
          </p>
        </div>
      </ModalHeader>
      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          // eslint-disable-next-line
          renderer: ({ elementRef, ...restParams }) => (
            <span
              {...restParams}
              ref={elementRef}
              className={classNames('p-card position-absolute', {
                'border-left': isDark
              })}
            />
          )
        }}
      >
        <div className="form-modal-padding">
          <h5 className="fs-0">{t('Color Scheme')}</h5>
          <p className="fs--1">
            {t('Choose the perfect color mode for your app.')}
          </p>
          <ButtonGroup className="btn-group-toggle btn-block">
            <Button
              color="theme-default"
              className={classNames('custom-radio-success', {
                active: !isDark
              })}
            >
              <Label
                for="theme-mode-default"
                className="cursor-pointer hover-overlay"
              >
                <img className="w-100" src={defaultModeImg} alt="" />
              </Label>
              <Input
                type="radio"
                id="theme-mode-default"
                label="Light"
                checked={!isDark}
                onChange={({ target }) => setIsDark(!target.checked)}
              />
            </Button>
            <Button
              color="theme-dark"
              className={classNames('custom-radio-success', { active: isDark })}
            >
              <Label
                for="theme-mode-dark"
                className="cursor-pointer hover-overlay"
              >
                <img className="w-100" src={darkModeImg} alt="" />
              </Label>
              <Input
                type="radio"
                id="theme-mode-dark"
                label="Dark"
                checked={isDark}
                onChange={({ target }) => setIsDark(target.checked)}
              />
            </Button>
          </ButtonGroup>
          <hr />
          <Flex justify="between">
            <Media className="flex-grow-1">
              <img src={leftArrowFromLeft} alt="" width={20} className="mr-2" />
              <Media body>
                <h5 className="fs-0">{t('RTL Mode')}</h5>
                <p className="fs--1 mb-0">
                  {t('Switch your language direction')}
                </p>
              </Media>
            </Media>
            <Input
              type="checkbox"
              id="rtl-switch"
              checked={isRTL}
              onChange={({ target }) => setIsRTL(target.checked)}
              className="position-static m-0"
            />
          </Flex>
          <hr />

          <Flex justify="between">
            <Media className="flex-grow-1">
              <img src={arrowsH} alt="" width={20} className="mr-2" />
              <Media body>
                <h5 className="fs-0">{t('Fluid Layout')}</h5>
                <p className="fs--1 mb-0">
                  {t('Toggle container layout system')}
                </p>
              </Media>
            </Media>
            <Input
              type="checkbox"
              id="fluid-switch"
              checked={isFluid}
              onChange={({ target }) => setIsFluid(target.checked)}
              className="position-static m-0"
            />
          </Flex>
          <hr />

          <Media>
            <img src={paragraph} alt="" width={20} className="mr-2" />
            <Media body>
              <Flex align="center" tag="h5" className="fs-0">
                {t('Navigation Position')}
              </Flex>
              <p className="fs--1 mb-2">
                {t(
                  'Select a suitable navigation system for your web application'
                )}
              </p>
              <FormGroup className="d-flex align-items-center">
                <Label htmlFor="verticalNav-radio" className="m-0">
                  {t('Vertical')}
                </Label>
                <Input
                  type="radio"
                  id="verticalNav-radio"
                  name="NavBarPositionRadioButton"
                  checked={!isCombo && isVertical}
                  onChange={({ target }) => {
                    setIsVertical(target.checked);
                    setIsTopNav(!target.checked);
                    setIsCombo(!target.checked);
                  }}
                  className="position-static m-0 ml-1"
                />
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label htmlFor="topNav-radio" className="m-0">
                  {t('Top')}
                </Label>
                <Input
                  type="radio"
                  id="topNav-radio"
                  name="NavBarPositionRadioButton"
                  checked={!isCombo && isTopNav}
                  onChange={({ target }) => {
                    setIsTopNav(target.checked);
                    setIsVertical(!target.checked);
                    setIsCombo(!target.checked);
                  }}
                  className="position-static m-0 ml-1"
                />
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label htmlFor="combo-radio" className="m-0">
                  {t('Combo')}
                </Label>
                <Input
                  type="radio"
                  id="combo-radio"
                  name="NavBarPositionRadioButton"
                  checked={isCombo}
                  onChange={({ target }) => {
                    setIsCombo(target.checked);
                    setIsTopNav(target.checked);
                    setIsVertical(target.checked);
                  }}
                  className="position-static m-0 ml-1"
                />
              </FormGroup>
            </Media>
          </Media>
          <hr />
          <h5 className="fs-0 d-flex align-items-center">
            {t('Vertical Navbar Style')}
          </h5>
          <p className="fs--1">
            {t('Switch between styles for your vertical navbar')}
          </p>
          <div className="btn-group-toggle btn-block btn-group-navbar-style">
            <ButtonGroup className="btn-block">
              <VerticalNavRadioBtn img={transparent} btnName={'transparent'} />
              <VerticalNavRadioBtn img={invertedImg} btnName={'inverted'} />
            </ButtonGroup>
            <ButtonGroup className="btn-block mt-3">
              <VerticalNavRadioBtn img={card} btnName={'card'} />
              <VerticalNavRadioBtn img={vibrant} btnName={'vibrant'} />
            </ButtonGroup>
          </div>
        </div>
      </ScrollBarCustom>
    </Modal>
  );
};

SidePanelModal.propTypes = {
  autoShow: PropTypes.bool,
  showOnce: PropTypes.bool,
  autoShowDelay: PropTypes.number,
  cookieExpireTime: PropTypes.number
};

SidePanelModal.defaultProps = {
  autoShow: true,
  showOnce: true,
  autoShowDelay: 3000,
  cookieExpireTime: 7200000
};

export default SidePanelModal;
