import z from 'zod';

export const userManagementFormSchema = z.object({
  clientId: z.number().nullish(),
  firstName: z
    .string({
      invalid_type_error: 'First Name is required',
      required_error: 'First Name is required'
    })
    .min(1, { message: 'First Name is required' }),
  lastName: z
    .string({
      invalid_type_error: 'Last Name is required',
      required_error: 'Last Name is required'
    })
    .min(1, { message: 'Last Name is required' }),
  userEmail: z
    .string({
      invalid_type_error: 'Email is required',
      required_error: 'Email is required'
    })
    .email({ message: 'Invalid email' }),
  userRole: z.number({
    invalid_type_error: 'User Role is required',
    required_error: 'User Role is required'
  }),
  company: z.string().nullish(),
  phoneNumber: z.string().nullish(),
  clientIds: z.array(z.number()).nullish(),
  projectIds: z.array(z.number()).nullish()
});
