import { Badge } from 'reactstrap';

export default function PhaseDetailsBadge({
  hasHighTension,
  consumersTotalPower,
  phaseIndex,
  percentage
}) {
  return (
    <Badge
      color="soft-dark"
      style={{
        backgroundColor: hasHighTension && '#FF4D00',
        color: hasHighTension && 'white'
      }}
    >
      {phaseIndex}: {consumersTotalPower} W ({percentage} %)
    </Badge>
  );
}
