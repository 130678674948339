import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../../components/form/FormGroupNote';
import ProductsSelect from '../../../../components/form/ProductsSelect';

export default function ProtectionSelect({
  isRequired = false,
  isDisabled,
  destination,
  manufacturerId,
  exactDestination = false
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="protection.id">
        {t('Protection')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="protection.id"
        control={control}
        render={({ field }) => (
          <ProductsSelect
            fieldValue={field.value}
            onChange={field.onChange}
            placeholder="No Protection"
            isDisabled={isDisabled}
            destination={destination}
            manufacturerId={manufacturerId}
            exactDestination={exactDestination}
          />
        )}
      />
      {errors.protection && (
        <FormGroupNote type="warning">
          {errors.protection.id.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
