import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonIcon from '../../../components/common/ButtonIcon';
import DragAndDrop from '../../../components/DragAndDrop';
import AppContext from '../../../context/Context';
import { relayApi } from '../../../helpers/api/relayApi';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../../hoc/checkUserPermissions';
import RelayCard from './RelayCard';

export default function RelaysDragAndDrop({ relays, fetchRelays, isLoading }) {
  const { toggleRelayModal } = useContext(AppContext);
  const { t } = useTranslation();

  const handleItemsSwap = async (projectId, draggedItemId, indexes) => {
    await relayApi.swapRelay(projectId, draggedItemId, indexes);
  };

  return (
    <DragAndDrop
      items={relays}
      successMessage="Item successfully moved."
      handleItemsSwap={handleItemsSwap}
      fetchItemsCallback={fetchRelays}
      isDragDisabled={isLoading}
      addItemChildren={
        <CheckUserPermissions permissions={[PermissionType.RELAY.MODIFY]}>
          <ButtonIcon
            className="w-100 mt-1 mb-3"
            color="light"
            size="sm"
            icon="plus"
            onClick={() => toggleRelayModal()}
          >
            {t('Add item')}
          </ButtonIcon>
        </CheckUserPermissions>
      }
      permissions={[PermissionType.RELAY.MODIFY]}
    >
      <RelayCard onClick={(it) => toggleRelayModal(it)} />
    </DragAndDrop>
  );
}
