import { Outlet } from 'react-router-dom';

const GuardedRouteByRole = ({ isAdmin, userPermissions, permissions }) => {
  const hasAnyPermission = () => {
    return (
      permissions &&
      permissions.some((permission) => userPermissions.includes(permission))
    );
  };

  return isAdmin || hasAnyPermission() ? <Outlet /> : null;
};

export default GuardedRouteByRole;
