import axios from '../axios/axios.service';

export const clientApi = {
  getClientById: async (id) => {
    return await axios.get(`/clients/${id}`);
  },
  updateClient: async (id, data) => {
    return await axios.post(`/clients/${id}`, data);
  },
  deleteClient: async (id) => {
    return await axios.delete(`/clients/${id}`);
  },
  getClients: async (params) => {
    return await axios.get(`/clients`, { params });
  },
  addClient: async (data) => {
    return await axios.post(`/clients`, data);
  },
};
