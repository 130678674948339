import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import { manufacturerApi } from '../../../../helpers/api/manufacturerApi';
import RequiredStarLabel from '../../../common/requiredStarLabel';
import EntitySelect from '../../../entitySelect/EntitySelect';
import FormGroupNote from '../../FormGroupNote';

export default function ProductManufacturerSelect({
  isRequired = false,
  isDisabled
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="manufacturerId">
        {t('Protection Manufacturer')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="manufacturerId"
        control={control}
        render={({ field }) => (
          <EntitySelect
            name="manufacturerId"
            displayName={t('Protection Manufacturer')}
            fieldValue={field.value}
            onChange={field.onChange}
            placeholder="Not assigned"
            fetchDataFunction={manufacturerApi.getManufacturers}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.manufacturerId && (
        <FormGroupNote type={'warning'}>
          {errors.manufacturerId.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
