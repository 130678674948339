export const checkUserPermissions = ({ isAdmin, userPermissions, permissions }) => {
  if (isAdmin) {
    return true;
  }

  const hasAnyPermission = () => {
    if (userPermissions && permissions) {
      return permissions.some((permission) => userPermissions.includes(permission));
    }
  };

  if (hasAnyPermission()) {
    return true;
  }

  return false;
};
