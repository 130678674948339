export const siteSettings = {
  en: {
    translations: {
      'Set your own customized style': 'Set your own customized style',
      'Color Scheme': 'Color Scheme',
      'Choose the perfect color mode for your app.':
        'Choose the perfect color mode for your app.',
      'RTL Mode': 'RTL Mode',
      'Switch your language direction': 'Switch your language direction',
      'Fluid Layout': 'Fluid Layout',
      'Toggle container layout system': 'Toggle container layout system',
      'Navigation Position': 'Navigation Position',
      Vertical: 'Vertical',
      Top: 'Top',
      Combo: 'Combo',
      'Vertical Navbar Style': 'Vertical Navbar Style',
      'Switch between styles for your vertical navbar':
        'Switch between styles for your vertical navbar'
    }
  },
  ro: {
    translations: {
      'Set your own customized style': 'Setări personalizate',
      'Color Scheme': 'Schema de culori',
      'Choose the perfect color mode for your app.':
        'Alegeți modul perfect de culoare pentru aplicația dvs.',
      'RTL Mode': 'Mod Dreapta-La-Stânga',
      'Switch your language direction': 'Comutați direcția limbii',
      'Fluid Layout': 'Aspect fluid',
      'Toggle container layout system':
        'Comutați sistemul de aspect al containerului',
      'Navigation Position': 'Poziția navigării',
      Vertical: 'Vertical',
      Top: 'Sus',
      Combo: 'Combo',
      'Vertical Navbar Style': 'Stil bara de navigare verticală',
      'Switch between styles for your vertical navbar':
        'Comutați între stilurile pentru bara de navigare verticală'
    }
  },
  de: {
    translations: {
      'Set your own customized style':
        'Legen Sie Ihren eigenen benutzerdefinierten Stil fest',
      'Color Scheme': 'Farbschema',
      'Choose the perfect color mode for your app.':
        'Wählen Sie den perfekten Farbmodus für Ihre App.',
      'RTL Mode': 'RTL-Modus',
      'Switch your language direction': 'Wechseln Sie Ihre Sprachrichtung',
      'Fluid Layout': 'Flüssiges Layout',
      'Toggle container layout system': 'Container-Layout-System umschalten',
      'Navigation Position': 'Navigationsposition',
      Vertical: 'Vertikal',
      Top: 'Oben',
      Combo: 'Combo',
      'Vertical Navbar Style': 'Vertikaler Navigationsleistenstil',
      'Switch between styles for your vertical navbar':
        'Wechseln Sie zwischen Stilen für Ihre vertikale Navigationsleiste'
    }
  }
};
