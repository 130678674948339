import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Media } from 'reactstrap';

import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import FileDropzone from '../../products/components/FileDropzone';
import SharedFile from './SharedFile';

const SharedFiles = ({ projectId }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [updateAttachments, setUpdateAttachments] = useState(false);

  const fetchAttachments = useCallback(async () => {
    try {
      const response = await projectApi.getProjectAttachments(projectId);
      setFiles(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [projectId]);

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      await projectApi.addProjectAttachment(projectId, formData);
      await fetchAttachments();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments, updateAttachments]);

  return (
    <Card className="h-lg-100">
      <FalconCardHeader title="Attachments" light={false} />
      <CardBody>
        {files.length !== 0 &&
          files.map((file, index) => (
            <SharedFile
              key={file.id}
              file={file}
              isLast={index === files.length - 1}
              setUpdateAttachments={setUpdateAttachments}
            />
          ))}

        <div className="mt-4" />
        <FileDropzone
          onChange={(file) => {
            handleFileUpload(file);
          }}
          multiple={false}
          accept="*/*"
          placeholder={
            <>
              <Media className="fs-0 mx-auto d-inline-flex align-items-center">
                <Media>
                  <p className="fs-0 mb-0 text-700">{t('Attach a file')}</p>
                </Media>
              </Media>
              <p className="mb-0 w-75 mx-auto text-500">
                {t('Drop files here to start uploading')}
              </p>
            </>
          }
        />
      </CardBody>
    </Card>
  );
};

export default SharedFiles;
