import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import AppContext from '../../../context/Context';
import { relayApi } from '../../../helpers/api/relayApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import RelaysDragAndDrop from './RelaysDragAndDrop';

export default function RelaysList({ relays, setRelays }) {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { setFetchData, fetchData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRelays = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await relayApi.getRelays(projectId);
      setRelays(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setRelays]);

  useEffect(() => {
    fetchRelays();
    if (fetchData) {
      setFetchData(false);
    }
  }, [fetchRelays, fetchData, setFetchData]);

  return (
    <Row>
      <Col className="d-flex justify-content-center">
        <RelaysDragAndDrop
          relays={relays}
          fetchRelays={fetchRelays}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
