import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import { deleteConfirmationToast } from '../../../components/deleteConfirmationToast';
import IndexFieldSelect from '../../../components/form/IndexFieldSelect';
import InputFormField from '../../../components/form/InputFormField';
import AppContext from '../../../context/Context';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { x7Api } from '../../../helpers/api/x7Api';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import { x7FormSchema } from '../../../helpers/formValidations/x7FormSchema';
import { checkUserPermissions } from '../../../helpers/utils/checkUserPermissions';
import CheckUserPermission from '../../../hoc/checkUserPermissions';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import X7TypeSelect from './X7TypeSelect';

export default function X7ModalForm({
  defaultValues,
  isLoading,
  setIsLoading,
  x7Switchboard
}) {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { toggleX7Modal, X7ForEdit, setFetchData, user } =
    useContext(AppContext);

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(x7FormSchema)
  });

  useFormScrollIntoView({ form });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (X7ForEdit) {
        await x7Api.updateX7Switchboard(projectId, X7ForEdit.id, {
          label: data.label,
          idx: data.index,
          type: data.type,
          switchboardId: projectId
        });
      } else {
        await x7Api.createX7Switchboard(projectId, {
          label: data.label,
          idx: data.index,
          type: data.type,
          switchboardId: projectId
        });
      }
      toast.success(t('X7 saved successfully.'));
      toggleX7Modal();
      setFetchData(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  const onDelete = async () => {
    try {
      await x7Api.deleteX7Switchboard(projectId, X7ForEdit.id);
      toast.success(t('X7 successfully deleted.'));
      setFetchData(true);
      toggleX7Modal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to delete')} "${X7ForEdit.label}"`,
      onClick: () => onDelete(),
      buttonText: t('Yes, delete!')
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <IndexFieldSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.X7SWITCHBOARD.MODIFY])
          }
          isEdit={X7ForEdit}
          items={x7Switchboard}
        />
        <InputFormField
          isDisabled={
            isLoading || !checkPermission([PermissionType.X7SWITCHBOARD.MODIFY])
          }
          isRequired
          label="label"
          displayLabel="Label"
        />
        <X7TypeSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.X7SWITCHBOARD.MODIFY])
          }
          isRequired
        />
        <CheckUserPermission
          permissions={[PermissionType.X7SWITCHBOARD.MODIFY]}
        >
          <div className="d-flex justify-content-between">
            {X7ForEdit && (
              <Button
                type="button"
                disabled={isLoading}
                color="falcon-danger"
                onClick={showDeleteConfirmationToast}
              >
                {t('Delete')}
              </Button>
            )}
            <Button type="submit" disabled={isLoading} color="falcon-primary">
              {isLoading ? t('Saving') : t('Save')}
            </Button>
          </div>
        </CheckUserPermission>
      </Form>
    </FormProvider>
  );
}
