import axios from '../../axios/axios.service';

export const switchboardSubzoneApi = {
  addSubzone: async (projectId, data) => {
    return await axios.post(`/projects/${projectId}/switchboard/subzone`, data);
  },
  updateSubzone: async (projectId, subZoneId, data) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/subzone/${subZoneId}`,
      data
    );
  },
  deleteSubzone: async (projectId, subZoneId) => {
    return await axios.delete(
      `/projects/${projectId}/switchboard/subzone/${subZoneId}`
    );
  },
  restoreSubzone: async (projectId, subZoneId) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/subzone/${subZoneId}/restore`
    );
  }
};
