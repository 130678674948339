import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import AppContext from '../../../../context/Context';
import { productHistoryCommentsApi } from '../../../../helpers/api/historyCommentsApi';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermission from '../../../../hoc/checkUserPermissions';
import { useFormScrollIntoView } from '../../../../hooks/useFormScrollIntoView';

export default function HistoryCommentsForm({ projectId, fetchHistory }) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const form = useForm({
    defaultValues: {
      isPublic: false,
      sendEmailNotification: false,
      comments: ''
    },
    mode: 'onChange'
  });

  useFormScrollIntoView({ form });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = form;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSave = async (data) => {
    setIsSubmitting(true);

    const payload = {
      isPublic: checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermission: user?.permissions,
        permissions: [
          PermissionType.PROJECT_DETAILS_HISTORY
            .CAN_SEE_CUSTOMER_VISIBLE_CHECKBOX
        ]
      })
        ? data.isPublic
        : true,
      sendEmailNotification: data.sendEmailNotification,
      comments: data.comments
    };

    try {
      await productHistoryCommentsApi.addHistoryCommentsByProjectId(
        projectId,
        payload
      );
      toast.success(t('Comment posted successfully'));
      reset();
      await fetchHistory();
    } catch (error) {
      toast.error(t('Unable to post comment'));
    }

    setIsSubmitting(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <div className="d-flex align-items-center flex-wrap">
        <CheckUserPermission
          permissions={[
            PermissionType.PROJECT_DETAILS_HISTORY
              .CAN_SEE_CUSTOMER_VISIBLE_CHECKBOX
          ]}
        >
          <FormGroup className="d-flex align-items-center mr-3 mb-3">
            <Label htmlFor="isPublic" className="mb-0">
              {t('Customer visible')}
            </Label>
            <Controller
              name="isPublic"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  type="checkbox"
                  id="isPublic"
                  {...field}
                  checked={!!field.value}
                  className="position-static ml-1 mt-0 mb-0"
                />
              )}
            />
          </FormGroup>
        </CheckUserPermission>
        <FormGroup className="d-flex align-items-center mr-3 mb-3">
          <Label htmlFor="sendEmailNotification" className="mb-0">
            {t('Send email notification')}
          </Label>
          <Controller
            name="sendEmailNotification"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                type="checkbox"
                id="sendEmailNotification"
                {...field}
                checked={!!field.value}
                className="position-static ml-1 mt-0 mb-0"
              />
            )}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <Label for="comment">{t('Comment')}</Label>
        <Controller
          name="comments"
          control={control}
          rules={{ required: true, minLength: 20 }}
          render={({ field: { ref, ...field } }) => (
            <Input
              type="textarea"
              name="comments"
              id="comments"
              rows={5}
              {...field}
            />
          )}
        />
        {errors.comments && (
          <div className="invalid-feedback d-block">
            {t('The comment should be at least 20 chars long')}
          </div>
        )}
      </FormGroup>
      <Button color="primary" disabled={isSubmitting} type="submit">
        {t('Post')}
      </Button>
    </Form>
  );
}
