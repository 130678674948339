import React from 'react';

import PageHeader from '../../components/common/PageHeader';
import OfferProfileTab from './components/offer-profiles/OfferProfilesTab';

const ConfigurationsScreen = () => {
  return (
    <>
      <PageHeader title="Configurations" controls={() => {}} />

      <OfferProfileTab />
    </>
  );
};

export default ConfigurationsScreen;
