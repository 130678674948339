import axios from '../../helpers/axios/axios.service';

export const productsExportApi = {
  exportProducts: async () => {
    return await axios.get(`/products/porting/export`);
  },
  importProducts: async (formData) => {
    return await axios.post(`/products/porting/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  },
};
