import z from 'zod';

export const consumerFormSchema = z
  .object({
    label: z
      .string({ required_error: 'Label is required' })
      .trim()
      .min(1, { message: 'Label is required' }),
    alias: z.string().optional(),
    aliases: z.array(z.string()).min(1, 'Alias is required'),
    type: z.coerce.number().optional(),
    power: z.coerce.number().min(1, 'Power is required'),
    connectionType: z.coerce.number().optional(),
    protection: z.object({
      id: z.number({
        invalid_type_error: 'Protection is required',
        required_error: 'Protection is required'
      }),
      label: z.string().nullish()
    }),
    cableType: z.coerce.number().optional()
  })
  .refine((data) => {
    if (data.aliases.length > 0 && data.alias === '') {
      return true;
    }
    return false;
  });
