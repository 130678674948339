import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ButtonIcon from '../../../components/common/ButtonIcon';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../../hoc/checkUserPermissions';

export default function SwitchboardHeaderButtons({
  projectId,
  toggleSwitchboardSettingsModal
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-end flex-wrap gap-4">
      <ButtonIcon
        icon={['fa', 'arrow-left']}
        color="falcon-default"
        className="rounded-capsule text-nowrap"
        size="md"
        onClick={() => navigate(`/projects/${projectId}`)}
      >
        {t('Back to project')}
      </ButtonIcon>
      <CheckUserPermissions
        permissions={[
          PermissionType.X7SWITCHBOARD.VIEW,
          PermissionType.X7SWITCHBOARD.MODIFY
        ]}
      >
        <ButtonIcon
          icon="cog"
          color="falcon-default"
          className="rounded-capsule text-nowrap"
          size="md"
          onClick={() => navigate(`/projects/${projectId}/x7`)}
        >
          X7
        </ButtonIcon>
        <ButtonIcon
          icon="cog"
          color="falcon-default"
          className="rounded-capsule text-nowrap"
          size="md"
          onClick={() => navigate(`/projects/${projectId}/relay`)}
        >
          {t('Relay')}
        </ButtonIcon>
        <ButtonIcon
          icon="cog"
          color="falcon-default"
          className="rounded-capsule text-nowrap"
          size="md"
          onClick={() => navigate(`/projects/${projectId}/power-sources`)}
        >
          {t('PowerSources')}
        </ButtonIcon>
      </CheckUserPermissions>
      <CheckUserPermissions
        permissions={[
          PermissionType.SWITCHBOARD.VIEW_DETAILS,
          PermissionType.SWITCHBOARD.MODIFY_DETAILS
        ]}
      >
        <ButtonIcon
          icon={['fa', 'tools']}
          color="falcon-default"
          className="rounded-capsule text-nowrap"
          size="md"
          onClick={toggleSwitchboardSettingsModal}
        >
          {t('Settings')}
        </ButtonIcon>
      </CheckUserPermissions>
    </div>
  );
}
