import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../components/form/FormGroupNote';
import { X7Type, X7TypeText } from '../../../helpers/enums/x7TypeEnum';
import { getDefaultSelectValue } from '../../../helpers/utils/getDefaultSelectValue';

export default function X7TypeSelect({ isDisabled, isRequired }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const options = [
    {
      value: X7Type.GenericRelay,
      label: X7TypeText[X7Type.GenericRelay]
    },
    {
      value: X7Type.DigitalInput,
      label: X7TypeText[X7Type.DigitalInput]
    }
  ];

  return (
    <FormGroup>
      <Label htmlFor="type">
        {t('Type')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <Select
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(option) => field.onChange(option.value)}
            value={getDefaultSelectValue(field.value, options)}
            className="text-capitalize"
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.type && (
        <FormGroupNote type="warning">{errors.type.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
