import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { auth } from './auth';
import { clients } from './clients';
import { configurations } from './configurations';
import { consumers } from './consumers';
import { dashboard } from './dashboard';
import { footer } from './footer';
import { form } from './form';
import { manageUsers } from './manageUsers';
import { navbar } from './navbar';
import { offer } from './offer';
import { products } from './products';
import { projects } from './projects';
import { relay } from './relay';
import { powerSources } from './powerSources';
import { siteSettings } from './siteSettings';
import { switchboard } from './switchboard';
import { switchboardForms } from './switchboardForms';
import { toastMessages } from './toastMessages';
import { x7 } from './x7';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          ...navbar.en.translations,
          ...dashboard.en.translations,
          ...projects.en.translations,
          ...clients.en.translations,
          ...products.en.translations,
          ...consumers.en.translations,
          ...configurations.en.translations,
          ...manageUsers.en.translations,
          ...form.en.translations,
          ...switchboard.en.translations,
          ...switchboardForms.en.translations,
          ...siteSettings.en.translations,
          ...toastMessages.en.translations,
          ...auth.en.translations,
          ...x7.en.translations,
          ...powerSources.en.translations,
          ...relay.en.translations,
          ...offer.en.translations,
          ...footer.en.translations
        }
      },
      ro: {
        translations: {
          ...navbar.ro.translations,
          ...dashboard.ro.translations,
          ...projects.ro.translations,
          ...clients.ro.translations,
          ...products.ro.translations,
          ...consumers.ro.translations,
          ...configurations.ro.translations,
          ...manageUsers.ro.translations,
          ...form.ro.translations,
          ...switchboard.ro.translations,
          ...switchboardForms.ro.translations,
          ...siteSettings.ro.translations,
          ...toastMessages.ro.translations,
          ...auth.ro.translations,
          ...x7.ro.translations,
          ...powerSources.ro.translations,
          ...relay.ro.translations,
          ...offer.ro.translations,
          ...footer.ro.translations
        }
      },
      de: {
        translations: {
          ...navbar.de.translations,
          ...dashboard.de.translations,
          ...projects.de.translations,
          ...clients.de.translations,
          ...products.de.translations,
          ...consumers.de.translations,
          ...configurations.de.translations,
          ...manageUsers.de.translations,
          ...form.de.translations,
          ...switchboard.de.translations,
          ...switchboardForms.de.translations,
          ...siteSettings.de.translations,
          ...toastMessages.de.translations,
          ...auth.de.translations,
          ...x7.de.translations,
          ...powerSources.de.translations,
          ...relay.de.translations,
          ...offer.de.translations,
          ...footer.de.translations
        }
      }
    },
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
