import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from '../../components/common/PageHeader';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import RelayHeaderButtons from './components/RelayHeaderButtons';
import RelayModal from './components/RelayModal';
import RelaysList from './components/RelaysList';

export default function RelayScreen() {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const [project, setProject] = useState({});
  const [relays, setRelays] = useState([]);

  const fetchRelay = useCallback(async () => {
    try {
      const response = await projectApi.getProjectById(projectId);
      setProject(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [projectId]);

  useEffect(() => {
    fetchRelay();
  }, [fetchRelay]);

  return (
    <>
      <PageHeader
        title={`${t('Relays')}: ${project.name || '...'}`}
        controls={() => (
          <div className="d-flex flex-wrap justify-content-end gap-4">
            <RelayHeaderButtons />
          </div>
        )}
      />
      <RelaysList relays={relays} setRelays={setRelays} />
      <RelayModal
        relays={relays}
        manufacturerId={project?.switchboard?.manufacturerId}
      />
    </>
  );
}
