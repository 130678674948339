import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SideModal from '../../../components/modals/SideModal';
import AppContext from '../../../context/Context';
import { X7Type } from '../../../helpers/enums/x7TypeEnum';
import X7ModalForm from './X7ModalForm';

export default function X7Modal({ x7Switchboard }) {
  const { t } = useTranslation();
  const { isOpenX7Modal, toggleX7Modal, X7ForEdit } = useContext(AppContext);

  const emptyFormData = useMemo(
    () => ({
      label: '',
      index: x7Switchboard.length + 1,
      type: X7Type.GenericRelay,
      switchboardId: null
    }),
    [x7Switchboard]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (X7ForEdit) {
      setFormData({
        label: X7ForEdit.label,
        index: X7ForEdit.idx,
        type: X7ForEdit.type,
        switchboardId: X7ForEdit.switchboardId
      });
    } else {
      setFormData(emptyFormData);
    }
  }, [X7ForEdit, emptyFormData]);

  return (
    <SideModal
      isOpen={isOpenX7Modal}
      toggle={toggleX7Modal}
      title="X7 Form"
      subtitle={X7ForEdit ? `${t('Edit')} #${X7ForEdit.label}` : t('Create X7')}
      icon="edit"
    >
      <X7ModalForm
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultValues={formData}
        x7Switchboard={x7Switchboard}
      />
    </SideModal>
  );
}
