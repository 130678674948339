import PhaseDetailsBadge from '../../../components/PhaseDetailsBadge';

export default function SwitchboardPhasesDetails({ phasesDetails }) {
  return (
    <div className="d-flex align-items-center mr-2 flex-wrap justify-content-end gap-2">
      <PhaseDetailsBadge
        hasHighTension={phasesDetails.hasL1HighTension}
        consumersTotalPower={phasesDetails.l1ConsumersTotalPower}
        phaseIndex="L1"
        percentage={phasesDetails.l1Percentage}
      />
      <PhaseDetailsBadge
        hasHighTension={phasesDetails.hasL2HighTension}
        consumersTotalPower={phasesDetails.l2ConsumersTotalPower}
        phaseIndex="L2"
        percentage={phasesDetails.l2Percentage}
      />
      <PhaseDetailsBadge
        hasHighTension={phasesDetails.hasL3HighTension}
        consumersTotalPower={phasesDetails.l3ConsumersTotalPower}
        phaseIndex="L3"
        percentage={phasesDetails.l3Percentage}
      />
    </div>
  );
}
