import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import AppContext from '../../context/Context';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../hoc/checkUserPermissions';
import { deleteConfirmationToast } from '../deleteConfirmationToast';

const ActionButton = ({ project }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleProjectModal, setFetchData } = useContext(AppContext);

  const deleteProject = async (id) => {
    try {
      await projectApi.deleteProject(id);
      toast.success(t('Project has been successfully deleted!'));
      setFetchData(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to remove')} ${project.name} ${'project'}.`,
      subtitle: t("This action can't be reverted!"),
      onClick: () => deleteProject(project.id),
      buttonText: t('Yes, delete!')
    });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        <DropdownItem
          onClick={() => {
            navigate(`/projects/${project.id}`);
          }}
        >
          {t('View')}
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            navigate(`/projects/${project.id}/switchboard`);
          }}
        >
          {t('Configure')}
        </DropdownItem>

        <CheckUserPermissions
          permissions={[
            PermissionType.PROJECTS.MODIFY,
            PermissionType.PROJECT_DETAILS.MODIFY
          ]}
        >
          <DropdownItem divider />
          <DropdownItem
            className="text-warning"
            onClick={() => toggleProjectModal(project.id)}
          >
            {t('Edit')}
          </DropdownItem>
          <DropdownItem
            className="text-danger"
            onClick={() => {
              showDeleteConfirmationToast();
            }}
          >
            {t('Delete')}
          </DropdownItem>
        </CheckUserPermissions>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionButton;
