import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

export const getClientsColumns = ({
  applySort,
  t,
  onClick,
  showDeleteConfirmationToast
}) => {
  return [
    {
      dataField: 'id',
      text: 'Id',
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('id')
    },
    {
      dataField: 'name',
      text: t('Name'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('name')
    },
    {
      dataField: 'email',
      text: t('Email'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('email')
    },
    {
      dataField: 'phone',
      text: t('Phone'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('phone')
    },
    {
      dataField: 'createdAt',
      text: t('Created At'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('createdAt'),
      formatter: (cell, row) => {
        return new Date(row.createdAt).toLocaleDateString();
      }
    },
    {
      dataField: 'createdBy',
      text: t('Created By'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: false
    },
    {
      formatter: (_, row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              size="sm"
              className="text-600 btn-reveal mr-3"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
            </DropdownToggle>
            <DropdownMenu right className="border py-2">
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(row.id);
                }}
              >
                {t('Edit')}
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  showDeleteConfirmationToast(row.name, row.id);
                }}
              >
                {t('Delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      text: '',
      classes: 'border-0',
      headerClasses: 'border-0',
      dataField: ''
    }
  ];
};
