import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Media } from 'reactstrap';

import cloudUpload from '../../../assets/img/icons/cloud-upload.svg';
import ButtonIcon from '../../../components/common/ButtonIcon';
import Flex from '../../../components/common/Flex';

const getSize = size => {
  if (size < 1024) {
    return (
      <>
        <strong>{size}</strong> Byte
      </>
    );
  } else if (size < 1024 * 1024) {
    return (
      <>
        <strong>{(size / 1024).toFixed(2)}</strong> KB
      </>
    );
  } else {
    return (
      <>
        <strong>{(size / (1024 * 1024)).toFixed(2)}</strong> MB
      </>
    );
  }
};

const FileDropzone = ({ placeholder, className, onChange, file, preview, ...rest }) => {
  const onDrop = file => {
    if (file.length > 0) {
      onChange(file[0]);
    }
  };

  return (
    <>
      <Dropzone multiple={false} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classNames(
                'p-3 border-dashed border-2x border-300 bg-light rounded-soft text-center cursor-pointer',
                className
              )
            })}
          >
            <input {...{ ...getInputProps(), ...rest }} />
            {placeholder}
          </div>
        )}
      </Dropzone>
      {preview && file && (
        <div className="mt-2">
          <Media className="align-items-center py-3 border-bottom btn-reveal-trigger" key={file.id}>
            <Media body tag={Flex} justify="between" align="center">
              <div>
                <h6 data-dz-name="">{file.path}</h6>
                <Flex className="position-relative" align="center">
                  <p className="mb-0 fs--1 text-400 line-height-1">{getSize(file.size)}</p>
                </Flex>
              </div>
              <ButtonIcon icon={'trash'} color={'link'} className="text-danger" onClick={() => onChange(null)} />
            </Media>
          </Media>
        </div>
      )}
    </>
  );
};

FileDropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  files: PropTypes.array,
  preview: PropTypes.bool,
  isMulti: PropTypes.bool
};

FileDropzone.defaultProps = {
  placeholder: <img src={cloudUpload} alt="" width={25} className="mr-2" />,
  files: [],
  preview: false
};

export default FileDropzone;
