import axios from '../../axios/axios.service';

export const roleApi = {
  getRoles: async ({ includeAdmin }) => {
    return await axios.get(`/role/all`, {
      params: {
        includeAdmin
      }
    });
  },
  getRolePermissions: async (id) => {
    return await axios.get(`/role/${id}`);
  },
  addRolePermissions: async (id, permissions) => {
    return await axios.post(`/role/${id}`, permissions);
  },
  getPermissions: async () => {
    return await axios.get('/role/permissions');
  }
};
