import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Creatable from 'react-select/creatable';
import { FormGroup, InputGroup, InputGroupText, Label } from 'reactstrap';

import RequiredStarLabel from '../common/requiredStarLabel';
import FormGroupNote from './FormGroupNote';

export default function FloatValueInputSelect({
  isDisabled,
  isRequired = false,
  label,
  displayLabel,
  inputGroupText = null,
  placeholder,
  options = []
}) {
  const errorRef = useRef(null);
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const getDefaultValue = (fieldValue) => {
    if (!fieldValue) {
      return {
        label: '0',
        value: 0
      };
    }

    const value = options.find((option) => option.value === fieldValue);

    if (!value) {
      return {
        label: `${fieldValue}`,
        value: fieldValue
      };
    }

    return value;
  };

  useEffect(() => {
    if (errors?.[label] && errorRef?.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors[label]]);

  return (
    <FormGroup>
      <Label htmlFor={label}>
        {t(displayLabel)} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={label}
        control={control}
        render={({ field }) => (
          <InputGroup className="d-flex">
            <Creatable
              id={label}
              name={label}
              options={options}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(option) => field.onChange(option?.value ?? null)}
              placeholder={placeholder}
              value={getDefaultValue(field.value)}
              isSearchable
              isDisabled={isDisabled}
              className="flex-grow-1"
              isClearable
              ref={field.ref}
            />
            {inputGroupText && (
              <InputGroupText>{t(inputGroupText)}</InputGroupText>
            )}
          </InputGroup>
        )}
      />
      <div ref={errorRef}>
        {errors[label] && (
          <FormGroupNote type="warning">
            {t(errors[label].message)}
          </FormGroupNote>
        )}
      </div>
    </FormGroup>
  );
}
