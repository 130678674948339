import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function BlindPlate({ isDisabled, isRequired = false }) {
  const { watch } = useFormContext();

  const verticalModules = watch('verticalModulesPerClampRow');
  const horizontalModules = watch('horizontalModulesPerRow');

  const params = {
    verticalModules,
    horizontalModules,
    categorySlug: systemProductCategorySlug.BlindPlate
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Blind Plate"
      inputName="blindPlate"
      params={params}
    />
  );
}
