import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import { dataContractApi } from '../../../../helpers/api/dataContractApi';
import { ConnectionTypeText } from '../../../../helpers/enums/connectionTypeEnum';
import RequiredStarLabel from '../../../common/requiredStarLabel';
import EntitySelect from '../../../entitySelect/EntitySelect';
import FormGroupNote from '../../FormGroupNote';

export default function ConnectionTypeSelect({
  isDisabled,
  isRequired = false
}) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="connectionType">
        {t('Connection Type')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="connectionType"
        control={control}
        render={({ field }) => (
          <>
            <EntitySelect
              name="connectionType"
              displayName={t('Connection Type')}
              fieldValue={field.value}
              onChange={field.onChange}
              placeholder="Not specified"
              fetchDataFunction={() => {
                return dataContractApi.getDataContractsByContractType(
                  'connection-types'
                );
              }}
              isFetchDataEnum
              enumToText={ConnectionTypeText}
              isDisabled={isDisabled}
            />
          </>
        )}
      />
      {errors.connectionType && (
        <FormGroupNote type="warning">
          {errors.connectionType.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
