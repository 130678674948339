import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { settings } from './config';
import AppContext from './context/Context';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getUserIdentity } from './helpers/utils/getUserIdentity';
import {
  getItemFromStore,
  setItemToStore,
  themeColors
} from './helpers/utils/utils';
import Layout from './layouts/Layout';
import i18n from './localization';

import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const { t } = useTranslation();
  const [isFluid, setIsFluid] = useState(
    getItemFromStore('isFluid', settings.isFluid)
  );
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', settings.isRTL));
  const [isDark, setIsDark] = useState(
    getItemFromStore('isDark', settings.isDark)
  );
  const [isTopNav, setIsTopNav] = useState(
    getItemFromStore('isTopNav', settings.isTopNav)
  );
  const [isCombo, setIsCombo] = useState(
    getItemFromStore('isCombo', settings.isCombo)
  );
  const [isVertical, setIsVertical] = useState(
    getItemFromStore('isVertical', settings.isVertical)
  );
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    )
  );
  const [currency, setCurrency] = useState(settings.currency);
  const [showBurgerMenu, setShowBurgerMenu] = useState(settings.showBurgerMenu);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);

  const [isOpenProductModal, setIsOpenProductModal] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const toggleProductModal = (productId) => {
    setEditProductId(isOpenProductModal ? null : productId);
    setIsOpenProductModal(!isOpenProductModal);
  };

  const [isOpenClientModal, setIsOpenClientModal] = useState(false);
  const [editClientId, setEditClientId] = useState(null);
  const toggleClientModal = (clientId = null) => {
    setEditClientId(!isOpenClientModal ? clientId : null);
    setIsOpenClientModal(!isOpenClientModal);
  };

  const [isOpenProjectModal, setIsOpenProjectModal] = useState(false);
  const [editProjectId, setEditProjectId] = useState(null);
  const toggleProjectModal = (clientId = null) => {
    setEditProjectId(isOpenProjectModal ? null : clientId);
    setIsOpenProjectModal(!isOpenProjectModal);
  };

  const [isOpenConsumerModal, setIsOpenConsumerModal] = useState(false);
  const [editConsumerId, setEditConsumerId] = useState(null);
  const toggleConsumerModal = (consumerId) => {
    setEditConsumerId(isOpenConsumerModal ? null : consumerId);
    setIsOpenConsumerModal(!isOpenConsumerModal);
  };

  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const toggleUserModal = (userId) => {
    setEditUserId(isOpenUserModal ? null : userId);
    setIsOpenUserModal(!isOpenUserModal);
  };

  const [isOpenX7Modal, setIsOpenX7Modal] = useState(false);
  const [X7ForEdit, setX7ForEdit] = useState(null);
  const toggleX7Modal = (x7 = null) => {
    setX7ForEdit(isOpenX7Modal ? null : x7);
    setIsOpenX7Modal(!isOpenX7Modal);
  };

  const [isOpenRelayModal, setIsOpenRelayModal] = useState(false);
  const [relayForEdit, setRelayForEdit] = useState(null);
  const toggleRelayModal = (relay = null) => {
    setRelayForEdit(isOpenRelayModal ? null : relay);
    setIsOpenRelayModal(!isOpenRelayModal);
  };

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState(
    getItemFromStore('navbarStyle', settings.navbarStyle)
  );
  const toggleModal = () =>
    setIsOpenSidePanel((prevIsOpenSidePanel) => !prevIsOpenSidePanel);

  const [isAuthenticated, setIsAuthenticated] = useState(
    settings.isAuthenticated
  );

  const [user, setUser] = useState(settings.user);

  const [fetchData, setFetchData] = useState(false);

  const [language, setLanguage] = useState(localStorage.getItem('language'));

  const [selectedCategory, setSelectedCategory] = useState(null);

  const variables = {
    isRTL,
    isDark,
    isCombo,
    isFluid,
    isTopNav,
    currency,
    isVertical,
    navbarStyle,
    showBurgerMenu,
    isOpenSidePanel,
    navbarCollapsed,
    isNavbarVerticalCollapsed,
    isAuthenticated,
    user,
    fetchData,
    isOpenProductModal,
    editProductId,
    isOpenClientModal,
    editClientId,
    isOpenProjectModal,
    editProjectId,
    isOpenConsumerModal,
    editConsumerId,
    isOpenUserModal,
    editUserId,
    isOpenX7Modal,
    X7ForEdit,
    isOpenRelayModal,
    relayForEdit,
    language,
    selectedCategory,
    isProfileModalOpen
  };

  const functions = {
    setIsRTL,
    setIsDark,
    setIsCombo,
    setIsFluid,
    toggleModal,
    setIsTopNav,
    setCurrency,
    setIsVertical,
    setNavbarStyle,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    setNavbarCollapsed,
    setIsNavbarVerticalCollapsed,
    setIsAuthenticated,
    setUser,
    setFetchData,
    setIsOpenProductModal,
    setEditProductId,
    toggleProductModal,
    setIsOpenClientModal,
    setEditClientId,
    toggleClientModal,
    setIsOpenProjectModal,
    setEditProjectId,
    toggleProjectModal,
    setIsOpenConsumerModal,
    toggleConsumerModal,
    setEditConsumerId,
    setIsOpenUserModal,
    setEditUserId,
    toggleUserModal,
    setIsOpenX7Modal,
    setX7ForEdit,
    toggleX7Modal,
    setIsOpenRelayModal,
    setRelayForEdit,
    toggleRelayModal,
    setLanguage,
    setSelectedCategory,
    setIsProfileModalOpen,
    toggleProfileModal
  };

  const applicationContext = {
    ...variables,
    ...functions
  };

  const setStylesheetMode = (mode) => {
    setIsLoaded(false);
    setItemToStore(mode, applicationContext[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setStylesheetMode('isFluid');
  }, [isFluid]);

  useEffect(() => {
    setStylesheetMode('isRTL');
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
  }, [isDark]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
  }, [isNavbarVerticalCollapsed]);

  useEffect(() => {
    setItemToStore('isTopNav', isTopNav);
  }, [isTopNav]);

  useEffect(() => {
    setItemToStore('isCombo', isCombo);
  }, [isCombo]);
  useEffect(() => {
    setItemToStore('isVertical', isVertical);
  }, [isVertical]);

  useEffect(() => {
    setItemToStore('navbarStyle', navbarStyle);
  }, [navbarStyle]);

  useEffect(() => {
    if (Cookies.get('token')) {
      getUserIdentity({ setUser, setIsAuthenticated, t });
    } else {
      setIsAuthenticated(false);
    }
  }, [t]);

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  document.documentElement.lang = i18n.language;

  return (
    <AppContext.Provider value={applicationContext}>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </AppContext.Provider>
  );
};

export default App;
