import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import AppContext from '../../../context/Context';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { x7Api } from '../../../helpers/api/x7Api';
import X7DragAndDropZone from './X7DragAndDropZone';

export default function X7ItemsList({ x7Switchboard, setX7Switchboard }) {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { setFetchData, fetchData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchX7Switchboard = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await x7Api.getX7Switchboard(projectId);
      setX7Switchboard(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setX7Switchboard]);

  useEffect(() => {
    fetchX7Switchboard();
    if (fetchData) {
      setFetchData(false);
    }
  }, [fetchX7Switchboard, fetchData, setFetchData]);

  return (
    <Row>
      <Col className="d-flex justify-content-center">
        <X7DragAndDropZone
          x7Switchboard={x7Switchboard}
          fetchX7Switchboard={fetchX7Switchboard}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
