import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import {
  differentialAmperageCategories,
  differentialClassCategories,
  differentialTripCurveCategories
} from '../../../../constants/differentialInputsCategories';
import AppContext from '../../../../context/Context';
import InputFormField from '../../InputFormField';
import InputFormFieldFloatValue from '../../InputFormFieldFloatValue';

export default function DifferentialInputs({ isLoading }) {
  const { selectedCategory } = useContext(AppContext);
  const { t } = useTranslation();

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  const verifyInputsVisibility = (categories) => {
    if (
      categories.includes(selectedCategory?.name.toLowerCase()) ||
      categories.includes(selectedCategory?.slug.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  const isAnyInputVisible =
    verifyInputsVisibility(differentialAmperageCategories) ||
    verifyInputsVisibility(differentialClassCategories) ||
    verifyInputsVisibility(differentialTripCurveCategories);

  useEffect(() => {
    if (selectedCategory) {
      setIsCollapseOpen(
        differentialAmperageCategories.includes(selectedCategory.slug)
      );
    } else {
      setIsCollapseOpen(false);
    }
  }, [selectedCategory]);

  if (!isAnyInputVisible) {
    return null;
  }

  return (
    <>
      <h6
        className="mt-5 mb-3 text-primary font-weight-bold d-flex gap-2 cursor-pointer"
        onClick={toggleCollapse}
      >
        {t('Differential Inputs')}{' '}
        <FontAwesomeIcon icon={isCollapseOpen ? faArrowUp : faArrowDown} />
      </h6>
      <Collapse isOpen={isCollapseOpen}>
        {verifyInputsVisibility(differentialAmperageCategories) && (
          <InputFormFieldFloatValue
            isDisabled={isLoading}
            label="differentialAmperage"
            displayLabel="Differential Amperage"
            inputGroupText="miliamps"
            isDecimal={false}
          />
        )}
        {verifyInputsVisibility(differentialClassCategories) && (
          <InputFormField
            isDisabled={isLoading}
            label="differentialClass"
            displayLabel="Differential Class"
          />
        )}
        {verifyInputsVisibility(differentialTripCurveCategories) && (
          <InputFormField
            isDisabled={isLoading}
            label="differentialTripCurve"
            displayLabel="Differential Trip Curve"
          />
        )}
      </Collapse>
    </>
  );
}
