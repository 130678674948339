import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonIcon from '../../../../components/common/ButtonIcon';
import DragAndDrop from '../../../../components/DragAndDrop';
import AppContext from '../../../../context/Context';
import { switchboardConsumerApi } from '../../../../helpers/api/switchboard/switchboardConsumerApi';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import ConsumerCard from './zoneCard/ConsumerCard';

export default function ConsumerColumn({
  subzone,
  switchboard,
  fetchSwitchboard,
  toggleConsumerModal
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const checkPermission = ({ permissions, callback }) => {
    if (
      checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermissions: user?.permissions,
        permissions: permissions
      })
    ) {
      return callback();
    }
  };

  const handleConsumersSwap = async (projectId, draggedItemId, indexes) => {
    await switchboardConsumerApi.consumerSwap(
      projectId,
      draggedItemId,
      indexes
    );
  };

  return (
    <DragAndDrop
      items={subzone.consumers}
      successMessage="Consumer successfully moved."
      handleItemsSwap={handleConsumersSwap}
      fetchItemsCallback={fetchSwitchboard}
      addItemChildren={
        !subzone.isDeleted && (
          <CheckUserPermissions
            permissions={[PermissionType.SWITCHBOARD.CONSUMER.MODIFY]}
          >
            <ButtonIcon
              className="w-100 mt-1 mb-3"
              color="light"
              size="sm"
              icon="plus"
              onClick={() => toggleConsumerModal({ subzoneId: subzone.id })}
            >
              {t('New Consumer')}
            </ButtonIcon>
          </CheckUserPermissions>
        )
      }
      permissions={[PermissionType.SWITCHBOARD.CONSUMER.MODIFY]}
    >
      <ConsumerCard
        onClick={(consumer) =>
          checkPermission({
            permissions: [
              PermissionType.SWITCHBOARD.CONSUMER.VIEW,
              PermissionType.SWITCHBOARD.CONSUMER.MODIFY
            ],
            callback: () => toggleConsumerModal(consumer)
          })
        }
      />
    </DragAndDrop>
  );
}
