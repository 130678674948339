import axios from '../axios/axios.service';

export const categoryApi = {
  getCategories: async (params) => {
    return axios.get(`/categories`, { params });
  },
  getCategory: async (id) => axios.get(`/categories/${id}`),
  addCategory: async (data) => axios.post(`/categories`, data),
  updateCategory: async (id, data) => axios.post(`/categories/${id}`, data),
  deleteCategory: async (id) => axios.delete(`/categories/${id}`)
};
