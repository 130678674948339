import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

import LoginForm from './components/LoginForm';

const LoginScreen = () => {
  const { t } = useTranslation();

  return (
    <Col md={9} lg={7} xl={5}>
      <Card>
        <CardBody className="fs--1 font-weight-normal p-5">
          <Row className="text-left justify-content-between mb-3">
            <Col xs="auto">
              <h5>{t('Log in')}</h5>
            </Col>
          </Row>
          <LoginForm />
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoginScreen;
