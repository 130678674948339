import { Children, cloneElement, useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';

import AppContext from '../context/Context';
import { checkUserPermissions } from '../helpers/utils/checkUserPermissions';

export default function DragAndDrop({
  items,
  handleItemsSwap,
  fetchItemsCallback,
  successMessage,
  permissions,
  isDragDisabled = false,
  children,
  addItemChildren
}) {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const checkPermission = ({ permissions, callback }) => {
    if (
      checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermissions: user?.permissions,
        permissions: permissions
      })
    ) {
      return callback();
    }
  };

  const handleDragEnd = async (drag) => {
    const draggedItem = items.find(
      (it) => it.id === Number.parseInt(drag.draggableId)
    );

    if (
      drag.reason !== 'DROP' ||
      drag.destination === null ||
      drag.destination.index === null ||
      drag.source.index === null ||
      drag.source.index === drag.destination.index ||
      drag.draggableId === null ||
      draggedItem === null
    )
      return;

    if (!draggedItem) return;

    try {
      await handleItemsSwap(projectId, draggedItem.id, {
        newIndex: drag.destination.index + 1,
        oldIndex: drag.source.index + 1
      });
      toast.success(t(successMessage));
      await fetchItemsCallback();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const clonedChildren = (it, snapshot) =>
    Children.map(children, (child) => cloneElement(child, { it, snapshot }));

  return (
    <Col md={6}>
      <DragDropContext
        onDragEnd={(e) => {
          checkPermission({
            permissions,
            callback: () => handleDragEnd(e)
          });
        }}
      >
        <Droppable droppableId={projectId}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {items.map((it, index) => (
                <Draggable
                  isDragDisabled={it?.isDeleted || isDragDisabled}
                  key={it.id}
                  draggableId={it.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style }}
                    >
                      {clonedChildren(it, snapshot)}
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {addItemChildren}
    </Col>
  );
}
