import Cookies from 'js-cookie';

export const removeUserFromCookies = () => {
  Cookies.remove('token');
  Cookies.remove('tokenType');
  Cookies.remove('refreshToken');
};

export const setUserToCookies = ({
  token = '',
  refreshToken = '',
  tokenType = ''
}) => {
  Cookies.set('token', token, {
    expires: 1
  });
  Cookies.set('refreshToken', refreshToken, {
    expires: 30
  });
  Cookies.set('tokenType', tokenType, {
    expires: 7
  });
};
