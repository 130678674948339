import { ProductDestinationText } from '../../../helpers/enums/productDestinationEnum';
import { ProductGroupText } from '../../../helpers/enums/productGroupEnum';

export const getConvertedEnumsFromRequest = (data) => {
  return {
    ...data,
    items: data.items.map((item) => {
      return {
        ...item,
        group: ProductGroupText[item.group],
        destination: ProductDestinationText[item.destination]
      };
    })
  };
};
