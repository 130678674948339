import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardFooter } from 'reactstrap';

import Flex from '../../../../components/common/Flex';
import OfferProfile from '../../../../components/OfferProfile';
import AppContext from '../../../../context/Context';
import { offersApi } from '../../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import FalconCardHeader from './../../../../components/common/FalconCardHeader';
import DynamicEntriesTable from './DynamicEntriesTable';
import DynamicEntryModal from './DynamicEntryModal';

const OfferProfileTab = () => {
  const { t } = useTranslation();
  const { fetchData, setFetchData } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [editDynamicEntryId, setEditDynamicEntryId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dynamicEntries, setDynamicEntries] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const fetchDynamicEntries = useCallback(async () => {
    setIsLoading(true);
    try {
      const response =
        await offersApi.getDynamicEntriesByProfileId(selectedProfileId);
      setDynamicEntries(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [selectedProfileId, t]);

  useEffect(() => {
    if (selectedProfileId || (selectedProfileId && fetchData)) {
      fetchDynamicEntries();
      setFetchData(false);
    }
  }, [fetchDynamicEntries, selectedProfileId, fetchData, setFetchData]);

  const toggleModal = (id = null) => {
    setEditDynamicEntryId(Number.isInteger(id) ? id : null);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Card>
        <FalconCardHeader title="Dynamic Entries" light={false} breakPoint="sm">
          <OfferProfile
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setDynamicEntries={setDynamicEntries}
            selectedProfileId={selectedProfileId}
            setSelectedProfileId={setSelectedProfileId}
          />
        </FalconCardHeader>
        <CardBody className="p-0">
          <DynamicEntriesTable
            entries={dynamicEntries}
            onEdit={toggleModal}
            refreshEntries={fetchDynamicEntries}
            profileId={selectedProfileId}
          />
        </CardBody>

        <CardFooter tag={Flex} justify="start" className="bg-light">
          <Button
            className={`${!selectedProfileId && 'user-select-none'}`}
            color="primary"
            size="sm"
            disabled={!selectedProfileId}
            title={
              !selectedProfileId ? t('Please select a profile first!') : ''
            }
            onClick={() => setIsModalOpen(true)}
          >
            <FontAwesomeIcon icon="plus" className="mr-2" />
            {t('Add new entry')}
          </Button>
        </CardFooter>
      </Card>
      {(editDynamicEntryId || isModalOpen) && (
        <DynamicEntryModal
          isOpen={isModalOpen}
          toggleModal={() => toggleModal()}
          editDynamicEntryId={editDynamicEntryId}
          profileId={selectedProfileId}
        />
      )}
    </>
  );
};

export default OfferProfileTab;
