import React from 'react';
import { Nav, NavItem } from 'reactstrap';

import LanguageDropdown from './LanguageDropdown';
import NotificationDropdown from './NotificationDropdown';
import ProfileDropdown from './ProfileDropdown';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ml-auto flex-row align-items-center"
    >
      <NavItem>
        <SettingsAnimatedIcon />
      </NavItem>
      <LanguageDropdown />
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
