import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import { productSelectDestinationOptions } from '../../../../constants/productSelectDestinationOptions';
import RequiredStarLabel from '../../../common/requiredStarLabel';
import FormGroupNote from '../../FormGroupNote';

export default function ProductDestinationSelect({
  isRequired = false,
  isDisabled
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const getDefaultValue = (fieldValue) => {
    if (fieldValue) {
      return productSelectDestinationOptions.find(
        (option) => option.value === fieldValue
      );
    }

    return productSelectDestinationOptions[0];
  };

  const handleOnChange = (option, onChange) => {
    onChange(option ? option.value : null);
  };

  return (
    <FormGroup>
      <Label htmlFor="destination">
        {t('Destination')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="destination"
        control={control}
        render={({ field }) => (
          <Select
            id="destination"
            name="destination"
            className="text-capitalize"
            value={getDefaultValue(field.value)}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => t(option.label)}
            options={productSelectDestinationOptions}
            onChange={(option) => handleOnChange(option, field.onChange)}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.destination && (
        <FormGroupNote type={'warning'}>
          {errors.destination.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
