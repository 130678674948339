import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';

import AppContext from '../../context/Context';
import { adminRoutes } from '../../helpers/routes/routes';
import { checkUserPermissions } from '../../helpers/utils/checkUserPermissions';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

const NavbarVerticalMenu = ({ routes }) => {
  const location = useLocation();
  const [openedIndex, setOpenedIndex] = useState(null);
  const { setShowBurgerMenu, user } = useContext(AppContext);

  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
  }, []);

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const getHr = (name) => {
    if (name === 'Widgets' || name === 'Documentation') {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions,
    });
  };

  return (
    <>
      {routes.map((route, index) => {
        if (checkPermission(route.permissions)) {
          if (!route.children) {
            return (
              <div key={index}>
                {getHr(route.name)}
                <NavItem>
                  <NavLink className="nav-link" {...route} onClick={() => setShowBurgerMenu(false)}>
                    <NavbarVerticalMenuItem route={route} />
                  </NavLink>
                </NavItem>
              </div>
            );
          }
          return (
            <div key={index}>
              {getHr(route.name)}
              <NavItem>
                <BootstrapNavLink
                  onClick={(e) => toggleOpened(e, index)}
                  className="dropdown-indicator cursor-pointer"
                  aria-expanded={openedIndex === index}
                >
                  <NavbarVerticalMenuItem route={route} />
                </BootstrapNavLink>
                <Collapse isOpen={openedIndex === index}>
                  <Nav>
                    <NavbarVerticalMenu routes={route.children} location={location} />
                  </Nav>
                </Collapse>
              </NavItem>
            </div>
          );
        } else {
          return null;
        }
      })}
      <div>
        {user && user.isAdmin && <hr className="my-2" />}
        {user &&
          user.isAdmin &&
          adminRoutes.map((route, index) => (
            <div key={index}>
              <NavItem>
                <NavLink className="nav-link" {...route} onClick={() => setShowBurgerMenu(false)}>
                  <NavbarVerticalMenuItem route={route} />
                </NavLink>
              </NavItem>
            </div>
          ))}
      </div>
    </>
  );
};

export default NavbarVerticalMenu;
