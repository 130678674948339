import { useContext } from 'react';

import AppContext from '../../../../context/Context';
import MountTypeSelect from '../SwitchBoardRelatedFields/MountTypeSelect';

export default function MountTypeForCategorySlugs({
  isLoading,
  categoriesSlugs
}) {
  const { selectedCategory } = useContext(AppContext);

  return (
    categoriesSlugs.includes(selectedCategory?.slug) && (
      <MountTypeSelect isDisabled={isLoading} />
    )
  );
}
