import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { getDefaultSelectValue } from '../../helpers/utils/getDefaultSelectValue';

const OfferProfileSelect = ({
  placeholder,
  profiles,
  selectedProfileId,
  setSelectedProfileId,
  className
}) => {
  const [options, setOptions] = useState([]);
  const alterProfiles = () => {
    return profiles.map((profile) => {
      return { value: profile.id, label: profile.label };
    });
  };

  useEffect(() => {
    if (profiles) {
      setOptions(alterProfiles());
    }
  }, [profiles]);

  return (
    <>
      <Select
        id="offerProfile"
        name="offerProfile"
        options={alterProfiles()}
        onChange={(option) => setSelectedProfileId(option.value)}
        placeholder={placeholder}
        className={className}
        value={getDefaultSelectValue(selectedProfileId, options)}
      />
    </>
  );
};

export default OfferProfileSelect;
