import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { ProductGroup } from '../../../../helpers/enums/productGroupEnum';
import InputFormFieldFloatValue from '../../InputFormFieldFloatValue';
import MountTypeSelect from './MountTypeSelect';

export default function SwitchBoardRelatedFields({ isLoading }) {
  const { t } = useTranslation();

  const { watch } = useFormContext();
  const group = watch('group');

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  useEffect(() => {
    setIsCollapseOpen(group === ProductGroup.DistributionBoard);
  }, [group]);

  return (
    <>
      <h6
        className="mt-5 mb-3 text-primary font-weight-bold d-flex gap-2 cursor-pointer"
        onClick={toggleCollapse}
      >
        {t('Switchboard Related Fields')}{' '}
        <FontAwesomeIcon icon={isCollapseOpen ? faArrowUp : faArrowDown} />
      </h6>
      <Collapse isOpen={isCollapseOpen}>
        <InputFormFieldFloatValue
          isDisabled={isLoading}
          label="horizontalModules"
          displayLabel="Horizontal Modules"
          inputGroupText="modules"
        />
        <InputFormFieldFloatValue
          isDisabled={isLoading}
          label="verticalModules"
          displayLabel="Vertical Modules"
          inputGroupText="modules"
        />
        <MountTypeSelect isDisabled={isLoading} />
      </Collapse>
    </>
  );
}
