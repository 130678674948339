import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';

import corner4 from '../../../assets/img/illustrations/corner-2.png';
import Background from '../../../components/common/Background';
import { ProjectStatusText } from '../../../helpers/enums/statusEnum';
import { getStatusColor } from '../../../helpers/utils/utils';

const ProjectDetailsCard = ({ title, subtitle, status }) => {
  const { t } = useTranslation();
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <CardBody>
        <Row>
          <Col>
            <h5 className="mb-0">{t(title)}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="fs--1 mt-1">{subtitle}</p>
            <div className="d-flex align-items-center flex-wrap gap-2">
              <strong className="mr-4">{t('Current status')}</strong>
              <div>
                <Badge
                  color={`${getStatusColor(status)}`}
                  className="mr-2 text-capitalize"
                  pill
                >
                  {t(ProjectStatusText[status])}
                </Badge>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProjectDetailsCard;
