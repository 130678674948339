export const consumers = {
  en: {
    translations: {
      'Add Consumer': 'Add Consumer',
      Power: 'Power',
      'Connection Type': 'Connection Type',
      Aliases: 'Aliases',
      'No aliases': 'No aliases',
      Generic: 'Generic',
      'Light On/Off': 'Light On/Off',
      'LED On/Off': 'LED On/Off',
      Rollup: 'Rollup',
      Heating: 'Heating',
      Cooling: 'Cooling',
      'No Cable Type': 'No Cable Type',
      'No Protection': 'No Protection'
    }
  },
  ro: {
    translations: {
      'Add Consumer': 'Adaugă Consumator',
      Power: 'Putere',
      'Connection Type': 'Tip Conexiune',
      Aliases: 'Pseudonime',
      'No aliases': 'Fără pseudonime',
      Generic: 'Generic',
      'Light On/Off': 'Lumină Aprinsă/Oprită',
      'LED On/Off': 'LED Pornit/Oprit',
      Rollup: 'Rollup',
      Heating: 'Încălzire',
      Cooling: 'Răcire',
      'No Cable Type': 'Fără tip de cablu',
      'No Protection': 'Fără protecție'
    }
  },
  de: {
    translations: {
      'Add Consumer': 'Verbraucher hinzufügen',
      Power: 'Leistung',
      'Connection Type': 'Verbindungstyp',
      Aliases: 'Alias',
      'No aliases': 'Keine Alias',
      Generic: 'Generisch',
      'Light On/Off': 'Licht An/Aus',
      'LED On/Off': 'LED Ein/Aus',
      Rollup: 'Aufrollen',
      Heating: 'Heizung',
      Cooling: 'Kühlung',
      'No Cable Type': 'Kein Kabeltyp',
      'No Protection': 'Kein Schutz'
    }
  }
};
