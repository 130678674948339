import Cookies from 'js-cookie';
import mem from 'mem';

import { userApi } from '../api/user/userApi';
import {
  removeUserFromCookies,
  setUserToCookies
} from '../utils/removeUserFromCookies';

const refreshTokenFn = async () => {
  try {
    const refreshToken = Cookies.get('refreshToken');

    if (refreshToken) {
      const response = await userApi.userRefreshToken(refreshToken);

      if (response.data) {
        const { token, refreshToken, tokenType } = response.data;
        setUserToCookies({ token, refreshToken, tokenType });
      }

      return response.data;
    }
  } catch (error) {
    removeUserFromCookies();
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, { maxAge });
