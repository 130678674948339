export const powerSources = {
  en: {
    translations: {
      PowerSources: 'Power Sources',
      'Consumer Label': 'Consumer Label',
      'Circuit Name': 'Circuit Name',
      'Power Source': 'Power Source',
      'No circuits in switchboard': 'No circuits in switchboard',
    }
  },
  ro: {
    translations: {
      PowerSources: 'Surse de Alimentare',
      'Consumer Label': 'Etichetă Consumator',
      'Circuit Name': 'Nume Circuit',
      'Power Source': 'Sursa de Alimentare',
    }
  },
  de: {
    translations: {
      PowerSources: 'Stromquellen',
      'Consumer Label': 'Verbraucherlabel',
      'Circuit Name': 'Schaltkreisname',
      'Power Source': 'Stromquelle',
    }
  }
};
