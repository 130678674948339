export const RelayType = {
  General: 0,
  Dimmable: 1,
  Led: 2,
  Heating: 3
};

export const RelayTypeText = {
  [RelayType.General]: 'General',
  [RelayType.Dimmable]: 'Dimmable',
  [RelayType.Led]: 'LED',
  [RelayType.Heating]: 'Heating'
};
