import {
  ProductGroup,
  ProductGroupText
} from '../helpers/enums/productGroupEnum';

export const productSelectGroupOptions = [
  {
    value: ProductGroup.Generic,
    label: ProductGroupText[ProductGroup.Generic]
  },
  {
    value: ProductGroup.Protection,
    label: ProductGroupText[ProductGroup.Protection]
  },
  {
    value: ProductGroup.SwitchboardComponent,
    label: ProductGroupText[ProductGroup.SwitchboardComponent]
  },
  {
    value: ProductGroup.Clamp,
    label: ProductGroupText[ProductGroup.Clamp]
  },
  {
    value: ProductGroup.CableAndWires,
    label: ProductGroupText[ProductGroup.CableAndWires]
  },
  {
    value: ProductGroup.ModularDevices,
    label: ProductGroupText[ProductGroup.ModularDevices]
  },
  {
    value: ProductGroup.IsolatedSleeves,
    label: ProductGroupText[ProductGroup.IsolatedSleeves]
  },
  {
    value: ProductGroup.SmallComponents,
    label: ProductGroupText[ProductGroup.SmallComponents]
  },
  {
    value: ProductGroup.DistributionBoard,
    label: ProductGroupText[ProductGroup.DistributionBoard]
  }
];
