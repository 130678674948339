import z from 'zod';

export const categoryCreateFormSchema = z.object({
  name: z.string().trim().min(1, { message: 'Name is required' }),
  slug: z.string().trim().min(1, { message: 'Slug is required' }),
  group: z.number({
    invalid_type_error: 'Group is required.',
    required_error: 'Group is required.'
  })
});
