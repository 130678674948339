import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Row } from 'reactstrap';

import Section from '../components/common/Section';
import Logo from '../components/navbar/Logo';
import AppContext from '../context/Context';
import { PermissionType } from '../helpers/enums/permissionTypeEnum';
import { checkUserPermissions } from '../helpers/utils/checkUserPermissions';

const AuthLayout = () => {
  const { isAuthenticated, user } = useContext(AppContext);

  if (isAuthenticated) {
    if (
      checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermissions: user?.permissions,
        permissions: [PermissionType.DASHBOARD.VIEW]
      })
    ) {
      return <Navigate to="/" />;
    }

    if (
      checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermissions: user?.permissions,
        permissions: [
          PermissionType.PROJECTS.VIEW,
          PermissionType.PROJECT_DETAILS.VIEW
        ]
      })
    ) {
      return <Navigate to="/projects" />;
    }

    return <Navigate to="/no-access" />;
  }

  return (
    <Section className="py-0">
      <Row className="flex-center flex-column min-vh-100">
        <Logo />
        <Outlet />
      </Row>
    </Section>
  );
};

export default AuthLayout;
