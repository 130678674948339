import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { cableColorsApi } from '../../../../../helpers/api/cableColorsApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import { cableColorFormSchema } from '../../../../../helpers/formValidations/cableColorFormSchema';
import { useFormScrollIntoView } from '../../../../../hooks/useFormScrollIntoView';
import InputFormField from '../../../InputFormField';

export default function CableColorFormModal({
  isModalOpen,
  toggleModal,
  cableColorId,
  setCableColor
}) {
  const { setFetchData } = useContext(AppContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      name: ''
    },
    mode: 'onChange',
    resolver: zodResolver(cableColorFormSchema)
  });

  useFormScrollIntoView({ form });

  const resetForm = (name) => form.reset({ name: name ?? '' });

  const handleModalClose = () => {
    toggleModal();
    resetForm();
  };

  const handleCableColorUpsert = async (data) => {
    setIsLoading(true);

    try {
      let response;
      if (cableColorId) {
        response = await cableColorsApi.updateCableColor(cableColorId, data);
      } else {
        response = await cableColorsApi.addCableColor(data);
      }

      setCableColor(response.data.id);
      setFetchData(true);
      handleModalClose();
    } catch (e) {
      showErrorsFromRequest(e, t);
    }

    setIsLoading(false);
  };

  const handleSubmitButtonClick = () => {
    form.handleSubmit(handleCableColorUpsert)();
  };

  const getCableColorById = async () => {
    try {
      const response = await cableColorsApi.getCableColor(cableColorId);
      resetForm(response.data.name);
    } catch (e) {
      showErrorsFromRequest(e, t);
    }
  };

  useEffect(() => {
    if (cableColorId && isModalOpen) {
      getCableColorById();
    } else {
      resetForm();
    }
  }, [cableColorId, isModalOpen]);

  return (
    <Modal
      id="cableColorModal"
      isOpen={isLoading || isModalOpen}
      toggle={isLoading ? null : toggleModal}
    >
      <ModalHeader>
        <span>
          {cableColorId ? t('Edit cable color') : t('Add new cable color')}
        </span>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <Form>
            <InputFormField
              displayLabel="Name"
              label="name"
              isRequired
              isDisabled={isLoading}
            />
          </Form>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleModalClose} disabled={isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          type="button"
          color="success"
          onClick={handleSubmitButtonClick}
          disabled={isLoading}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
