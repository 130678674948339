import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Badge, Card, CardTitle } from 'reactstrap';

import { ProjectStatusText } from '../../helpers/enums/statusEnum';
import { getStatusColor } from '../../helpers/utils/utils';
import ActionButton from './ActionButton';
import ProgressBar from './ProgressBar';

const ProjectCard = ({ project }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card body className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <CardTitle
          className="mb-0"
          onClick={() => navigate(`/projects/${project.id}`)}
          style={{
            '--max-lines': 2,
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            height: '2lh'
          }}
        >
          {project.name}
        </CardTitle>
        <ActionButton project={project} />
      </div>

      <div className="mt-2 mb-2 fs--1">{project.clientName}</div>

      <ProgressBar progress={project.progress} />

      <div className="d-flex justify-content-between pt-3 flex-wrap">
        <Badge
          color={`${getStatusColor(project.status)}`}
          className="mr-2 text-capitalize"
          pill
        >
          {t(ProjectStatusText[project.status])}
        </Badge>
        <small>
          <Trans>Last Update</Trans> {' '}
          <span>{`${moment(project.updatedAt).format('DD-MM-YY')}`}</span>
        </small>
      </div>
    </Card>
  );
};

export default ProjectCard;
