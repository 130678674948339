import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';

import { roleApi } from '../../helpers/api/user/roleApi';
import RequiredStarLabel from '../common/requiredStarLabel';
import EntitySelect from '../entitySelect/EntitySelect';
import FormGroupNote from './FormGroupNote';

export default function UserRoleSelect({ isDisabled, isRequired = false }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="userRole">
        User Role {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="userRole"
        control={control}
        render={({ field }) => (
          <EntitySelect
            name="userRole"
            displayName="user role"
            fieldValue={field.value}
            onChange={field.onChange}
            fetchDataFunction={() => {
              return roleApi.getRoles({ includeAdmin: true });
            }}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.userRole && (
        <FormGroupNote type="warning">{errors.userRole.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
