import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import { dataContractApi } from '../../../../helpers/api/dataContractApi';
import { ProductGroupText } from '../../../../helpers/enums/productGroupEnum';
import RequiredStarLabel from '../../../common/requiredStarLabel';
import EntitySelect from '../../../entitySelect/EntitySelect';
import FormGroupNote from '../../FormGroupNote';

export default function ProductGroupSelect({ isRequired = false, isDisabled }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Label htmlFor="group">
        {t('Group')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="group"
        control={control}
        render={({ field }) => (
          <EntitySelect
            name="group"
            displayName={t('Group')}
            fieldValue={field.value}
            onChange={field.onChange}
            placeholder="Not specified"
            fetchDataFunction={() => {
              return dataContractApi.getDataContractsByContractType(
                'product-groups'
              );
            }}
            isFetchDataEnum
            enumToText={ProductGroupText}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.group && (
        <FormGroupNote type={'warning'}>{errors.group.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
