import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import FormGroupNote from '../../../../components/form/FormGroupNote';
import { manufacturerApi } from '../../../../helpers/api/manufacturerApi';
import { getDefaultSelectValue } from '../../../../helpers/utils/getDefaultSelectValue';
import { getNullSwitchboardProductFormValues } from '../../../../helpers/utils/getSwitchboardSettingsFormValues';

export default function SwitchboardManufacturerFilter({ isDisabled }) {
  const { t } = useTranslation();
  const {
    control,
    resetField,
    formState: { errors }
  } = useFormContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['manufacturerFilter'],
    queryFn: () =>
      manufacturerApi.getManufacturers().then((res) =>
        res.data
          .filter((manufacturer) => manufacturer.name !== 'N/A')
          .map((manufacturer) => ({
            value: manufacturer.id,
            label: manufacturer.name
          }))
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: []
  });

  const handleSelectChange = (option, onChange) => {
    onChange(option.id || null);
    resetField('switchboardProduct', {
      defaultValue: getNullSwitchboardProductFormValues()
    });
  };

  return (
    <FormGroup>
      <Label htmlFor="switchboardManufacturerId">
        {t('Switchboard Manufacturer')}
      </Label>
      <Controller
        name="switchboardManufacturerId"
        control={control}
        render={({ field }) => (
          <Select
            options={data}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={getDefaultSelectValue(field.value, data)}
            onChange={(option) => handleSelectChange(option, field.onChange)}
            placeholder="Not assigned"
            isClearable
            isDisabled={isDisabled || isLoading || isError}
          />
        )}
      />
      {errors && errors.switchboardManufacturerId && (
        <FormGroupNote type="warning">
          {t(errors.switchboardManufacturerId?.message)}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
