import axios from '../axios/axios.service';

export const consumerApi = {
  getConsumers: async (params) => {
    return await axios.get(`/consumers`, { params });
  },
  addConsumer: async (data) => {
    return await axios.post(`/consumers`, data);
  },
  deleteConsumer: async (id) => {
    return await axios.delete(`/consumers/${id}`);
  },
  getConsumerById: async (id) => {
    return await axios.get(`/consumers/${id}`);
  },
  updateConsumer: async (id, data) => {
    return await axios.post(`/consumers/${id}`, data);
  },
};
