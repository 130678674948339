export const PermissionType = {
  DASHBOARD: {
    VIEW: 'Dashboard.View'
  },
  PRODUCT: {
    VIEW: 'Product.View',
    MODIFY: 'Product.Modify'
  },
  CONSUMER: {
    VIEW: 'Consumer.View',
    MODIFY: 'Consumer.Modify'
  },
  USER_MANAGEMENT: {
    MANAGE_USER_ROLES: {
      VIEW: 'ManageUserRoles.View',
      MODIFY: 'ManageUserRoles.Modify'
    }
  },
  SWITCHBOARD: {
    VIEW_DETAILS: 'Switchboard.ViewDetails',
    MODIFY_DETAILS: 'Switchboard.ModifyDetails',
    ZONE: {
      VIEW: 'Switchboard.Zone.View',
      MODIFY: 'Switchboard.Zone.Modify'
    },
    SUBZONE: {
      VIEW: 'Switchboard.Subzone.View',
      MODIFY: 'Switchboard.Subzone.Modify'
    },
    CONSUMER: {
      VIEW: 'Switchboard.Consumer.View',
      MODIFY: 'Switchboard.Consumer.Modify'
    }
  },
  X7SWITCHBOARD: {
    VIEW: 'Switchboard.X7.View',
    MODIFY: 'Switchboard.X7.Modify'
  },
  RELAY: {
    VIEW: 'Switchboard.Relay.View',
    MODIFY: 'Switchboard.Relay.Modify'
  },
  PROJECTS: {
    VIEW: 'Projects.View',
    MODIFY: 'Projects.Modify'
  },
  PROJECT_DETAILS: {
    VIEW: 'ProjectDetails.View',
    MODIFY: 'ProjectDetails.Modify',
    STATUS_MODIFY: 'ProjectDetails.Status.Modify'
  },
  PROJECT_DETAILS_OFFER: {
    VIEW: 'ProjectDetails.Offer.View',
    MODIFY: 'ProjectDetails.Offer.Modify'
  },
  PROJECT_DETAILS_HISTORY: {
    CAN_SEE_PUBLIC_COLUMN: 'ProjectDetails.History.CanSeePublicColumn',
    CAN_SEE_CUSTOMER_VISIBLE_CHECKBOX:
      'ProjectDetails.History.CanSeeCustomerVisibleCheckbox'
  },
  CLIENT: {
    VIEW: 'Client.View',
    MODIFY: 'Client.Modify'
  },
  CONFIGURATIONS: {
    VIEW: 'Configurations.View',
    MODIFY: 'Configurations.Modify'
  }
};
