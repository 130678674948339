import z from 'zod';

export const clientFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Name is required' })
    .max(100, { message: 'Name must be less than 100 characters' }),
  email: z.string().trim().min(1, { message: 'Email is required' }).email({ message: 'Invalid email address' }),
  phone: z.string().trim().max(20, { message: 'Phone number is too long' }).optional(),
  address: z.string().trim().max(255, { message: 'Address is too long' }).optional(),
  companyRegistrationCode: z.string().trim().max(255, { message: 'Company registration code is too long' }).optional(),
  companyFiscalCode: z.string().trim().max(255, { message: 'Company fiscal code is too long' }).optional(),
  IDNumber: z.string().trim().max(255, { message: 'ID number is too long' }).optional(),
});
