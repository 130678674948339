import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function ConfirmationModal({
  isOpen,
  setIsOpen,
  onConfirm,
  titleText,
  bodyText,
  confirmText,
  cancelText,
  confirmButtonColor = 'success'
}) {
  const { t } = useTranslation();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t(titleText)}</ModalHeader>
      <ModalBody>{t(bodyText)}</ModalBody>
      <ModalFooter>
        <Button color={confirmButtonColor} onClick={onConfirm}>
          {t(confirmText)}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {t(cancelText)}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
