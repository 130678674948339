import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SideModal from '../../../components/modals/SideModal';
import AppContext from '../../../context/Context';
import { RelayType } from '../../../helpers/enums/relayTypeEnum';
import RelayModalForm from './RelayModalForm';

export default function RelayModal({ relays, manufacturerId }) {
  const { t } = useTranslation();
  const { isOpenRelayModal, toggleRelayModal, relayForEdit } =
    useContext(AppContext);

  const emptyFormData = useMemo(
    () => ({
      label: '',
      index: relays.length + 1,
      relayType: RelayType.General,
      circuitsCount: 1,
      switchboardId: null,
      protection: {
        id: null,
        label: ''
      }
    }),
    [relays]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (relayForEdit) {
      setFormData({
        label: relayForEdit.label,
        index: relayForEdit.idx,
        relayType: relayForEdit.relayType,
        circuitsCount: relayForEdit.circuitsCount,
        switchboardId: relayForEdit.switchboardId,
        protection: {
          id: relayForEdit.protectionId,
          label: ''
        }
      });
    } else {
      setFormData(emptyFormData);
    }
  }, [relayForEdit, emptyFormData]);

  return (
    <SideModal
      isOpen={isOpenRelayModal}
      toggle={toggleRelayModal}
      title="Relay Form"
      subtitle={
        relayForEdit ? `${t('Edit')} #${relayForEdit.label}` : t('Create relay')
      }
      icon="edit"
    >
      <RelayModalForm
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultValues={formData}
        relays={relays}
        manufacturerId={manufacturerId}
      />
    </SideModal>
  );
}
