import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonIcon from '../../../components/common/ButtonIcon';
import DragAndDrop from '../../../components/DragAndDrop';
import AppContext from '../../../context/Context';
import { x7Api } from '../../../helpers/api/x7Api';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../../hoc/checkUserPermissions';
import X7ItemCard from './X7ItemCard';

export default function X7DragAndDropZone({
  x7Switchboard,
  fetchX7Switchboard,
  isLoading
}) {
  const { toggleX7Modal } = useContext(AppContext);
  const { t } = useTranslation();

  const handleItemsSwap = async (projectId, draggedItemId, indexes) => {
    await x7Api.X7SwitchboardSwap(projectId, draggedItemId, indexes);
  };

  return (
    <DragAndDrop
      items={x7Switchboard}
      successMessage="Item successfully moved."
      handleItemsSwap={handleItemsSwap}
      fetchItemsCallback={fetchX7Switchboard}
      isDragDisabled={isLoading}
      addItemChildren={
        <CheckUserPermissions
          permissions={[PermissionType.X7SWITCHBOARD.MODIFY]}
        >
          <ButtonIcon
            className="w-100 mt-1 mb-3"
            color="light"
            size="sm"
            icon="plus"
            onClick={() => toggleX7Modal()}
          >
            {t('Add item')}
          </ButtonIcon>
        </CheckUserPermissions>
      }
      permissions={[PermissionType.X7SWITCHBOARD.MODIFY]}
    >
      <X7ItemCard onClick={(it) => toggleX7Modal(it)} />
    </DragAndDrop>
  );
}
