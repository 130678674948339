export const CableType = {
  FORCE: 0,
  BUS: 1,
  NETWORK: 2
};

export const CableTypeText = {
  [CableType.FORCE]: 'Force',
  [CableType.BUS]: 'Bus',
  [CableType.NETWORK]: 'Network'
};
