export const switchboard = {
  en: {
    translations: {
      'Back to project': 'Back to project',
      Settings: 'Settings',
      'New Zone': 'New Zone',
      'New Subzone': 'New Subzone',
      'New Consumer': 'New Consumer',
      'Show deleted': 'Show deleted',
      'Force cable code': 'Force cable code',
      'Bus cable code': 'Bus cable code',
      'Network cable code': 'Network cable code',
      'Switchboard width': 'Switchboard width',
      'Switchboard height': 'Switchboard height',
      'Horizontal modules per row': 'Horizontal modules per row',
      'Vertical modules per generic row': 'Vertical modules per generic row',
      'Number of generic rows': 'Number of generic rows',
      'Vertical modules per clamp row': 'Vertical modules per clamp row',
      'Number of clamp rows': 'Number of clamp rows',
      'Switchboard parameters': 'Switchboard parameters',
      Relay: 'Relay',
      Contactor: 'Contactor',
      'Is all included': 'Is all included'
    }
  },
  ro: {
    translations: {
      'Back to project': 'Înapoi la proiect',
      Settings: 'Setări',
      'New Zone': 'Zonă nouă',
      'New Subzone': 'Subzonă nouă',
      'New Consumer': 'Consumator nou',
      'Show deleted': 'Arată șterse',
      'Force cable code': 'Codul cablului Force',
      'Bus cable code': 'Codul cablului BUS',
      'Network cable code': 'Codul cablului de rețea',
      'Switchboard width': 'Lățime tablou',
      'Switchboard height': 'Înălțime tablou',
      'Horizontal modules per row': 'Module orizontale pe rând',
      'Vertical modules per generic row': 'Module verticale pe rând generic',
      'Number of generic rows': 'Număr de rânduri generice',
      'Vertical modules per clamp row': 'Module verticale pe rând de fixare',
      'Number of clamp rows': 'Număr de rânduri de fixare',
      'Switchboard parameters': 'Parametri tablou',
      Relay: 'Releu',
      Contactor: 'Contactor',
      'Is all included': 'Este totul inclus'
    }
  },
  de: {
    translations: {
      'Back to project': 'Zurück zum Projekt',
      Settings: 'Einstellungen',
      'New Zone': 'Neue Zone',
      'New Subzone': 'Neue Subzone',
      'New Consumer': 'Neuer Verbraucher',
      'Show deleted': 'Gelöschte anzeigen',
      'Force cable code': 'Force-Kabelcode',
      'Bus cable code': 'Bus-Kabelcode',
      'Network cable code': 'Netzwerkkabelcode',
      'Switchboard width': 'Schaltschrankbreite',
      'Switchboard height': 'Schalts chrankhöhe',
      'Horizontal modules per row': 'Horizontale Module pro Reihe',
      'Vertical modules per generic row':
        'Vertikale Module pro generischer Reihe',
      'Number of generic rows': 'Anzahl der generischen Reihen',
      'Vertical modules per clamp row': 'Vertikale Module pro Klemmenreihe',
      'Number of clamp rows': 'Anzahl der Klemmenreihen',
      'Switchboard parameters': 'Parameter der Telefonzentrale',
      Relay: 'Relais',
      Contactor: 'Schütz',
      'Is all included': 'Ist alles inklusive'
    }
  }
};
