import axios from '../axios/axios.service';

export const relayApi = {
  getRelays: async (projectId) =>
    axios.get(`/projects/${projectId}/switchboard/relay`),
  getRelay: async (projectId, relayId) =>
    axios.get(`/projects/${projectId}/switchboard/relay/${relayId}`),
  createRelay: async (projectId, relay) =>
    axios.post(`/projects/${projectId}/switchboard/relay`, relay),
  updateRelay: async (projectId, relayId, relay) =>
    axios.post(`/projects/${projectId}/switchboard/relay/${relayId}`, relay),
  deleteRelay: async (projectId, relayId) =>
    axios.delete(`/projects/${projectId}/switchboard/relay/${relayId}`),
  swapRelay: async (projectId, relayId, data) =>
    axios.post(`/projects/${projectId}/switchboard/relay/${relayId}/swap`, data)
};
