export const offer = {
  en: {
    translations: {
      'You are about to recreate the offer':
        'You are about to recreate the offer',
      'This will delete the current offer and create a new one. Are you sure you want to continue?':
        'This will delete the current offer and create a new one. Are you sure you want to continue?',
      'Some switchboard parameters are missing. Please add them to see all values in the offer.':
        'Some switchboard parameters are missing. Please add them to see all values in the offer.',
      'You have some changes on the switchboard. Please recreate the offer to see it updated to the latest values.':
        'You have some changes on the switchboard. Please recreate the offer to see it updated to the latest values.'
    }
  },
  ro: {
    translations: {
      'You are about to recreate the offer':
        'Sunteți pe cale să recreați oferta',
      'This will delete the current offer and create a new one. Are you sure you want to continue?':
        'Acest lucru va șterge oferta curentă și va crea una nouă. Sunteți sigur că doriți să continuați?',
      'Some switchboard parameters are missing. Please add them to see all values in the offer.':
        'Lipsesc unii parametri ai tabloului electric. Vă rugăm să-i completați pentru a vedea toate valorile din ofertă.',
      'You have some changes on the switchboard. Please recreate the offer to see it updated to the latest values.':
        'Aveți unele modificări la tabloul electric. Vă rugăm să recreați oferta pentru a o vedea actualizată la cele mai recente valori.'
    }
  },
  de: {
    translations: {
      'You are about to recreate the offer':
        'Sie sind dabei, das Angebot neu zu erstellen',
      'This will delete the current offer and create a new one. Are you sure you want to continue?':
        'Dies löscht das aktuelle Angebot und erstellt ein neues. Sind Sie sicher, dass Sie fortfahren möchten?',
      'Some switchboard parameters are missing. Please add them to see all values in the offer.':
        'Es fehlen einige Parameter der Telefonzentrale. Bitte ergänzen Sie diese, um alle Werte im Angebot zu sehen.',
      'You have some changes on the switchboard. Please recreate the offer to see it updated to the latest values.':
        'Sie haben einige Änderungen an der Telefonzentrale. Bitte erstellen Sie das Angebot neu, um es auf die neuesten Werte zu aktualisieren.'
    }
  }
};
