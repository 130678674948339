import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { validateCaptcha } from 'react-simple-captcha';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'reactstrap';

import InputFormField from '../../../components/form/InputFormField';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { userApi } from '../../../helpers/api/user/userApi';
import { registerFormSchema } from '../../../helpers/formValidations/registerFormSchema';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import CaptchaField from './CaptchaField';
import PasswordInput from './PasswordInput';
import PhoneNumberInputFormField from './PhoneNumberInput';

export default function RegisterForm() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      company: '',
      phoneNumber: '',
      captcha: ''
    },
    mode: 'onChange',
    resolver: zodResolver(registerFormSchema)
  });

  useFormScrollIntoView({ form });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const { captcha, ...rest } = data;
      if (!validateCaptcha(captcha)) {
        toast.error(t('Invalid code!'));
        form.setValue('captcha', '');
        setIsLoading(false);
        return;
      }
      await userApi.userRegister(rest);
      toast.success(t('Registration successful!'));
      form.reset();
      navigate('/auth/login');
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-lg-nowrap flex-wrap"
          style={{ columnGap: '1rem' }}
        >
          <InputFormField
            isDisabled={isLoading}
            isRequired
            label="firstName"
            displayLabel={t('First name')}
          />
          <InputFormField
            isDisabled={isLoading}
            isRequired
            label="lastName"
            displayLabel={t('Last name')}
          />
        </div>
        <InputFormField
          isDisabled={isLoading}
          isRequired
          label="email"
          displayLabel={t('Email')}
        />
        <div
          className="d-flex flex-lg-nowrap flex-wrap"
          style={{ columnGap: '1rem' }}
        >
          <PasswordInput
            label="password"
            displayLabel={t('Password')}
            isRequired
            isDisabled={isLoading}
          />
          <PasswordInput
            label="confirmPassword"
            displayLabel={t('Confirm password')}
            isRequired
            isDisabled={isLoading}
          />
        </div>
        <div
          className="d-flex flex-lg-nowrap flex-wrap"
          style={{ columnGap: '1rem' }}
        >
          <InputFormField label="company" displayLabel={t('Company name')} />
          <PhoneNumberInputFormField />
        </div>
        <CaptchaField isDisabled={isLoading} />
        <Button
          type="submit"
          color="primary"
          block
          className="mt-3"
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" children="" /> : t('Register')}
        </Button>
      </Form>
    </FormProvider>
  );
}
