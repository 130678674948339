import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap';

import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import CheckUserPermissions from '../../../hoc/checkUserPermissions';
import { handleExportProject } from '../helpers/documentationExport';
import { ProjectContext } from '../ProjectScreen';

export default function DocumentationButtons({
  isLoading,
  setIsLoading,
  project
}) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { documentationInfo, setDocumentationInfo } =
    useContext(ProjectContext);

  const fetchDocumentationInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await projectApi.getDocumentationInfo(projectId);
      setDocumentationInfo(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setDocumentationInfo, setIsLoading, t]);

  useEffect(() => {
    fetchDocumentationInfo();
  }, [fetchDocumentationInfo]);

  const generateDocumentation = useCallback(async () => {
    setIsLoading(true);
    try {
      await projectApi.generateSwitchboardDocumentation(projectId);
      setDocumentationInfo({
        languageCode: i18n.language,
        isGenerated: true
      });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [documentationInfo, projectId, setDocumentationInfo, setIsLoading, t]);

  if (documentationInfo === null) return null;

  if (!documentationInfo?.isGenerated) {
    return (
      <Button
        color="falcon-primary"
        size="sm"
        className="text-600 d-flex gap-2"
        onClick={generateDocumentation}
      >
        <span className="text-nowrap">{t('Generate Documentation')}</span>
        {isLoading && <Spinner size="sm" />}
      </Button>
    );
  }

  const handleExport = async (documentType) => {
    await handleExportProject({
      documentType,
      setIsLoading,
      projectId,
      project,
      t
    });
  };

  return (
    <>
      <UncontrolledDropdown disabled={isLoading}>
        <DropdownToggle
          color="falcon-primary"
          size="sm"
          className="text-600 btn-reveal"
        >
          <span className="mr-2">{t('Documents')}</span>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <FontAwesomeIcon icon="ellipsis-vertical" className="fs--1" />
          )}
        </DropdownToggle>
        <DropdownMenu right className="border px-2">
          <DropdownItem onClick={() => handleExport('tableAttach')}>
            {t('Switchboard attach')} (PDF)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tableDocumentation')}>
            {t('Switchboard documentation')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('cableJournal')}>
            {t('Cable journal')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('purchasesJournal')}>
            {t('Purchases list')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tagsClamps')}>
            {t('Tags clamps')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tagsConductors')}>
            {t('Tags conductors')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tagsModularEquipment')}>
            {t('Tags modular equipment')} (Excel)
          </DropdownItem>
          <CheckUserPermissions>
            <DropdownItem onClick={() => handleExport('offer')}>
              {t('Offer')} (Excel)
            </DropdownItem>
          </CheckUserPermissions>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
