export const ProductGroup = {
  Generic: 0,
  Protection: 1,
  SwitchboardComponent: 2,
  Clamp: 3,
  CableAndWires: 4,
  ModularDevices: 5,
  IsolatedSleeves: 6,
  SmallComponents: 7,
  DistributionBoard: 8
};

export const ProductGroupText = {
  [ProductGroup.Generic]: 'Generic',
  [ProductGroup.Protection]: 'Protection',
  [ProductGroup.SwitchboardComponent]: 'Switchboard Component',
  [ProductGroup.Clamp]: 'Clamp',
  [ProductGroup.CableAndWires]: 'Cable And Wires',
  [ProductGroup.ModularDevices]: 'Modular Devices',
  [ProductGroup.IsolatedSleeves]: 'Isolated Sleeves',
  [ProductGroup.SmallComponents]: 'Small Components',
  [ProductGroup.DistributionBoard]: 'Distribution Board'
};
