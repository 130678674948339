import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../components/form/FormGroupNote';

import 'react-phone-number-input/style.css';

export default function PhoneNumberInputFormField({ isRequired, isDisabled }) {
  const ref = useRef(null);
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    if (ref.current) {
      ref.current.classList.add('form-control');
    }
  }, [ref]);

  return (
    <FormGroup className="w-100">
      <Label>
        {t('Phone number')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <PhoneInput
            value={field.value}
            onChange={field.onChange}
            disabled={isDisabled}
            ref={ref}
          />
        )}
      />
      {errors.phoneNumber && (
        <FormGroupNote type={'warning'}>
          {errors.phoneNumber.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
