import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import { deleteConfirmationToast } from '../../../../components/deleteConfirmationToast';
import CableTypeSelect from '../../../../components/form/ConsumerModalForm/CableTypeSelect';
import ConsumerTypeSelect from '../../../../components/form/ConsumerModalForm/ConsumerTypeSelect';
import IndexFieldSelect from '../../../../components/form/IndexFieldSelect';
import InputFormField from '../../../../components/form/InputFormField';
import InputFormFieldFloatValue from '../../../../components/form/InputFormFieldFloatValue';
import AppContext from '../../../../context/Context';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { switchboardConsumerApi } from '../../../../helpers/api/switchboard/switchboardConsumerApi';
import { ConsumerType } from '../../../../helpers/enums/consumerTypeEnum';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { ProductDestination } from '../../../../helpers/enums/productDestinationEnum';
import { switchboardConsumerFormSchema } from '../../../../helpers/formValidations/switchboardConsumerFormSchema';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import { useFormScrollIntoView } from '../../../../hooks/useFormScrollIntoView';
import CircuitsField from './CircuitsField';
import ConsumerSelect from './ConsumerSelect';
import ConsumerTypeDimmableCheckbox from './ConsumerTypeDimmableCheckbox';
import ProtectionSelect from './ProtectionSelect';

export default function ConsumerModalForm({
  isEdit,
  defaultValues,
  switchboard,
  refreshSwitchboard,
  toggle,
  consumer,
  consumers
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      subzoneId: consumer.subzoneId
    },
    mode: 'onChange',
    resolver: zodResolver(switchboardConsumerFormSchema)
  });

  useFormScrollIntoView({ form });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const newData = {
        consumerId: data.consumerId,
        extraClamps: data.extraClamps,
        index: data.index,
        label: data.label,
        power: data.power,
        circuits: [...data.circuits, ...data.dimmableCircuits],
        protection: data.protection.id,
        type: data.type,
        subzoneId: data.subzoneId,
        cableType: data.cableType,
        dimmable: data.dimmable
      };

      if (isEdit) {
        await switchboardConsumerApi.updateConsumer(
          switchboard.projectId,
          consumer.id,
          newData
        );
      } else {
        await switchboardConsumerApi.addConsumer(
          switchboard.projectId,
          newData
        );
      }

      toast.success(t('Consumer successfully saved.'));
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    try {
      await switchboardConsumerApi.deleteConsumer(
        switchboard.projectId,
        consumer.id
      );
      toast.success(t('Consumer successfully deleted.'));
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to delete')} "${consumer.label}" ${t('subzone')} ?`,
      onClick: () => onDelete(),
      buttonText: t('Yes, delete!')
    });
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  const checkIfConsumerIsDimmable = () => {
    const type = form.watch('type');
    return (
      type === ConsumerType.LED_ON_OFF || type === ConsumerType.LIGHT_ON_OFF
    );
  };

  useEffect(() => {
    if (form.watch('dimmable') === false) {
      form.resetField('dimmableCircuits', { defaultValue: [] });
    }
  }, [form.watch('dimmable')]);

  const restoreDeletedConsumer = async () => {
    try {
      await switchboardConsumerApi.restoreConsumer(
        switchboard.projectId,
        consumer.id
      );
      toast.success(t('Consumer successfully restored.'));
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const subzone = switchboard.subzone(consumer.subzoneId);
  const isSubzoneDeleted = subzone?.isDeleted ?? false;
  const isZoneDeleted = switchboard.zone(subzone?.zoneId)?.isDeleted ?? false;

  const checkAreZonesDeleted = isSubzoneDeleted || isZoneDeleted;

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="form-modal-padding">
          <IndexFieldSelect
            isEdit={isEdit}
            items={consumers}
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
          />
          <div className="text-secondary mb-2" style={{ fontSize: '14px' }}>
            <span>
              {t(
                'Select an existing consumer from the list below or fill in a custom one using the fields.'
              )}
            </span>
          </div>
          <ConsumerSelect
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
          />
          <InputFormField
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
            label="label"
            displayLabel="Label"
            isRequired
          />
          <ProtectionSelect
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
            destination={[
              ProductDestination.CONSUMER,
              ProductDestination.ZONEANDCONSUMER,
              ProductDestination.SUBZONEANDCONSUMER
            ]}
            manufacturerId={switchboard.manufacturerId}
            isRequired
          />
          <ConsumerTypeSelect
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
            isRequired
          />
          {checkIfConsumerIsDimmable() && (
            <ConsumerTypeDimmableCheckbox
              isDisabled={
                isLoading ||
                checkAreZonesDeleted ||
                !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
              }
            />
          )}
          <CableTypeSelect
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
            isRequired
          />
          <InputFormFieldFloatValue
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
            label="power"
            displayLabel="Power"
            isRequired
            placeholder="750"
            inputGroupText="watts"
          />
          <InputFormFieldFloatValue
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
            label="extraClamps"
            displayLabel="Extra Clamps"
            placeholder="No extra clamps required"
            inputGroupText="clamps"
          />
          <CircuitsField
            label="circuits"
            isDisabled={
              isLoading ||
              checkAreZonesDeleted ||
              !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
            }
          />
          {form.watch('dimmable') && (
            <CircuitsField
              label="dimmableCircuits"
              isDisabled={
                isLoading ||
                checkAreZonesDeleted ||
                !checkPermission([PermissionType.SWITCHBOARD.CONSUMER.VIEW])
              }
              isDimmable={true}
            />
          )}
          <CheckUserPermissions
            permissions={[PermissionType.SWITCHBOARD.CONSUMER.MODIFY]}
          >
            <div
              className={`d-flex mt-4 ${isEdit ? 'justify-content-between' : ''}`}
            >
              <div>
                {consumer.isDeleted ? (
                  <Button
                    type="button"
                    disabled={isLoading || checkAreZonesDeleted}
                    color="falcon-success"
                    onClick={restoreDeletedConsumer}
                  >
                    {t('Restore')}
                  </Button>
                ) : (
                  isEdit && (
                    <Button
                      type="button"
                      disabled={isLoading}
                      color="falcon-danger"
                      onClick={showDeleteConfirmationToast}
                    >
                      {t('Delete')}
                    </Button>
                  )
                )}
                {isZoneDeleted && (
                  <p className="text-danger mt-4">
                    * Zone is deleted, you have to restore it first.
                  </p>
                )}
                {isSubzoneDeleted && (
                  <p className="text-danger mt-4">
                    * Subzone is deleted, you have to restore it first.
                  </p>
                )}
              </div>
              {!checkAreZonesDeleted && (
                <div>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    color="falcon-primary"
                  >
                    {isLoading ? 'Saving' : 'Save'}
                  </Button>
                </div>
              )}
            </div>
          </CheckUserPermissions>
        </div>
      </Form>
    </FormProvider>
  );
}
