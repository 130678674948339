import React from 'react';
import { Col, Row } from 'reactstrap';

import { config } from '../../config';

const NavFooter = () => (
  <footer>
    <Row className="justify-content-between text-center fs--1 mt-4 mb-1 g-0 flex-nowrap">
      <Col sm="auto">
        <p className="mb-0 text-600 text-nowrap">{new Date().getFullYear()} &copy; Rotter</p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600 text-nowrap">v{config.version}</p>
      </Col>
    </Row>
  </footer>
);

export default NavFooter;
