export const products = {
  en: {
    translations: {
      'Add Product': 'Add Product',
      Label: 'Label',
      Group: 'Group',
      Destination: 'Destination',
      Description: 'Description',
      'Protection Manufacturer': 'Protection Manufacturer',
      'Manufacturer SKU': 'Manufacturer SKU',
      Price: 'Price',
      'Sell Price': 'Sell Price',
      'Measurement Unit': 'Measurement Unit',
      Pieces: 'Pieces',
      Meters: 'Meters',
      'Filter by Group': 'Filter by Group',
      'Filter by Destination': 'Filter by Destination',
      Generic: 'Generic',
      Protection: 'Protection',
      'Switch. Comp.': 'Switch. Comp.',
      Clamp: 'Clamp',
      'Cable And Wires': 'Cable And Wires',
      'Modular Devices': 'Modular Devices',
      'Isolated Sleeve': 'Isolated Sleeves',
      'Small Components': 'Small Components',
      'Distribution Board': 'Distribution Board',
      Zone: 'Zone',
      Subzone: 'Subzone',
      Consumer: 'Consumer',
      Switchboard: 'Switchboard',
      'Loading products': 'Loading products',
      None: 'None',
      Reset: 'Reset'
    }
  },
  ro: {
    translations: {
      'Add Product': 'Adaugă un produs',
      Label: 'Etichetă',
      Group: 'Grupa',
      Destination: 'Destinație',
      Description: 'Descriere',
      'Manufacturer SKU': 'Producător SKU',
      'Protection Manufacturer': 'Producător de protecție',
      Price: 'Preț',
      'Sell Price': 'Preț Vânzare',
      'Measurement Unit': 'Unitatea de măsură',
      Pieces: 'Bucăți',
      Meters: 'Metri',
      'Filter by Group': 'Filtrează după Grup',
      'Filter by Destination': 'Filtrează după Destinație',
      Generic: 'Generic',
      Protection: 'Protecție',
      'Switch. Comp.': 'Componentă Tablou',
      Clamp: 'Clampă',
      'Cable And Wires': 'Cablu și fire',
      'Modular Devices': 'Dispozitive Modulare',
      'Isolated Sleeve': 'Mâneci Izolate',
      'Small Components': 'Componente Mici',
      'Distribution Board': 'Tablă de Distribuție',
      Zone: 'Zonă',
      Subzone: 'Subzonă',
      Consumer: 'Consumator',
      Switchboard: 'Tablou',
      'Loading products': 'Se încarcă produsele',
      None: 'Niciuna',
      Reset: 'Resetează'
    }
  },
  de: {
    translations: {
      'Add Product': 'Produkt hinzufügen',
      Label: 'Etikett',
      Group: 'Gruppe',
      Destination: 'Ziel',
      Description: 'Beschreibung',
      'Protection Manufacturer': 'Schutz Hersteller',
      'Manufacturer SKU': 'Hersteller SKU',
      Price: 'Preis',
      'Sell Price': 'Verkaufspreis',
      'Measurement Unit': 'Maßeinheit',
      Pieces: 'Stücke',
      Meters: 'Meter',
      'Filter by Group': 'Filtern nach Gruppe',
      'Filter by Destination': 'Filtern nach Ziel',
      Generic: 'Generisch',
      Protection: 'Schutz',
      'Switch. Comp.': 'Schalter. Comp.',
      Clamp: 'Klemme',
      'Cable And Wires': 'Kabel und Leitungen',
      'Modular Devices': 'Modulare Geräte',
      'Isolated Sleeve': 'Isolierte Ärmel',
      'Small Components': 'Kleine Komponenten',
      'Distribution Board': 'Verteilertafel',
      Zone: 'Zone',
      Subzone: 'Subzone',
      Consumer: 'Verbraucher',
      Switchboard: 'Schalttafel',
      'Loading products': 'Produkte werden geladen',
      None: 'Keiner',
      Reset: 'Zurücksetzen'
    }
  }
};
