import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import AppContext from '../../context/Context';
import { consumerApi } from '../../helpers/api/consumerApi';
import ConsumerModalForm from '../form/ConsumerModalForm';
import SideModal from './SideModal';

const emptyFormData = {
  label: '',
  power: '',
  connectionType: '',
  type: '',
  alias: '',
  aliases: []
};

const ConsumerModal = () => {
  const { t } = useTranslation();
  const { editConsumerId, isOpenConsumerModal, toggleConsumerModal } =
    useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(emptyFormData);
  const [error, setError] = useState(undefined);

  const fetchConsumer = async () => {
    setIsLoading(true);
    try {
      const response = await consumerApi.getConsumerById(editConsumerId);
      setFormData({
        label: response?.data?.label,
        power: response?.data?.power,
        connectionType: response?.data?.connectionType,
        type: response?.data?.type,
        alias: '',
        aliases: response?.data?.aliases?.map((it) => it.alias),
        protection: response?.data?.protection,
        cableType: response?.data?.cableType
      });
    } catch (error) {
      toast.error(t('Unable to retrieve consumer data.'));
      setError(t('Unable to retrieve consumer data.'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (editConsumerId) {
      fetchConsumer();
    } else {
      setFormData(emptyFormData);
    }
  }, [editConsumerId]);

  return (
    <SideModal
      isOpen={isOpenConsumerModal}
      toggle={toggleConsumerModal}
      title="Consumer Form"
      subtitle={
        editConsumerId
          ? `${t('Edit consumer')} #${editConsumerId}`
          : t('Create a new consumer')
      }
      icon="edit"
    >
      {error === undefined && (
        <ConsumerModalForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          defaultValues={formData}
        />
      )}

      {error !== undefined && <p>{error}</p>}
    </SideModal>
  );
};

export default ConsumerModal;
