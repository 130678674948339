import z from 'zod';

export const loginFormSchema = z.object({
  email: z
    .string({
      invalid_type_error: 'Invalid email address',
      required_error: 'Email is required'
    })
    .email({
      message: 'Invalid email address'
    }),
  password: z
    .string({
      required_error: 'Password is required'
    })
    .min(1, {
      message: 'Password is required'
    })
});
