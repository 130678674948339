import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import ButtonIcon from '../../../../components/common/ButtonIcon';
import AppContext from '../../../../context/Context';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import SubzoneColumn from './SubzoneColumn';
import ZoneCard from './zoneCard/ZoneCard';

export default function ZonesCardsDisplay({
  switchboard,
  fetchSwitchboard,
  toggleZoneModal,
  toggleSubzoneModal,
  toggleConsumerModal
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const checkPermission = ({ permissions, callback }) => {
    if (
      checkUserPermissions({
        isAdmin: user?.isAdmin,
        userPermissions: user?.permissions,
        permissions: permissions
      })
    ) {
      return callback();
    }
  };

  return (
    <>
      {!switchboard.zones.length && (
        <CheckUserPermissions
          permissions={[PermissionType.SWITCHBOARD.ZONE.MODIFY]}
        >
          <ButtonIcon
            className="w-100 mt-1 mb-3"
            color="light"
            size="sm"
            icon="plus"
            onClick={() => toggleZoneModal()}
          >
            {t('New Zone')}
          </ButtonIcon>
        </CheckUserPermissions>
      )}

      {switchboard.zones.map((zone, zoneIndex) => (
        <Row key={`zone - row - ${zone.id}`}>
          <Col md={4}>
            <ZoneCard
              className={`mb-2 ${zone.isDeleted ? 'opacity-50' : ''}`}
              key={zone.id}
              zone={zone}
              onClick={() =>
                checkPermission({
                  permissions: [
                    PermissionType.SWITCHBOARD.ZONE.VIEW,
                    PermissionType.SWITCHBOARD.ZONE.MODIFY
                  ],
                  callback: () => toggleZoneModal(zone)
                })
              }
            />

            {switchboard.zones.length - 1 === zoneIndex && (
              <CheckUserPermissions
                permissions={[PermissionType.SWITCHBOARD.ZONE.MODIFY]}
              >
                <ButtonIcon
                  className="w-100 mt-1 mb-3"
                  color="light"
                  size="sm"
                  icon="plus"
                  onClick={() => toggleZoneModal()}
                >
                  {t('New Zone')}
                </ButtonIcon>
              </CheckUserPermissions>
            )}
          </Col>
          <SubzoneColumn
            zone={zone}
            fetchSwitchboard={fetchSwitchboard}
            switchboard={switchboard}
            toggleConsumerModal={toggleConsumerModal}
            toggleSubzoneModal={toggleSubzoneModal}
          />
        </Row>
      ))}
    </>
  );
}
