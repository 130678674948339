export const protectionProducts = [
  'mcb',
  'rcbo',
  'spd',
  'contactors',
  'relay',
  'distribution-board',
  'busbar',
  'changeover-switches'
];
