import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody } from 'reactstrap';

import { deleteConfirmationToast } from '../../../components/deleteConfirmationToast';
import RemoteTableWidget from '../../../components/RemoteTableWidget';
import AppContext from '../../../context/Context';
import { productApi } from '../../../helpers/api/productApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { getProductTableColumns } from '../constants/productTableColumns';
import { getConvertedEnumsFromRequest } from '../utils/getConvertedEnumsFromRequest';
import { prepareProductsListParams } from '../utils/prepareProductsListParams';
import ProductsTableHeader from './ProductsTableHeader';

const ProductsTable = ({ onClick }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterByGroup = searchParams.get('group') || '';
  const filterByDestination = searchParams.get('destination') || '';
  const page = Number(searchParams.get('page')) || 1;
  const perPage = Number(searchParams.get('perPage')) || 20;
  const search = searchParams.get('search') || '';

  const { t } = useTranslation();
  const { fetchData, setFetchData } = useContext(AppContext);

  const [data, setData] = useState({ lastPage: 0, count: 0, items: null });
  const [sort, setSort] = useState({ field: '', direction: '' });

  const setPage = (pageNumber) => {
    setSearchParams((params) => {
      params.set('page', pageNumber);
      return params;
    });
  };

  const setPerPage = (value) => {
    setSearchParams((params) => {
      params.set('perPage', value);
      return params;
    });
  };

  const deleteProduct = async (productId) => {
    try {
      await productApi.deleteProduct(productId);
      fetchProductsList();
      toast.success(t('Product deleted successfully!'));
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = (productName, productId) => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to delete product with')} ${productName} sku?`,
      onClick: () => deleteProduct(productId),
      buttonText: t('Yes, delete!')
    });
  };

  const fetchProductsList = async () => {
    const params = prepareProductsListParams({
      search,
      page,
      perPage,
      filterByGroup,
      sort,
      filterByDestination
    });

    try {
      const response = await productApi.getProducts(params);
      setData(getConvertedEnumsFromRequest(response.data));
      setFetchData(false);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  useEffect(() => {
    fetchProductsList();
  }, [page, perPage, sort, filterByGroup, filterByDestination, search]);

  useEffect(() => {
    if (fetchData) {
      fetchProductsList();
    }
  }, [fetchData]);

  const applySort = (field) => {
    if (sort.field === field) {
      setSort({ field, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ field: field, direction: 'asc' });
    }
  };

  const tableColumns = getProductTableColumns({
    applySort,
    onClick,
    showDeleteConfirmationToast,
    t
  });

  return (
    <Card className="mb-3">
      <ProductsTableHeader />
      <CardBody className="p-0">
        <RemoteTableWidget
          columns={tableColumns}
          keyField="sku"
          page={page}
          perPage={perPage}
          perPageOptions={[20, 30, 50]}
          items={data.items}
          lastPage={data.lastPage}
          onPageChange={(e) => setPage(e)}
          onPerPageChange={(e) => {
            setPage(1);
            setPerPage(e);
          }}
          rowEvents={{
            onClick: (_, row, rowIndex) => onClick(row.id, rowIndex)
          }}
          onSortChange={(field, direction) => setSort({ field, direction })}
        />
      </CardBody>
    </Card>
  );
};

export default ProductsTable;
