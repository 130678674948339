export const manageUsers = {
  en: {
    translations: {
      Users: 'Users',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Role: 'Role',
      'Add User': 'Add User',
      'Edit profile': 'Edit profile',
      'User avatar': 'User avatar',
      'Upload image': 'Upload image',
      'Upload avatar': 'Upload avatar',
      Profile: 'Profile',
      Remove: 'Remove'
    }
  },
  ro: {
    translations: {
      Users: 'Utilizatori',
      'First Name': 'Prenume',
      'Last Name': 'Nume',
      Role: 'Rol',
      'Add User': 'Adaugă un utilizator',
      'Edit profile': 'Editează profilul',
      'User avatar': 'Avatar utilizator',
      'Upload image': 'Încarcă imagine',
      'Upload avatar': 'Încarcă avatar',
      Profile: 'Profil',
      Remove: 'Șterge'
    }
  },
  de: {
    translations: {
      Users: 'Benutzer',
      'First Name': 'Vorname',
      'Last Name': 'Nachname',
      Role: 'Rolle',
      'Add User': 'Benutzer hinzufügen',
      'Edit profile': 'Profil bearbeiten',
      'User avatar': 'Benutzeravatar',
      'Upload image': 'Bild hochladen',
      'Upload avatar': 'Avatar hochladen',
      Profile: 'Profil',
      Remove: 'Entfernen'
    }
  }
};
