import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';

import { config } from '../../config';
import AppContext from '../../context/Context';
import { homeRoute } from '../../helpers/routes/routes';
import { breakpoints } from '../../helpers/utils/utils';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[config.topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[config.navbarBreakPoint] && setShowBurgerMenu(false);
  };

  return (
    <>
      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to={homeRoute.to}>
          {homeRoute.name}
        </NavLink>
      </NavItem>
    </>
  );
};

NavbarTopDropDownMenus.propTypes = {
  setNavbarCollapsed: PropTypes.func.isRequired,
  setShowBurgerMenu: PropTypes.func,
};

export default NavbarTopDropDownMenus;
