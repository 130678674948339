import z from 'zod';

export const productFormSchema = z.object({
  label: z
    .string({ required_error: 'Label is required' })
    .trim()
    .min(1, { message: 'Label is required' }),
  sku: z
    .string({ required_error: 'SKU is required' })
    .trim()
    .min(1, { message: 'SKU is required' }),
  description: z.string().optional(),
  group: z.number({
    invalid_type_error: 'Group is required.',
    required_error: 'Group is required.'
  }),
  categoryId: z.coerce.number().positive('Please select a category.'),
  manufacturerId: z.coerce.number().positive('Please select a manufacturer.'),
  manufacturerSku: z
    .string({ required_error: 'Manufacturer SKU is required' })
    .trim()
    .min(1, { message: 'Manufacturer SKU is required' }),
  destination: z.coerce.number(),
  price: z.coerce
    .number({
      invalid_type_error: 'Price is required.',
      required_error: 'Price is required.'
    })
    .positive('Price must be greater than 0.'),
  sellPrice: z.coerce
    .number({
      invalid_type_error: 'Sell price is required.',
      required_error: 'Sell price is required.'
    })
    .positive('Sell price must be greater than 0.'),
  measurementUnit: z.number({
    invalid_type_error: 'Measurement unit is required.',
    required_error: 'Measurement unit is required.'
  }),
  protectionType: z.coerce.number().optional(),
  connectionType: z.coerce.number().optional(),
  amperage: z.coerce.number().optional(),
  width: z.coerce.number().optional(),
  horizontalModules: z.coerce.number().optional(),
  verticalModules: z.coerce.number().optional(),
  mountType: z.coerce.number().optional(),
  cableThickness: z.coerce.number().optional(),
  cableColorId: z.coerce.number().optional(),
  differentialAmperage: z.coerce.number().optional(),
  differentialClass: z.string().trim().optional(),
  differentialTripCurve: z.string().trim().optional(),
  hasGrounding: z.coerce.number()
});
