import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { userApi } from '../../helpers/api/user/userApi';
import UsersList from './UsersList';

export default function UsersManagementScreen() {
  const { t } = useTranslation();
  const { toggleUserModal, fetchData, setFetchData } = useContext(AppContext);

  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const fetchUsers = async () => {
    const params = {
      page,
      perPage
    };
    try {
      const response = await userApi.getUsers(params);
      setUsers(response.data);
      setFetchData(false);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, perPage, fetchData]);

  return (
    <>
      <PageHeader
        title="Users"
        controls={() => (
          <ButtonIcon
            icon="plus"
            color="falcon-primary"
            size="sm"
            onClick={() => toggleUserModal()}
          >
            {t('Add User')}
          </ButtonIcon>
        )}
      />
      {users && (
        <UsersList
          users={users}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          onClick={(userId) => toggleUserModal(userId)}
        />
      )}
    </>
  );
}
