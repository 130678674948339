import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'reactstrap';

import AppContext from '../../../context/Context';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { userApi } from '../../../helpers/api/user/userApi';
import { userProfileEditFormSchema } from '../../../helpers/formValidations/userProfileEditFormSchema';
import PhoneNumberInputFormField from '../../../screens/auth/components/PhoneNumberInput';
import InputFormField from '../InputFormField';

export default function UserProfileForm({
  defaultValues,
  isLoading,
  setIsLoading
}) {
  const { t } = useTranslation();
  const { user, setUser, setIsProfileModalOpen } = useContext(AppContext);

  const form = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(userProfileEditFormSchema)
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues, form]);

  const handleSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await userApi.userProfileEdit(data);

      setUser({
        ...user,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        company: response.data.company,
        phoneNumber: response.data.phoneNumber
      });

      toast('Profile successfully updated!', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000
      });
      setIsProfileModalOpen(false);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <InputFormField
          isDisabled={isLoading}
          isRequired
          label="firstName"
          displayLabel="First name"
          placeholder={t('First name')}
        />
        <InputFormField
          isDisabled={isLoading}
          isRequired
          label="lastName"
          displayLabel="Last name"
          placeholder={t('Last name')}
        />
        <InputFormField
          label="company"
          displayLabel="Company name"
          placeholder={t('Company name')}
        />
        <PhoneNumberInputFormField isDisabled={isLoading} />
        <div className="d-flex justify-content-between align-items-end">
          <Button disabled={isLoading} type="submit" color="falcon-primary">
            {isLoading ? <Spinner size="sm" children="" /> : t('Save')}
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
}
