export const ProductDestination = {
  ALL: 0,
  SWITCHBOARD: 1,
  ZONE: 2,
  SUBZONE: 3,
  CONSUMER: 4,
  RELAY: 5,
  NONE: 6,
  ZONEANDSUBZONE: 7,
  ZONEANDCONSUMER: 8,
  SUBZONEANDCONSUMER: 9,
};

export const ProductDestinationText = {
  [ProductDestination.ALL]: 'All',
  [ProductDestination.SWITCHBOARD]: 'Switchboard',
  [ProductDestination.ZONE]: 'Zone',
  [ProductDestination.SUBZONE]: 'Subzone',
  [ProductDestination.CONSUMER]: 'Consumer',
  [ProductDestination.RELAY]: 'Relay',
  [ProductDestination.NONE]: 'None',
  [ProductDestination.ZONEANDSUBZONE]: 'Zone + Subzone',
  [ProductDestination.ZONEANDCONSUMER]: 'Zone + Consumer',
  [ProductDestination.SUBZONEANDCONSUMER]: 'Subzone + Consumer',
};
