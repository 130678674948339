import React from 'react';
import { Badge, Card, CardBody } from 'reactstrap';

import { ConnectionPhaseText } from '../../../../../helpers/enums/connectionPhaseEnum';

const ZoneCard = ({ zone, className, onClick }) => {
  return (
    <Card
      onClick={onClick}
      className={`${className}`}
      style={{ minHeight: '1vh' }}
      role={'button'}
    >
      <CardBody className={`d-flex flex-column justify-content-center`}>
        <h5 className={`text-center font-weight-light`}>{zone.label}</h5>

        <div
          className="pt-3 pb-3 px-3 d-flex justify-content-between"
          style={{ margin: '0 -20px -20px -20px' }}
        >
          <div className="flex-fill">
            <span className="fs--1">{`#${zone.code}`}</span>
          </div>
          <div className="d-flex align-items-center">
            <Badge
              color={zone.protection ? 'soft-primary' : 'light'}
              style={{ width: '100px' }}
              className="ml-1 text-truncate"
            >
              {zone.protection ? zone.protection.label : 'No protection'}
            </Badge>
            <Badge
              color="soft-dark"
              className="my-auto fs--1 font-weight-light ml-3"
              pill
            >
              {zone.connectionPhase
                ? ConnectionPhaseText[zone.connectionPhase].toUpperCase()
                : '3N'}
            </Badge>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ZoneCard;
