import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from '../../components/common/PageHeader';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import PowerSourcesHeaderButtons from './components/PowerSourcesHeaderButtons';
import PowerSourcesTable from './components/PowerSourcesTable';
import { Card, CardBody } from 'reactstrap';

export default function PowerSourcesScreen() {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [project, setProject] = useState({});
  const [entries, setEntries] = useState([]);
  const [originalEntries, setOriginalEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);


  const fetchProject = useCallback(async () => {
    try {
      const response = await projectApi.getProjectById(projectId);
      setProject(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [projectId]);

  // Fetch power sources (project circuits)
  const fetchProjectCircuits = useCallback(async () => {
    setLoading(true);
    try {
      const response = await projectApi.getProjectCircuits(projectId);
      const data = response.data;
      setEntries(data);
      setOriginalEntries(JSON.parse(JSON.stringify(data))); // Deep copy
      setIsModified(false); // Reset modification status
    } catch (error) {
      showErrorsFromRequest(error, t);
    } finally {
      setLoading(false);
    }
  }, [projectId, t]);

  // Save power sources (project circuits)
  const saveProjectCircuits = useCallback(async () => {
    setLoading(true);
    try {
      const body = {
        powerSources: entries,
      }
      await projectApi.saveProjectCircuitsPowerSource(projectId, body); // Save modified data
      await fetchProjectCircuits(); // Refresh to ensure consistency
    } catch (error) {
      showErrorsFromRequest(error, t);
    } finally {
      setLoading(false);
    }
  }, [projectId, entries, fetchProjectCircuits, t]);

  const handleCancelChanges = useCallback(() => {
    setEntries(JSON.parse(JSON.stringify(originalEntries))); // Revert to deep copy of original data
    setIsModified(false); // Reset modification status
  }, [originalEntries]);

  const handleEntriesChange = useCallback(
    (updatedEntries) => {
      setEntries(updatedEntries);
      // Compare current entries with original entries to detect modifications
      const isDataModified = JSON.stringify(updatedEntries) !== JSON.stringify(originalEntries);
      setIsModified(isDataModified);
    },
    [originalEntries]
  );

  useEffect(() => {
    fetchProject();
    fetchProjectCircuits();
  }, [fetchProject, fetchProjectCircuits]);

  return (
    <>
      <PageHeader
        title={`${t('PowerSources')}: ${project.name || '...'}`}
        controls={() => (
          <div className="d-flex flex-wrap justify-content-end gap-4">
            <PowerSourcesHeaderButtons
              projectId={projectId}
              onSave={() => saveProjectCircuits(entries)}
              onCancel={handleCancelChanges}
              isLoading={loading}
              isModified={isModified}
            />
          </div>
        )}
      />
      <Card>
        <CardBody className="p-0">
          <PowerSourcesTable
            originalEntries={originalEntries}
            entries={entries}
            setEntries={handleEntriesChange}
            loading={loading}
          />
        </CardBody>
      </Card>
    </>
  );
}
