import { ProjectSortBy, SortDirection } from '../enums/sortEnum';

export const prepareProjectParams = ({
  pagination,
  search,
  sortBy,
  sortDirection,
  setSearchParams
}) => {
  const params = {
    page: pagination.page,
    perPage: pagination.perPage,
    searchTerm: search,
    sortBy,
    sortDirection
  };

  if (!sortBy || isNaN(Number(sortBy))) {
    params.sortBy = ProjectSortBy.UpdatedAt;
    setSearchParams((params) => {
      params.set('sortBy', ProjectSortBy.UpdatedAt);
      return params;
    });
  }

  if (!sortDirection || isNaN(Number(sortDirection))) {
    params.sortDirection = SortDirection.Desc;
    setSearchParams((params) => {
      params.set('sortDirection', SortDirection.Desc);
      return params;
    });
  }

  return params;
};
