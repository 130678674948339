import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardFooter, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';

var notificationStyle = {
  maxHeight: '365px',
  overflowY: 'auto',
};

const notifications = [
  {
    id: 1,
    description: 'ASFhs afygw eiuahsd uags dfgqygw ekasd',
    date: '2 hours ago',
    projectId: '/hom',
  },
  {
    id: 2,
    description: 'asd fuygqw euy gakjshe c',
    date: '3 hours ago',
    projectId: '#',
  }
];

const NotificationsCard = () => {
  return notifications ? (
    <Card>
        <ListGroupItemHeading className="p-3">Notifications</ListGroupItemHeading>
        <CardBody className="p-1">
          <div style={notificationStyle}>
            <ListGroup>
              {notifications.map((notification) => (
                <ListGroupItem
                  key={notification.id}
                  tag="a"
                  href={'/projects/' + notification.projectId}
                  className="p-3 border-top-0 border-left-0 border-right-0"
                  action
                >
                  {notification.description}
                  <div className="fs--2 text-dark">{notification.date}</div>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </CardBody>
        <CardFooter className="text-muted d-flex justify-content-center">
          <Button color="link">View More</Button>
        </CardFooter>
    </Card>
  ) : (
    <div />
  );
};

NotificationsCard.propTypes = {
  notifications: PropTypes.array,
};

NotificationsCard.defaultProps = {
  notification: [],
};

export default NotificationsCard;
