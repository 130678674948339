import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import FormGroupNote from '../FormGroupNote';
import InputFormField from '../InputFormField';

export default function AliasInputField({
  aliases,
  setAliases,
  checkPermission,
  isLoading
}) {
  const { t } = useTranslation();
  const form = useFormContext();

  const onEnterPress = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      addAlias(event.target.value);
    }
  };

  const onButtonClick = () => {
    addAlias(form.getValues('alias'));
  };

  const addAlias = (value) => {
    if (value === '') return;
    setAliases([...aliases, value]);
    form.setValue('aliases', [...aliases, value], {
      shouldValidate: true
    });
    form.setValue('alias', '', { shouldValidate: true });
  };

  const removeAlias = (index) => {
    setAliases(aliases.filter((it, i) => i !== index));
    form.setValue(
      'aliases',
      aliases.filter((it, i) => i !== index)
    );
  };

  return (
    <div>
      <div className="d-flex align-items-end gap-1">
        <div className="flex-grow-1">
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
            }
            isRequired
            label="alias"
            displayLabel="Aliases"
            onKeyUp={onEnterPress}
            placeholder={t('Enter the name then Enter Key to add the alias')}
            formGroupClassName="mb-2"
          />
        </div>
        <button
          type="button"
          className="btn btn-secondary mb-2"
          onClick={onButtonClick}
        >
          +
        </button>
      </div>
      {form.formState.errors.aliases && (
        <FormGroupNote type={'warning'}>
          {form.formState.errors.aliases.message}
        </FormGroupNote>
      )}
      {aliases.length > 0 && (
        <div className="d-flex flex-wrap gap-2 mb-1">
          {aliases &&
            aliases.map((alias, index) => (
              <Badge
                color="dark"
                key={index}
                role="button"
                onClick={() => removeAlias(index)}
              >
                {alias} &nbsp; <FontAwesomeIcon icon="times" />
              </Badge>
            ))}
        </div>
      )}
    </div>
  );
}
