import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';
import CardHeader from 'reactstrap/es/CardHeader';

export default function ManageAccessCardHeader({
  isEditMode,
  setIsEditMode,
  roles,
  selectedRole,
  setSelectedRole,
  submitRef,
}) {
  const alterRoles = () => {
    if (roles.length) {
      return roles.map((role) => ({
        value: role.id,
        label: role.name,
      }));
    }
  };

  return (
    <CardHeader>
      <Row className="align-items-center mr-2">
        <Col>
          <div className="d-flex align-items-center">
            <span className="mr-3">Privileges</span>
            <Select
              id="rolesSelect"
              name="rolesSelect"
              options={alterRoles()}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              onChange={(option) => setSelectedRole(option)}
              isClearable
              placeholder="Select role"
              styles={{
                menu: (base) => ({
                  ...base,
                  width: 'max-content',
                  minWidth: '100%',
                }),
              }}
            />
          </div>
        </Col>
        <Col>
          {selectedRole && (
            <div className="d-flex justify-content-end">
              {!isEditMode ? (
                <Button color="primary" size="sm" onClick={() => setIsEditMode(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              ) : (
                <>
                  <div className="mr-2">
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => {
                        if (submitRef.current) submitRef.current.click();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <div>
                    <Button color="danger" size="sm" onClick={() => setIsEditMode(false)}>
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </Col>
      </Row>
    </CardHeader>
  );
}
