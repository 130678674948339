export const auth = {
  en: {
    translations: {
      'Password Reset': 'Password Reset',
      'Admin Area': 'Admin Area',
      'Logged in as': 'Logged in as',
      Password: 'Password',
      'Remember me': 'Remember me',
      'Forgot password?': 'Forgot password?',
      Login: 'Login',
      Logout: 'Logout',
      'New password': 'New password',
      'Confirm new password': 'Confirm new password',
      'Email address': 'Email address',
      'Reset Password': 'Reset Password',
      Register: 'Register',
      'Invalid code!': 'Invalid code!',
      'Registration successful!': 'Registration successful!',
      'First name': 'First name',
      'Last name': 'Last name',
      'Confirm Password': 'Confirm Password',
      'Company name': 'Company name',
      'Phone number': 'Phone number',
      'Refresh code': 'Refresh code',
      'Please enter the code': 'Please enter the code'
    }
  },
  ro: {
    translations: {
      'Password Reset': 'Resetare Parolă',
      'Admin Area': 'Zona de Administrare',
      'Logged in as': 'Autentificat ca',
      Password: 'Parolă',
      'Remember me': 'Ține-mă minte',
      'Forgot password?': 'Ai uitat parola?',
      Login: 'Autentificare',
      Logout: 'Deconectare',
      'New password': 'Parolă nouă',
      'Confirm new password': 'Confirmă parola nouă',
      'Email address': 'Adresa de email',
      'Reset Password': 'Resetare Parolă',
      Register: 'Înregistrare',
      'Invalid code!': 'Cod invalid!',
      'Registration successful!': 'Înregistrare reușită!',
      'First name': 'Prenume',
      'Last name': 'Nume',
      'Confirm Password': 'Confirmă Parola',
      'Company name': 'Numele Companiei',
      'Phone number': 'Număr de telefon',
      'Refresh code': 'Schimbă codul',
      'Please enter the code': 'Vă rugăm să introduceți codul'
    }
  },
  de: {
    translations: {
      'Password Reset': 'Passwort zurücksetzen',
      'Admin Area': 'Admin-Bereich',
      'Logged in as': 'Eingeloggt als',
      Password: 'Passwort',
      'Remember me': 'Erinnere dich an mich',
      'Forgot password?': 'Passwort vergessen?',
      Login: 'Anmeldung',
      Logout: 'Ausloggen',
      'New password': 'Neues Passwort',
      'Confirm new password': 'Bestätigen Sie das neue Passwort',
      'Email address': 'E-Mail-Addresse',
      'Reset Password': 'Passwort zurücksetzen',
      Register: 'Registrieren',
      'Invalid code!': 'Ungültiger Code!',
      'Registration successful!': 'Registrierung erfolgreich!',
      'First name': 'Vorname',
      'Last name': 'Nachname',
      'Confirm Password': 'Bestätige das Passwort',
      'Company name': 'Firmenname',
      'Phone number': 'Telefonnummer',
      'Refresh code': 'Code aktualisieren',
      'Please enter the code': 'Bitte geben Sie den Code ein'
    }
  }
};
