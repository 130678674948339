import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../components/common/requiredStarLabel';
import EntitySelect from '../../../../components/entitySelect/EntitySelect';
import FormGroupNote from '../../../../components/form/FormGroupNote';
import { productApi } from '../../../../helpers/api/productApi';

export default function ContactorProductSelect({ isRequired, isDisabled }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const inputName = 'contactorId';

  return (
    <FormGroup>
      <Label htmlFor={inputName}>
        {t('Contactor')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={inputName}
        control={control}
        render={({ field }) => (
          <EntitySelect
            name={inputName}
            displayName={t('Contactor')}
            fieldValue={field.value}
            onChange={field.onChange}
            placeholder="Not specified"
            fetchDataFunction={() => {
              return productApi
                .getProducts({ categorySlug: 'contactors' })
                .then((res) => ({
                  data: res?.data?.items || []
                }));
            }}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.contactorId && (
        <FormGroupNote type="warning">
          {errors.contactorId.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
