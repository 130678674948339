import z from 'zod';

export const x7FormSchema = z.object({
  label: z
    .string({
      invalid_type_error: 'Label is required',
      required_error: 'Label is required'
    })
    .min(1, {
      message: 'Label is required'
    }),
  index: z.number({
    invalid_type_error: 'Index is required',
    required_error: 'Index is required'
  }),
  type: z.coerce.number({
    invalid_type_error: 'Type is required',
    required_error: 'Type is required'
  })
});
