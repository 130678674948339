import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../context/Context';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { userApi } from '../../helpers/api/user/userApi';
import { UserRole } from '../../helpers/enums/userRoleEnum';
import UserManagementForm from '../form/UserManagementForm';
import SideModal from './SideModal';

export default function UserManagementModal() {
  const { t } = useTranslation();
  const { isOpenUserModal, toggleUserModal, editUserId } =
    useContext(AppContext);

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const fetchUser = useCallback(async () => {
    if (!editUserId) {
      setUser(null);
      return;
    }

    setIsLoading(true);

    try {
      const response = await userApi.getUserById(editUserId);

      let newData = {
        userEmail: response.data.email ?? '',
        firstName: response.data.firstName ?? '',
        lastName: response.data.lastName ?? '',
        userRole: response.data.role ?? null,
        projectIds: response.data.projectIds || [],
        company: response.data.company ?? '',
        phoneNumber: response.data.phoneNumber ?? ''
      };

      if (
        response.data.clientIds.length === 1 &&
        response.data.role === UserRole.CLIENT
      ) {
        newData.clientId = response.data.clientIds[0];
      } else {
        newData.clientIds = response.data.clientIds;
      }

      setUser(newData);
    } catch (error) {
      showErrorsFromRequest(error, t);
      setError('Unable to retrieve user information.');
    }

    setIsLoading(false);
  }, [editUserId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <SideModal
      isOpen={isOpenUserModal}
      toggle={toggleUserModal}
      title="User Management Form"
      subtitle={editUserId ? 'Edit user' : 'Create a new user'}
      icon="edit"
    >
      {!error && (
        <UserManagementForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          user={user}
          userId={editUserId}
        />
      )}
      {error && <p>{error}</p>}
    </SideModal>
  );
}
