/**
 * Application related configuration.
 */
export const config = {
  version: '0.7.2',
  navbarBreakPoint: 'xl',
  topNavbarBreakpoint: 'lg',
  currency: '$',
  rootApiUrl: process.env.REACT_APP_ROOT_API_URL
};

/**
 * Styling settings. This settings can be override by the local storage.
 */
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
  isAuthenticated: null,
  user: null
};

const configurations = { config, settings };

export default configurations;
