import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ButtonIcon from '../../../components/common/ButtonIcon';

export default function X7HeaderButtons({ projectId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-end flex-wrap gap-4">
      <ButtonIcon
        icon={['fa', 'arrow-left']}
        color="falcon-default"
        className="rounded-capsule text-nowrap"
        size="md"
        onClick={() => navigate(`/projects/${projectId}`)}
      >
        {t('Back to project')}
      </ButtonIcon>
      <ButtonIcon
        icon={['fa', 'arrow-left']}
        color="falcon-default"
        className="rounded-capsule text-nowrap"
        size="md"
        onClick={() => navigate(`/projects/${projectId}/switchboard`)}
      >
        {t('Switchboard')}
      </ButtonIcon>
    </div>
  );
}
