import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CardDeck, Col, Row } from 'reactstrap';

import ButtonIcon from '../../components/common/ButtonIcon';
import NotificationsCard from '../../components/NotificationsCard';
import ProjectsColumns from '../../components/project/ProjectsColumns';
import AppContext from '../../context/Context';
import { dashboardApi } from '../../helpers/api/dashboardApi';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import { prepareProjectParams } from '../../helpers/utils/prepareProjectParams';
import CheckUserPermissions from '../../hoc/checkUserPermissions';
import ProductivityCard from './components/ProductivityCard';
import SummaryCard from './components/SummaryCard';

const DashboardScreen = () => {
  const { toggleProjectModal, fetchData, setFetchData } =
    useContext(AppContext);
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = searchParams.get('sortBy');
  const sortDirection = searchParams.get('sortDirection');

  const [pagination, setPagination] = useState({
    count: 0,
    total: 0,
    page: 1,
    lastPage: 0,
    perPage: 4,
    perPageOptions: [4, 8, 12, 16]
  });

  const [isLoading, setIsLoading] = useState(true);
  const [dashboardStatistics, setDashboardStatistics] = useState(null);
  const [projects, setProjects] = useState([]);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const fetchDashboardStatistics = useCallback(async () => {
    try {
      const response = await dashboardApi.getDashboardStatistics();
      setDashboardStatistics(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = prepareProjectParams({
        pagination,
        search: debouncedSearchValue,
        sortBy,
        sortDirection,
        setSearchParams
      });

      const response = await projectApi.getProjects(params);
      setProjects(response.data.items);
      setPagination({
        ...pagination,
        count: response.data.count,
        lastPage: response.data.lastPage,
        page: response.data.page,
        perPage: response.data.perPage,
        total: response.data.total
      });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [
    pagination.page,
    pagination.perPage,
    t,
    sortDirection,
    sortBy,
    debouncedSearchValue
  ]);

  useEffect(() => {
    fetchDashboardStatistics();
  }, [fetchDashboardStatistics]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (fetchData) {
      setFetchData(false);
      fetchProjects();
    }
  }, [fetchData, setFetchData, fetchProjects]);

  const handleSearch = (value) => {
    setPagination({
      ...pagination,
      page: 1
    });

    setDebouncedSearchValue(value);
  };

  return (
    <>
      <Row>
        <Col className="mb-xl-0 mb-4">
          {dashboardStatistics && (
            <CardDeck>
              <SummaryCard
                title="Clients waiting"
                value={String(dashboardStatistics.clientsWaiting)}
              />
              <SummaryCard
                title="Ongoing Projects"
                value={String(dashboardStatistics.ongoingProjects)}
              />
              <SummaryCard
                title="Completed Projects"
                value={String(dashboardStatistics.completedProjects)}
              />
            </CardDeck>
          )}

          <Row className="mb-3 mt-4">
            <Col>
              <h4>
                <Trans>Latest Projects</Trans>
              </h4>
            </Col>
            <Col className="text-right">
              <CheckUserPermissions
                permissions={[PermissionType.PROJECTS.MODIFY]}
              >
                <ButtonIcon
                  icon="plus"
                  color="falcon-primary"
                  size="sm"
                  onClick={() => toggleProjectModal()}
                >
                  <Trans>Add Project</Trans>
                </ButtonIcon>
              </CheckUserPermissions>
            </Col>
          </Row>

          <ProjectsColumns
            projects={projects}
            projectCardRowProps={{
              xs: 1,
              md: 2,
              lg: 2
            }}
            pagination={pagination}
            setPagination={setPagination}
            sortRowProps={{
              className:
                'd-flex align-items-end mb-3 justify-content-center justify-content-sm-between flex-wrap gap-2'
            }}
            handleSearch={handleSearch}
            isLoading={isLoading}
          />
        </Col>

        <Col xxl="3" xl="4" lg="12" md="12" s="12">
          <Row>
            <div className="col-xxl-12 col-xl-12 col-lg-6 mb-4">
              {dashboardStatistics && (
                <ProductivityCard
                  completedProjects={dashboardStatistics.completedProjects}
                  monthlyCompletedProjects={
                    dashboardStatistics.monthlyCompletedProjects
                  }
                />
              )}
            </div>
            <div
              className="col-xxl-12 col-xl-12 col-lg-6"
              style={{ display: 'none' }}
            >
              <NotificationsCard />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DashboardScreen;
