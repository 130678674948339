export const dashboard = {
  en: {
    translations: {
      'Clients waiting': 'Clients waiting',
      'Ongoing Projects': 'Ongoing Projects',
      'Completed Projects': 'Completed Projects',
      'Latest Projects': 'Latest Projects',
      'Add Project': 'Add Project',
      'Last Update': 'Last Update',
      Productivity: 'Productivity',
      'Monthly completed projects': 'Monthly completed projects'
    }
  },
  ro: {
    translations: {
      'Clients waiting': 'Clienti in asteptare',
      'Ongoing Projects': 'Proiecte in desfasurare',
      'Completed Projects': 'Proiecte finalizate',
      'Latest Projects': 'Ultimele proiecte',
      'Add Project': 'Adauga un proiect',
      'Last Update': 'Ultima actualizare',
      Productivity: 'Productivitate',
      'Monthly completed projects': 'Proiecte finalizate lunar'
    }
  },
  de: {
    translations: {
      'Clients waiting': 'Wartende Kunden',
      'Ongoing Projects': 'Laufende Projekte',
      'Completed Projects': 'Abgeschlossene Projekte',
      'Latest Projects': 'Neueste Projekte',
      'Add Project': 'Projekt hinzufügen',
      'Last Update': 'Letztes Update',
      Productivity: 'Produktivität',
      'Monthly completed projects': 'Monatlich abgeschlossene Projekte'
    }
  }
};
