import { useFormContext } from 'react-hook-form';

import { systemProductCategorySlug } from '../../../../../constants/systemProductCategorySlug';
import GenericSwitchboardSettingsProductSelect from './GenericSwitchboardSettingsProductSelect';

export default function MeterBoxSelect({ isDisabled, isRequired = false }) {
  const { watch } = useFormContext();

  const horizontalModules = watch('horizontalModulesPerRow');

  const params = {
    horizontalModules,
    categorySlug: systemProductCategorySlug.MeterBox
  };

  return (
    <GenericSwitchboardSettingsProductSelect
      isDisabled={isDisabled}
      isRequired={isRequired}
      displayName="Meter Box"
      inputName="counter"
      params={params}
    />
  );
}
