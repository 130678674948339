import { Col, Row } from 'reactstrap';

export default function TermsAndConditionsScreen() {
  return (
    <Row>
      <Col>
        <Row>
          <p className="font-weight-bold fs-4">Terms and Conditions</p>
        </Row>
        <Row>
          <Col>
            <div>
              {/* TODO: add content */}
              <p>Content goes here</p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
