import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import { deleteConfirmationToast } from '../../../../components/deleteConfirmationToast';
import { offersApi } from '../../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { isIterableArray } from '../../../../helpers/utils/utils';

const DynamicEntriesTable = ({
  entries,
  onEdit,
  refreshEntries,
  profileId
}) => {
  const { t } = useTranslation();
  let table = createRef();

  const showDeleteConfirmationToast = (entryId) => {
    deleteConfirmationToast({
      title: 'Confirm entry deletion?',
      onClick: () => deleteEntry(entryId),
      buttonText: 'Yes, proceed!'
    });
  };

  const actionFormatter = (dataField, { id }) => (
    <UncontrolledDropdown>
      <DropdownToggle
        color="link"
        size="sm"
        className="text-600 btn-reveal mr-3"
      >
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        <DropdownItem onClick={() => onEdit(id)}>Edit</DropdownItem>
        <DropdownItem
          onClick={() => {
            showDeleteConfirmationToast(id);
          }}
          className="text-danger"
        >
          {t('Delete')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const expressionFormatter = (expression) => (
    <code
      style={{
        background: '#eee',
        display: 'block',
        padding: '2px 5px',
        borderRadius: '5px',
        maxWidth: '200px',
        wordWrap: 'break-word'
      }}
    >
      {expression}
    </code>
  );

  const columns = [
    {
      dataField: 'label',
      text: t('Label'),
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'productLabel',
      headerClasses: 'border-0',
      text: t('Product'),
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'priceExpression',
      headerClasses: 'border-0',
      text: t('Price'),
      formatter: expressionFormatter,
      classes: 'border-0 py-2 align-start'
    },
    {
      dataField: 'taxExpression',
      headerClasses: 'border-0',
      text: t('Tax'),
      formatter: expressionFormatter,
      classes: 'border-0 py-2 align-start'
    },
    {
      dataField: 'discountExpression',
      headerClasses: 'border-0',
      text: `${t('Discount')} %`,
      formatter: expressionFormatter,
      classes: 'border-0 py-2 align-start'
    },
    {
      dataField: 'quantityExpression',
      headerClasses: 'border-0',
      text: t('Quantity'),
      formatter: expressionFormatter,
      classes: 'border-0 py-2 align-start'
    },

    {
      dataField: '',
      headerClasses: 'border-0',
      text: '',
      classes: 'border-0 py-2 align-middle',
      formatter: actionFormatter,
      align: 'right'
    }
  ];

  const deleteEntry = async (id) => {
    try {
      await offersApi.deleteDynamicEntry(profileId, id);
      toast.success(t('Entry has been successfully deleted!'));
      refreshEntries();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <>
      {isIterableArray(entries) ? (
        <>
          <div className="table-responsive">
            <BootstrapTable
              ref={table}
              bootstrap4
              keyField="label"
              data={entries}
              columns={columns}
              bordered={false}
              classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger border-top border-200"
              headerClasses="bg-200 text-900 border-y border-200"
              wrapperClasses="table-responsive"
            />
          </div>
        </>
      ) : (
        <p className="p-card mb-0 bg-light">
          {t('There are no dynamic entries, go ahead and add a new one.')}
        </p>
      )}
    </>
  );
};

export default DynamicEntriesTable;
