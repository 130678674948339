import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import ProjectsColumns from '../../components/project/ProjectsColumns';
import AppContext from '../../context/Context';
import { projectApi } from '../../helpers/api/projectApi';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import { prepareProjectParams } from '../../helpers/utils/prepareProjectParams';
import CheckUserPermissions from '../../hoc/checkUserPermissions';

const ProjectsScreen = () => {
  const { t } = useTranslation();
  const { toggleProjectModal, fetchData, setFetchData } =
    useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const sortBy = searchParams.get('sortBy');
  const sortDirection = searchParams.get('sortDirection');

  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    total: 0,
    page: 1,
    lastPage: 0,
    perPage: 9,
    perPageOptions: [9, 18, 27, 36]
  });
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const fetchProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = prepareProjectParams({
        pagination,
        sortDirection,
        setSearchParams,
        sortBy,
        search: debouncedSearchValue
      });

      const response = await projectApi.getProjects(params);
      setProjects(response.data.items);
      setPagination({
        ...pagination,
        count: response.data.count,
        total: response.data.total,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage
      });
    } catch (error) {
      toast.error(t('Unable to retrieve projects'));
    }
    setIsLoading(false);
  }, [
    pagination.page,
    pagination.perPage,
    sortBy,
    sortDirection,
    t,
    debouncedSearchValue
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (fetchData) {
      setFetchData(false);
      fetchProjects();
    }
  }, [fetchData, setFetchData, fetchProjects]);

  const handleSearch = (value) => {
    setPagination({
      ...pagination,
      page: 1
    });

    setDebouncedSearchValue(value);
  };

  return (
    <>
      <PageHeader
        title="Projects"
        controls={() => (
          <CheckUserPermissions permissions={[PermissionType.PROJECTS.MODIFY]}>
            <ButtonIcon
              icon="plus"
              color="falcon-primary"
              size="sm"
              onClick={() => toggleProjectModal()}
            >
              {t('Add Project')}
            </ButtonIcon>
          </CheckUserPermissions>
        )}
      />
      <ProjectsColumns
        projects={projects}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        sortRowProps={{
          className:
            'd-flex align-items-end mb-3 justify-content-sm-between justify-content-center flex-wrap gap-2'
        }}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProjectsScreen;
