import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { getDefaultSelectValue } from '../../helpers/utils/getDefaultSelectValue';

export const InputStates = {
  Loading: 0,
  Loaded: 1,
  Error: 2
};

export default function EntitySelect({
  name,
  displayName,
  fieldValue,
  onChange,
  placeholder,
  fetchDataFunction,
  isFetchDataEnum = false,
  enumToText,
  isDisabled,
  isFetchData,
  setFetchData,
  setInputState,
  isClearable = true
}) {
  const { t } = useTranslation();
  const [state, setState] = useState(InputStates.Loading);
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const setError = (message) => {
    setState(InputStates.Error);
    setErrorMessage(message);
  };

  const fetchData = async () => {
    setState(InputStates.Loading);
    try {
      const response = await fetchDataFunction();
      if (response.data.length === 0) {
        setError(t('Not found entity error')(displayName || name));
        return;
      }

      let newOptions;

      if (isFetchDataEnum) {
        newOptions = response.data.map((option) => {
          return { value: option, label: t(enumToText[option]) };
        });
      } else {
        newOptions = response.data.map((option) => {
          return { value: option.id, label: t(option.name || option.label) };
        });
      }

      setOptions(newOptions);
      setState(InputStates.Loaded);
    } catch (error) {
      setError(t('Could not load')(displayName || name));
    }
  };

  useLayoutEffect(() => {
    fetchData();

    return () => {
      setState(InputStates.Loading);
      setOptions([]);
      setErrorMessage(null);
    };
  }, []);

  useLayoutEffect(() => {
    if (isFetchData) {
      fetchData();
      setFetchData(false);
    }
  }, [isFetchData]);

  const handleOnChange = (option) => {
    onChange(option ? option.value : null);
  };

  useLayoutEffect(() => {
    if (setInputState) {
      setInputState(state);
    }
  }, [errorMessage, setInputState, state]);

  if (InputStates.Error === state) {
    return <div>{errorMessage}</div>;
  }

  return (
    <>
      <Select
        id={name}
        name={name}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onChange={handleOnChange}
        placeholder={
          InputStates.Loading === state ? t('Loading...') : t(placeholder)
        }
        value={getDefaultSelectValue(fieldValue, options)}
        isSearchable
        isClearable={isClearable}
        className={`${errorMessage ? 'is-invalid' : ''} w-100`}
        isDisabled={isDisabled || InputStates.Loading === state}
      />
    </>
  );
}
