import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import { clientApi } from '../../../helpers/api/clientApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import RequiredStarLabel from '../../common/requiredStarLabel';
import FormGroupNote from '../FormGroupNote';

export default function UserClientsSelect({ isDisabled, isRequired }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchClients = async () => {
    let currentPage = 1;
    let fetchedClients = [];
    setIsLoading(true);
    while (true) {
      try {
        const response = await clientApi.getClients({
          perPage: 1000,
          page: currentPage
        });
        const { items, page, lastPage } = response.data;

        fetchedClients = [...fetchedClients, ...items];
        currentPage++;

        if (lastPage === 0 || page === lastPage) {
          break;
        }
      } catch (error) {
        showErrorsFromRequest(error, t);
        break;
      }
    }

    fetchedClients = fetchedClients.map((client) => {
      return { value: client.id, label: client.name };
    });

    setOptions(fetchedClients);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const getDefaultValues = (fieldValue) => {
    if (options.length === 0 || !fieldValue) {
      return null;
    }

    return options.filter((p) => {
      return fieldValue.includes(p.value);
    });
  };

  return (
    <FormGroup>
      <Label htmlFor="clientIds">
        Select clients {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="clientIds"
        control={control}
        render={({ field }) => (
          <Select
            id="clientIds"
            name="clientIds"
            isMulti
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(selectedOption) => {
              field.onChange(
                selectedOption ? selectedOption.map((o) => o.value) : []
              );
            }}
            isDisabled={isDisabled || isLoading}
            isSearchable
            isClearable
            value={getDefaultValues(field.value)}
          />
        )}
      />
      {errors.clientIds && (
        <FormGroupNote type="warning">{errors.clientIds.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
