import React from 'react';

import SubzoneModalForm from '../form/SubzoneModalForm';
import CardModal from './CardModal';

const SubzoneModal = ({
  isOpen,
  toggle,
  subzone,
  switchboard,
  refreshSwitchboard
}) => {
  const isEdit =
    typeof subzone === 'object' &&
    subzone !== null &&
    Number.isInteger(subzone.id) &&
    subzone.id >= 0;

  const subzones = switchboard.subzones(subzone.zoneId);

  const defaultValues = {
    index: subzone.index ?? subzones.length + 1,
    label: subzone.label ?? '',
    protection: subzone.protection ?? null
  };

  return (
    <CardModal
      title={isEdit ? `Subzone ${subzone.code}` : 'Subzone'}
      subtitle={isEdit ? `Editing '${subzone.label}'` : 'Create a new subzone'}
      isOpen={isOpen}
      toggleModal={toggle}
    >
      <SubzoneModalForm
        defaultValues={defaultValues}
        isOpen={isOpen}
        subzone={subzone}
        toggle={toggle}
        switchboard={switchboard}
        refreshSwitchboard={refreshSwitchboard}
        isEdit={isEdit}
        subzones={subzones}
      />
    </CardModal>
  );
};

export default SubzoneModal;
