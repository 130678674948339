import { useContext, useEffect, useState } from 'react';

import AppContext from '../context/Context';

export default function CheckUserPermissions({ children, permissions }) {
  const { user } = useContext(AppContext);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (user && user.permissions) {
      setUserPermissions(user.permissions);
    }
  }, [user]);

  if (user?.isAdmin) {
    return children;
  }

  const hasAnyPermission = () => {
    return permissions && permissions.some((permission) => userPermissions.includes(permission));
  };

  if (hasAnyPermission()) {
    return children;
  }

  return null;
}
