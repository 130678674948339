import axios from '../axios/axios.service';

export const cableColorsApi = {
  getCableColors: async () => await axios.get('/cable-colors'),
  getCableColor: async (id) => await axios.get(`/cable-colors/${id}`),
  addCableColor: async (data) => await axios.post('/cable-colors', data),
  updateCableColor: async (id, data) =>
    await axios.put(`/cable-colors/${id}`, data),
  deleteCableColor: async (id) => await axios.delete(`/cable-colors/${id}`)
};
