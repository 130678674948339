import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';

import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { roleApi } from '../../helpers/api/user/roleApi';
import ManageAccessCardHeader from './components/ManageAccessCardHeader';
import ManageAccessForm from './components/ManageAccessForm';

export default function ManageAccess() {
  const { t } = useTranslation();
  const { user, setFetchData, fetchData } = useContext(AppContext);

  const [roles, setRoles] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});

  const submitRef = useRef();

  const getRoles = useCallback(async () => {
    try {
      const response = await roleApi.getRoles({ includeAdmin: false });
      setRoles(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
      setRoles([]);
    }
  }, []);

  const getPermissions = useCallback(async () => {
    try {
      const response = await roleApi.getPermissions();
      setPermissions(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
      setPermissions([]);
    }
  }, []);

  const getRolePermissions = async (id) => {
    try {
      const response = await roleApi.getRolePermissions(id);
      setSelectedRolePermissions(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
      setSelectedRolePermissions([]);
    }
  };

  const getAdminDefaultValues = useCallback(() => {
    const adminDefaultValues = {};
    permissions.forEach((permission) => {
      permission.permissions.forEach((p) => {
        adminDefaultValues[p.value.replace(/\./g, '_')] = true;
      });
    });
    setDefaultValues({
      permissions: adminDefaultValues
    });
  }, [permissions]);

  const getUserDefaultValues = useCallback(() => {
    const userDefaultValues = {};
    selectedRolePermissions.forEach((permission) => {
      userDefaultValues[permission.replace(/\./g, '_')] = true;
    });
    setDefaultValues({
      permissions: userDefaultValues
    });
  }, [selectedRolePermissions]);

  useEffect(() => {
    getRoles();
    getPermissions();
  }, [getPermissions, getRoles]);

  useEffect(() => {
    if (fetchData) {
      getRolePermissions(selectedRole.value);
      setFetchData(false);
    }
  }, [fetchData]);

  useEffect(() => {
    if (!selectedRole) {
      setIsEditMode(false);
    } else {
      getRolePermissions(selectedRole.value);
    }
  }, [selectedRole]);

  useEffect(() => {
    if (user) {
      if (user.isAdmin && !selectedRole) {
        getAdminDefaultValues();
      } else {
        getUserDefaultValues();
      }
    }
  }, [getAdminDefaultValues, getUserDefaultValues, selectedRole, user]);

  return (
    <>
      {user && user.isAdmin && (
        <PageHeader
          title="Manage Access"
          controls={() => (
            <span className="fs-2">
              Your role: <span className="font-weight-bold">Admin</span>
            </span>
          )}
        />
      )}
      <Card>
        <ManageAccessCardHeader
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          roles={roles}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          submitRef={submitRef}
        />
        <CardBody className="px-5">
          <ManageAccessForm
            selectedRole={selectedRole}
            isEditMode={isEditMode}
            permissions={permissions}
            defaultValues={defaultValues}
            submitRef={submitRef}
            setIsEditMode={setIsEditMode}
          />
        </CardBody>
      </Card>
    </>
  );
}
