import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import RequiredStarLabel from '../../common/requiredStarLabel';
import FormGroupNote from '../FormGroupNote';

export default function UserProjectsSelect({ isDisabled, isRequired }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjects = async () => {
    let currentPage = 1;
    let fetchedProjects = [];
    setIsLoading(true);
    while (true) {
      try {
        const response = await projectApi.getProjects({
          page: currentPage,
          perPage: 1000
        });
        const { items, page, lastPage } = response.data;

        fetchedProjects = [...fetchedProjects, ...items];
        currentPage++;

        if (lastPage === 0 || page === lastPage) {
          break;
        }
      } catch (error) {
        showErrorsFromRequest(error, t);
        break;
      }
    }
    fetchedProjects = fetchedProjects.map((project) => ({
      value: project.id,
      label: project.name
    }));

    setOptions(fetchedProjects);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const getDefaultValues = (fieldValue) => {
    if (options.length === 0 || !fieldValue) {
      return null;
    }

    return options.filter((p) => {
      return fieldValue.includes(p.value);
    });
  };

  return (
    <FormGroup>
      <Label htmlFor="projectIds">
        Select projects {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="projectIds"
        control={control}
        render={({ field }) => (
          <Select
            id="projectIds"
            name="projectIds"
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(selectedOption) => {
              field.onChange(
                selectedOption ? selectedOption.map((o) => o.value) : []
              );
            }}
            isDisabled={isDisabled || isLoading}
            isSearchable
            isClearable
            value={getDefaultValues(field.value)}
            isMulti
          />
        )}
      />
      {errors.projectIds && (
        <FormGroupNote type="warning">
          {errors.projectIds.id.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
