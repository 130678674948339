import axios from '../../../helpers/axios/axios.service';

export const switchboardZoneApi = {
  addZone: async (projectId, data) => {
    return await axios.post(`/projects/${projectId}/switchboard/zone`, data);
  },
  updateZone: async (projectId, zoneId, data) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/zone/${zoneId}`,
      data
    );
  },
  deleteZone: async (projectId, zoneId) => {
    return await axios.delete(
      `/projects/${projectId}/switchboard/zone/${zoneId}`
    );
  },
  restoreZone: async (projectId, zoneId) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/zone/${zoneId}/restore`
    );
  }
};
