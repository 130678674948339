import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';

import RequiredStarLabel from '../../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../../components/form/FormGroupNote';

export default function IsAllIncludedCheckbox({ isDisabled, isRequired }) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const inputName = 'isAllIncluded';

  return (
    <FormGroup className="d-flex align-items-center">
      <Label htmlFor={inputName} className="m-0 mr-2">
        {t('Is all included')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        control={control}
        name={inputName}
        render={({ field }) => (
          <Input
            id={inputName}
            type="checkbox"
            {...field}
            checked={!!field.value}
            disabled={isDisabled}
            className="m-0 position-static"
          />
        )}
      />
      {errors.isAllIncluded && (
        <FormGroupNote type="warning">
          {errors.isAllIncluded.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}
