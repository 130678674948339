import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader } from 'reactstrap';

import ScrollBarCustom from '../common/ScrollBarCustom';

const SideModal = ({ children, title, subtitle, icon, isOpen, toggle }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      modalClassName="overflow-hidden modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader tag="div" toggle={toggle} className="modal-header-settings">
        <div className="py-1 flex-grow-1">
          <h5 className="text-white">
            {icon && <FontAwesomeIcon icon={icon} className="mr-2 fs-0" />}
            {t(title)}
          </h5>
          {subtitle && (
            <p className="mb-0 fs--1 text-white opacity-75">{t(subtitle)}</p>
          )}
        </div>
      </ModalHeader>

      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          renderer: ({ elementRef, ...restParams }) => (
            <span
              {...restParams}
              ref={elementRef}
              className={classNames('p-card position-absolute border-left')}
            />
          )
        }}
      >
        {children}
      </ScrollBarCustom>
    </Modal>
  );
};

SideModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

SideModal.defaultProps = {
  isOpen: false,
  toggleModal: () => {}
};

export default SideModal;
