import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

export default function ProductsFilterSelect({
  selectId,
  onChange,
  options,
  defaultValue,
  value
}) {
  const { t } = useTranslation();

  return (
    <Input
      type="select"
      id={selectId}
      style={{ width: 'fit-content' }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">{t(defaultValue)}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
}
