import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

const IndexSelect = ({ maxIndex, defaultValue, onChange, isDisabled }) => {
  maxIndex = maxIndex >= 1 ? maxIndex : 1;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const changeValue = (newValue) => {
    if (newValue < 1) newValue = 1;
    if (newValue > maxIndex) newValue = maxIndex;
    if (newValue === value) return;

    setValue(newValue);
    onChange(newValue);
  };

  const isChanged = () => value !== defaultValue;
  const changedUp = () => value - defaultValue;
  const isChangedUp = () => changedUp() > 0;
  const changedDown = () => defaultValue - value;
  const isChangedDown = () => changedDown() > 0;

  return (
    <div className="form-control d-flex justify-content-between">
      <div className={`mr-3 ${isChanged() ? 'text-black' : 'text-500'}`}>
        {value || 'N/A'}
      </div>
      <div
        className="btn-group"
        style={{ margin: '-0.32rem -1rem -0.32rem 0' }}
      >
        <Button
          type="button"
          color="light"
          className="stretched-link"
          onClick={() => changeValue(value - 1)}
          disabled={value === 1 || isDisabled}
        >
          - {isChangedDown() && changedDown()}
        </Button>
        <Button
          type="button"
          color="light"
          className="stretched-link"
          onClick={() => changeValue(value + 1)}
          disabled={value >= maxIndex || isDisabled}
        >
          +{isChangedUp() && changedUp()}
        </Button>
      </div>
    </div>
  );
};

export default IndexSelect;
