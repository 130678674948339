import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileDownload from 'js-file-download';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import { productsExportApi } from '../../helpers/api/productsExportApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import ProductsTable from './components/ProductsTable';
import ProductsImportModal from './ProductsImportModal';

const ProductsScreen = () => {
  const { t } = useTranslation();
  const { toggleProductModal } = useContext(AppContext);
  const [isImporterModalOpen, setIsImporterModalOpen] = useState(false);

  const toggleImporterModal = () => {
    setIsImporterModalOpen(!isImporterModalOpen);
  };

  const handleExport = async () => {
    try {
      const response = await productsExportApi.exportProducts();
      fileDownload(response.data, 'products.csv');
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <>
      <PageHeader
        title="Products"
        controls={() => (
          <div className="d-flex justify-content-end">
            <ButtonIcon
              onClick={() => toggleProductModal()}
              icon="plus"
              size="sm"
              className="mr-2"
              color="falcon-primary"
            >
              {t('Add Product')}
            </ButtonIcon>
            <UncontrolledDropdown>
              <DropdownToggle
                color="falcon-primary"
                size="sm"
                className="text-600 btn-reveal"
              >
                <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
              </DropdownToggle>
              <DropdownMenu right className="border px-2">
                <DropdownItem onClick={handleExport}>Export CSV</DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={toggleImporterModal}
                >
                  Import CSV
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      />
      <ProductsTable onClick={(productId) => toggleProductModal(productId)} />
      <ProductsImportModal
        isOpen={isImporterModalOpen}
        toggleModal={toggleImporterModal}
      />
    </>
  );
};

export default ProductsScreen;
