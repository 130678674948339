export const switchboardWidths = [
  {
    value: 600,
    label: '600'
  },
  {
    value: 800,
    label: '800'
  },
  {
    value: 1000,
    label: '1000'
  },
  {
    value: 1200,
    label: '1200'
  }
];

export const switchboardHeights = [
  {
    value: 24,
    label: '24'
  },
  {
    value: 28,
    label: '28'
  },
  {
    value: 33,
    label: '33'
  },
  {
    value: 39,
    label: '39'
  },
  {
    value: 42,
    label: '42'
  },
  {
    value: 45,
    label: '45'
  }
];

export const horizontalModulesPerRow = [
  {
    value: 24,
    label: '24'
  },
  {
    value: 33,
    label: '33'
  },
  {
    value: 36,
    label: '36'
  },
  {
    value: 45,
    label: '45'
  },
  {
    value: 57,
    label: '57'
  }
];

export const verticalModulesPerGenericRow = [
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  }
];

export const numberOfGenericRows = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5'
  }
];

export const verticalModulesPerClampRow = [
  {
    value: 5,
    label: '5'
  },
  {
    value: 6,
    label: '6'
  },
  {
    value: 7,
    label: '7'
  },
  {
    value: 8,
    label: '8'
  },
  {
    value: 9,
    label: '9'
  }
];

export const numberOfClampRows = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5'
  }
];

export const numberOfReservedRows = [
  {
    value: 5,
    label: '5'
  },
  {
    value: 6,
    label: '6'
  },
  {
    value: 7,
    label: '7'
  },
  {
    value: 8,
    label: '8'
  },
  {
    value: 9,
    label: '9'
  }
];

export const numberOfReservedClampRows = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5'
  }
];
