import axios from '../../axios/axios.service';

export const switchboardConsumerApi = {
  consumerSwap: async (projectId, consumerId, data) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/consumer/${consumerId}/swap`,
      data
    );
  },
  addConsumer: async (projectId, data) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/consumer`,
      data
    );
  },
  updateConsumer: async (projectId, consumerId, data) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/consumer/${consumerId}`,
      data
    );
  },
  deleteConsumer: async (projectId, consumerId) => {
    return await axios.delete(
      `/projects/${projectId}/switchboard/consumer/${consumerId}`
    );
  },
  restoreConsumer: async (projectId, consumerId) => {
    return await axios.post(
      `/projects/${projectId}/switchboard/consumer/${consumerId}/restore`
    );
  }
};
