import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup } from 'reactstrap';

import SideModal from '../../components/modals/SideModal';
import AppContext from '../../context/Context';
import { productsExportApi } from '../../helpers/api/productsExportApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import FileDropzone from './components/FileDropzone';

const ProductsImportModal = ({ isOpen = false, toggleModal }) => {
  const { t } = useTranslation();
  const { setFetchData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(undefined);
  const [file, setFile] = useState(null);
  const [mode, setMode] = useState(0);

  useEffect(() => {
    setError(false);

    if (!isOpen) return;

    setFile(null);
    setError(null);
    setMode(0);
  }, [isOpen]);

  const onSubmit = async () => {
    var formData = new FormData();

    formData.append('mode', mode);
    formData.append('skipFirstLine', true);
    formData.append('file', file);

    setIsLoading(true);

    try {
      await productsExportApi.importProducts(formData);
      toast.success(t('Products successfully imported!'));
      setFetchData(true);
      toggleModal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  return (
    <SideModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Product Import"
      subtitle="Import products from a .csv file"
      icon="edit"
    >
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <FileDropzone
            placeholder={t('Drop products .csv file here')}
            file={file}
            onChange={setFile}
            preview
          />
        </FormGroup>
      </Form>
      {error !== undefined && <p>{error}</p>}

      <Button disabled={isLoading} onClick={onSubmit} color="falcon-primary">
        {isLoading ? '...' : 'Import'}
      </Button>
    </SideModal>
  );
};

export default ProductsImportModal;
