import z from 'zod';

export const switchboardZoneFormSchema = z.object({
  index: z.number({ invalid_type_error: 'Index is required.', required_error: 'Index is required.' }),
  label: z
    .string()
    .trim()
    .min(1, { message: 'Label is required' })
    .max(100, { message: 'Label must be less than 100 characters' }),
  protection: z.object({
    id: z.number().nullish(),
    cablesShouldHaveTileLabels: z.coerce.boolean().optional(),
  }),
});
