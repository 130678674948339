import qs from 'qs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';

import AppContext from '../../context/Context';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import axios from '../../helpers/axios/axios.service';
import { removeUserFromCookies } from '../../helpers/utils/removeUserFromCookies';
import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPasswordScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });
  const token = queryString.token;

  const [isLoading, setIsLoading] = useState(false);

  const { setIsAuthenticated, setUser } = useContext(AppContext);

  useEffect(() => {
    setIsAuthenticated(false);
    setUser(null);
    removeUserFromCookies();
  }, []);

  const handleResetPassword = async (email) => {
    setIsLoading(true);
    try {
      await axios.post('/users/reset-password', { email });
      toast.info(t('Check your email for the reset link'));
      return true;
    } catch (error) {
      showErrorsFromRequest(error, t);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPasswordConfirm = async (
    email,
    newPassword,
    confirmNewPassword
  ) => {
    setIsLoading(true);
    try {
      await axios.post(
        `/users/reset-password-confirm?${qs.stringify({ token: token })}`,
        {
          email,
          newPassword,
          confirmedPassword: confirmNewPassword
        }
      );
      toast.success(t('Password reset successfully'));
      navigate('/auth/login', { replace: true });
      return true;
    } catch (error) {
      showErrorsFromRequest(error, t);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const getOnSubmitFunctionByToken = token
    ? handleResetPasswordConfirm
    : handleResetPassword;

  return (
    <div>
      <Row className="text-left justify-content-between">
        <Col xs="auto">
          <h5>{t('Password Reset')}</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600">{t('Admin Area')}</p>
        </Col>
      </Row>
      <ResetPasswordForm
        resetToken={token}
        onSubmit={getOnSubmitFunctionByToken}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ResetPasswordScreen;
