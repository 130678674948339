import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { ProjectSortOptions } from '../../constants/sortOptions';
import Pagination from '../../screens/projects/components/Pagination';
import SortDropdown from '../common/SortDropdown';
import SearchInput from '../SearchInput';
import ProjectCard from './ProjectCard';

const ProjectsColumns = ({
  projects,
  pagination,
  setPagination,
  projectCardRowProps = { xs: 1, md: 2, lg: 3 },
  sortRowProps,
  handleSearch,
  isLoading
}) => {
  const { t } = useTranslation();

  let changedProjectCardRowProps =
    projects.length === 0 ? { xs: 12 } : projectCardRowProps;

  const getProjectCards = () => {
    if (projects.length === 0 && !isLoading) {
      return (
        <Col className="my-2 text-center font-weight-bold fs-2">
          {t('No projects found')}
        </Col>
      );
    }

    return projects.map((project) => {
      return (
        <Col className="mb-3" key={project.id}>
          <ProjectCard key={project.id} project={project} />
        </Col>
      );
    });
  };

  return (
    <>
      <div {...sortRowProps}>
        <SearchInput
          placeholder="Project Name, Client Name"
          addSearchParams={true}
          onSearch={handleSearch}
        />
        <SortDropdown options={ProjectSortOptions} />
      </div>

      <Row {...changedProjectCardRowProps}>{getProjectCards()}</Row>
      <div className="mt-2">
        <Pagination
          {...pagination}
          onPageChanged={(page) => setPagination({ ...pagination, page: page })}
          onPerPageChanged={(perPage) =>
            setPagination({ ...pagination, page: 1, perPage: perPage })
          }
        />
      </div>
    </>
  );
};

export default ProjectsColumns;
